import React, { useEffect, useMemo, useState } from 'react';

import { User } from '@sendbird/chat';
import { Member } from '@sendbird/chat/groupChannel';
import { useRecoilValue } from 'recoil';

import useCurrentUser from '../../../hooks/useCurrentUser';
import { getIsMaster, getIsStaff } from '../../../libs/helper';
import { fetchUsers } from '../../../libs/sendbird';
import mentionedUsersState from '../../../store/atoms/mentionedUsersState';
import { ChatChannel } from '../../../types/common';

type Props = {
  channel: ChatChannel;
  keyword?: string;
  onInvite: (user: User) => () => void;
  onMention: (member: Member, mentionKeyword: string) => () => void;
  selectionStart: number | null;
  selectionEnd: number | null;
};

const ChatMessageMentionInput: React.FC<Props> = ({
  channel,
  keyword = '',
  onInvite,
  onMention,
  selectionStart = null,
  selectionEnd = null
}) => {
  const { currentUser } = useCurrentUser();
  const [users, setUsers] = useState<User[]>([]);
  // const [invitationLink, setInvitationLink] = useState();
  const mentionedUsers = useRecoilValue(mentionedUsersState);

  const mentionKeyword = useMemo(() => {
    console.log('selectionStart, selectionEnd', selectionStart, selectionEnd);
    if (selectionStart === null || selectionEnd === null) {
      return '';
    }

    if (selectionStart === selectionEnd) {
      return keyword.substring(0, selectionEnd).split(' ').pop()?.replace('@', '') || '';
    }

    return keyword.substring(selectionStart, selectionEnd).split(' ').pop()?.replace('@', '') || '';
  }, [keyword, selectionStart, selectionEnd]);

  const members = useMemo(() => {
    return channel.members.filter((member) => {
      if (currentUser?.userId === member.userId) {
        return false;
      }

      if (!member.nickname) {
        return false;
      }

      if (mentionedUsers.find((mentionedUser) => mentionedUser.userId === member.userId)) {
        return false;
      }

      return member.nickname.indexOf(mentionKeyword) > -1;
    });
  }, [channel, mentionKeyword, currentUser, mentionedUsers]);

  useEffect(() => {
    async function search() {
      if (!mentionKeyword) {
        setUsers([]);
        return;
      }

      if (!getIsMaster({ channel, user: currentUser as User }) && !getIsStaff({ channel, user: currentUser as User })) {
        setUsers([]);
        return;
      }

      const userIds = channel.members.map((member) => member.userId) || [];
      const response = await fetchUsers({
        nicknameStartsWithFilter: mentionKeyword
      });
      setUsers(
        response.filter((row) => {
          if (currentUser?.userId === row.userId) {
            return false;
          }

          if (!row.nickname) {
            return false;
          }

          return userIds.indexOf(row.userId) === -1;
        })
      );
    }
    search();
  }, [mentionKeyword, channel, currentUser]);

  // useEffect(() => {
  //   async function getInvitationLink() {
  //     if (!channel) {
  //       return;
  //     }

  //     // setInvitationLink(link.url);
  //   }
  //   getInvitationLink();
  // }, [channel]);

  return (
    <div
      className="tw-absolute tw-bottom-[50px] tw-left-0 tw--mr-[10px] tw-hidden tw-max-h-[420px] tw-w-[260px] tw-overflow-auto tw-rounded-[12px] tw-border tw-border-[#e0e0e0] tw-bg-white tw-shadow-[0_2px_4px_0_rgba(0,0,0,0.1)]"
      style={{ display: 'block' }}
    >
      {/* 채널에 있는 멤버 검색 결과 리스트 */}
      <ul className="">
        {members.map((member) => (
          <li
            key={member.userId}
            className="tw-flex tw-h-10 tw-items-center tw-px-4"
            onClick={onMention(member, mentionKeyword)}
          >
            <img className="tw-size-7 tw-rounded-full" src={member.profileUrl || member.plainProfileUrl} />

            <div className="tw-ml-2 tw-text-13 tw-font-medium tw-text-black-body">
              <span className="tw-text-point-100">{mentionKeyword}</span>
              {member.nickname.replace(mentionKeyword, '')}
            </div>
          </li>
        ))}
      </ul>
      {/* 채널 밖 사용자 닉네임 검색 결과 (초대버튼으로 채널 초대 DM 발송) */}
      {users.length > 0 && (
        <ul className="tw-mx-4 -tw-mt-1 tw-border-t tw-border-gray-300">
          {users.map((user) => {
            return (
              <li key={user.userId} className="-tw-mx-4 tw-flex tw-h-10 tw-items-center tw-justify-between tw-px-4">
                <div className="tw-flex tw-items-center">
                  <img className="tw-size-7 tw-rounded-full" src={user.profileUrl} />

                  <div className="tw-ml-2 tw-text-13 tw-font-medium tw-text-black-body">
                    <span className="tw-text-point-100">{mentionKeyword}</span>
                    {user.nickname.replace(mentionKeyword, '')}
                  </div>
                </div>
                {/* 이 검색 결과 부분만 초대 버튼이 숨겨지고 hover시에만 보여집니다. */}
                <button type="button" className="btn btn_small_xs check" onClick={onInvite(user)}>
                  <span>Invite</span>
                </button>
              </li>
            );
          })}
        </ul>
      )}
      {/*검색결과가 없을 때 */}
      {users.length === 0 && members.length === 0 && (
        <div className="search_member_none" style={{ paddingBottom: 30 }}>
          <span>No Matching..</span>
        </div>
      )}
      {/**
       *  @todo
       *  채널링크 공유해도 현재 구조상 접근할 수 없기 때문에 임시 주석처리
       */}
      {/* <div className="url_copy">
        <span className="url_copy_title">Send a invite link to a friend</span>
        <div className="form_wrap">
          <span className="form_cell form_input input_sm">
            <input type="text" defaultValue={invitationLink} />
            <button className="btn_input copy" onClick={onCopy}>
              <span className="a11y">복사</span>
            </button>
          </span>
        </div>
      </div> */}
    </div>
  );
};

export default ChatMessageMentionInput;
