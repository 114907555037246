import React, { useMemo } from 'react';
import { Trans } from 'react-i18next';

import { ChatMessage, CustomMessageType } from 'types/common';

type Props = {
  message: ChatMessage;
};

const getI18nKey = (type?: CustomMessageType) => {
  switch (type) {
    case CustomMessageType.JOIN:
      return 'chat.welcomeMassage.label';
    case CustomMessageType.INVITATION:
      return 'chat.invitationMessage.label';
    case CustomMessageType.LEAVE:
      return 'chat.leaveMessage.label';

    default:
      return '';
  }
};

const ChatJoinLeaveMessage = ({ message }: Props) => {
  const data = useMemo(() => {
    if (!message.data) return null;

    try {
      return JSON.parse(
        message.data
          .replace(/:\s*True/g, ': true')
          .replace(/:\s*False/g, ': false')
          .replace(/:\s*None/g, ': null')
          .replace(/'/g, '"')
      );
    } catch (error) {
      console.log('Failed to parse message data', message);
      console.log(error);
      return null;
    }
  }, [message]);

  if (!getI18nKey(message.customType)) return null;

  return (
    <div className="tw-my-4 tw-flex tw-w-full tw-items-center tw-justify-between tw-bg-gray-200 tw-px-5 tw-py-2.5">
      <div className="tw-text-14 tw-text-gray-700">
        <Trans
          i18nKey={getI18nKey(message.customType)}
          values={{ username: data?.user.username || message.message, toUser: data?.toUser?.username || '' }}
        >
          <span className="tw-font-medium tw-text-black-body">{message.message}</span>
        </Trans>
      </div>
    </div>
  );
};
export default ChatJoinLeaveMessage;
