import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as CheckIcon } from 'assets/icons/ic-check.svg';
import { ReactComponent as SearchIcon } from 'assets/icons/ic-search.svg';
import Button from 'components/jayden/Button';
import Input from 'components/jayden/Input';
import ModalContainer from 'components/layout/ModalContainer';
import ProfileAvatar from 'components/profileAvatar/ProfileAvatar';
import { PostMessagePath, RequestCommand } from 'types/postMessageCommand';

import useCurrentUser from 'hooks/useCurrentUser';
import useGroupChannel from 'hooks/useGroupChannel';
import useUserInfo from 'hooks/useUserInfo';
const ChannelSendCoin = () => {
  const { t } = useTranslation();
  const { members } = useGroupChannel();
  const { currentUser } = useCurrentUser();

  const [keyword, setKeyword] = useState('');

  const memberIdList = useMemo(() => {
    const filteredMembers = Object.entries(members).filter(([memberId, member]) => {
      const isNotCurrentUser = memberId !== currentUser?.userId;
      const matchesKeyword = !keyword || member.nickname?.includes(keyword);
      return isNotCurrentUser && matchesKeyword;
    });

    const memberIds = filteredMembers.map(([memberId]) => memberId);

    return memberIds;
  }, [members, keyword, currentUser]);

  const handleKeywordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setKeyword(e.target.value);
  };

  return (
    <ModalContainer width="tw-w-[320px]" height="auto">
      <div className="title">{t('chat.sendCoinPopup.title')}</div>
      <div className="tw-mb-3 tw-px-5">
        <Input
          className="tw-peer tw-transition-all"
          type="text"
          placeholder={t('chat.menu.search.placeholder')}
          value={keyword}
          onChange={handleKeywordChange}
          suffix={
            <SearchIcon className="tw-absolute tw-right-3 tw-top-1/2 tw-inline-block tw-size-6 -tw-translate-y-1/2 tw-cursor-pointer tw-text-gray-500 tw-transition-all peer-focus:tw-text-black-body" />
          }
        />
      </div>

      <div className="tw-h-80 tw-px-6 tw-pb-5">
        <ul className="-tw-mr-1 tw-h-full tw-overflow-y-auto tw-pr-1">
          {memberIdList.map((memberId) => (
            <MemberItem key={memberId} userId={memberId} />
          ))}
        </ul>
      </div>
    </ModalContainer>
  );
};

export default ChannelSendCoin;

const MemberItem = ({ userId }: { userId: string }) => {
  const { t } = useTranslation();
  const { currentChannel } = useGroupChannel();
  const { data: userInfo, isLoading } = useUserInfo(userId);

  const handleClickSend = () => {
    if (!userInfo?.wallet_address || !currentChannel?.url) return;

    const toAddress = userInfo.wallet_address;
    const toUserId = userInfo.id;
    const urlPath = `/user-profile/wallet?toAddress=${toAddress}&toUserId=${toUserId}&toUsername=${userInfo?.username}&channelUrl=${currentChannel.url}`;

    window?.opener?.postMessage(
      {
        from: PostMessagePath.Chat,
        command: RequestCommand.SendCoins,
        urlPath
      },
      '*'
    );
  };

  if (isLoading || !userInfo) return null;
  return (
    <li className="tw-flex tw-h-10 tw-items-center tw-justify-between tw-py-1.5">
      <div className="tw-flex tw-items-center tw-gap-1.5">
        <ProfileAvatar userInfo={userInfo} trustRing size={28} className="tw-z-0" />
        <div className="tw-flex tw-text-13">
          <div className="tw-max-w-28 tw-truncate tw-font-medium tw-text-black-title">{userInfo.username}</div>
          <div className="tw-text-gray-600">#{userInfo.hash}</div>
        </div>
      </div>

      <Button
        size="xs"
        color="default"
        className="tw-flex tw-h-7 tw-items-center tw-justify-center"
        onClick={handleClickSend}
        disabled={!userInfo.wallet_address}
      >
        <CheckIcon className="tw-size-4" />
        {t('chat.sendCoinPopup.button')}
      </Button>
    </li>
  );
};
