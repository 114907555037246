import React, { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import styled from 'styled-components';

import ModalContainer from '../../components/layout/ModalContainer';
import useGroupChannel from '../../hooks/useGroupChannel';
import usePostMessage from '../../hooks/usePostMessage';
import useVoteIframe, { voteUrl } from '../../hooks/useVoteIframe';
import { DjangoInterface, VoteInterface } from '../../libs/apis/response.type';
import { CustomMessageType } from '../../types/common';
import { RequestCommand, ResponseCommand, ResponseCommandMap } from '../../types/postMessageCommand';

type Params = {
  channelUrl: string;
};

const ChannelPollCreate: React.FC = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const { channelUrl } = useParams<Params>();
  const [loading, setLoading] = useState(false);
  const { sendMessageToCurrentChannel } = useGroupChannel();

  const voteIframeRef = useRef<HTMLIFrameElement>(null);
  const postMessage = usePostMessage(voteIframeRef);

  const handleClickReset = () => {
    postMessage(RequestCommand.ResetVote);
  };

  const handleClickCreatePoll = () => {
    setLoading(true);
    if (!loading) {
      postMessage(RequestCommand.CreateVote, { channelUrl });
    }
  };

  const onSuccessCreatePoll = async (event: MessageEvent) => {
    const createVoteResponse = event.data.data as DjangoInterface<VoteInterface>;
    setLoading(false);

    const vote = createVoteResponse.data;

    sendMessageToCurrentChannel({
      message: vote.title,
      customType: CustomMessageType.POLL,
      data: JSON.stringify(vote)
    });

    navigate(-1);
  };

  const onFailCreatePoll = async () => {
    /**
     * @TODO 실패시 처리 ?
     */
    setLoading(false);
  };

  const commandMap = useMemo<ResponseCommandMap>(
    () =>
      new Map([
        [ResponseCommand.CreateSuccess, onSuccessCreatePoll],
        [ResponseCommand.CreateFailure, onFailCreatePoll]
      ]),
    []
  );

  useVoteIframe({ ref: voteIframeRef, commandMap });

  return (
    <ModalContainer
      width="w600"
      Buttons={[
        <button className="left reset" key={0} onClick={handleClickReset}>
          <span>{t('chat.pollPopup.button.reset')}</span>
        </button>,
        <button className="btn primary button_lg save" key={1} onClick={handleClickCreatePoll}>
          <span>{t('chat.pollPopup.button.createPoll')}</span>
        </button>
      ]}
      contentClassNames={{ chatting_vote: true }}
    >
      <div className="title">{t('chat.pollPopup.title')}</div>
      <StyledVoteIframeWrapper>
        <iframe ref={voteIframeRef} src={`${voteUrl}/create`} width="100%" height="100%" />
      </StyledVoteIframeWrapper>
    </ModalContainer>
  );
};

export default ChannelPollCreate;

const StyledVoteIframeWrapper = styled.div`
  height: 476px;
  overflow: overlay;
`;
