import React, { useState } from 'react';
import { FileUploader } from 'react-drag-drop-files';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useRecoilCallback } from 'recoil';

import { MESSAGE_UPLOAD_FILE_MAX_COUNT } from '../../../constants/common';
import useAlert from '../../../hooks/useAlert';
import useGroupChannel from '../../../hooks/useGroupChannel';
import useMessageInput from '../../../hooks/useMessageInput';
import { UploadToS3Response } from '../../../libs/s3';
import groupChannelState from '../../../store/atoms/groupChannelState';
import { CustomMessageType } from '../../../types/common';

import { mediaApi } from 'apis/media';
import { ReactComponent as InfoIcon } from 'assets/icons/ic-info.svg';
import { ReactComponent as UploadIcon } from 'assets/icons/ic-upload-purple.svg';
import Button from 'components/jayden/Button';

import { _changeS3Form } from 'utils/s3-form';

const fileTypes = ['mp4', 'mov', 'avi', 'wmv', 'mkv', 'flv'];

const MAX_FILE_SIZE = 50 * 1024 * 1024; // 50MB

const ChatMessageAttachmentVideo = () => {
  const navigate = useNavigate();
  const { currentChannel } = useGroupChannel();
  const { messageText, sendMessageToChannel } = useMessageInput();
  const { show, close } = useAlert();
  const [attachments, setAttachments] = useState<UploadToS3Response[]>([]);
  const [isUploading, setIsUploading] = useState(false);
  const { t } = useTranslation();

  const onConfirm = useRecoilCallback(
    ({ snapshot }) =>
      async () => {
        const channel = snapshot.getLoadable(groupChannelState).getValue();

        if (!channel) {
          return;
        }

        await sendMessageToChannel(channel.url, {
          message: 'upload video',
          data: JSON.stringify(attachments),
          customType: CustomMessageType.VIDEO
        });
        navigate(-1);
      },
    [attachments, messageText, navigate]
  );
  const handleDrop = async (files: any) => {
    if (!currentChannel) return;
    if (files.length === 0) return;

    if (files.length > MESSAGE_UPLOAD_FILE_MAX_COUNT) {
      show({
        content: t('chat.attachPopup.video.validate.upTo12'),
        buttons: [
          {
            title: t('common.button.confirm'),
            async onClick() {
              close();
            }
          }
        ]
      });
      return;
    }

    setIsUploading(true);

    const testArray: UploadToS3Response[] = [];
    for (let i = 0; i < files.length; i++) {
      const file = files[i];

      if (file.size > MAX_FILE_SIZE) {
        show({
          content: t('chat.attachPopup.video.guide'),
          buttons: [
            {
              title: t('common.button.confirm'),
              async onClick() {
                close();
              }
            }
          ]
        });
        setIsUploading(false);
        return;
      }

      const formData = new FormData();
      formData.append('file', file);

      const result = await mediaApi.postVideoUpload(formData);
      testArray.push(_changeS3Form(result, file.type));
    }
    setAttachments((prev) => [...prev, ...testArray]);
    setIsUploading(false);
  };

  return (
    <div className="tw-h-[calc(100%-52px)] tw-pt-5">
      <div className="tw-h-full tw-overflow-y-auto">
        <FileUploader handleChange={handleDrop} name="file" multiple types={fileTypes}>
          <div className="tw-mx-auto tw-flex tw-w-full tw-cursor-pointer tw-flex-col tw-items-center tw-justify-center tw-rounded-3 tw-border tw-border-gray-300 tw-bg-gray-200 tw-py-[43px]">
            <UploadIcon className="tw-size-[60px]" />
            <div className="tw-text-center tw-text-16 tw-text-gray-700">{t('chat.attachPopup.video.dragAndDrop')}</div>
          </div>
        </FileUploader>

        <div className="tw-h-2" />
        <div className="tw-flex tw-items-center tw-gap-1 tw-text-13 tw-text-gray-700">
          <InfoIcon className="" />
          {t('chat.attachPopup.video.guide')}
        </div>
        {/* 비디오업로드후에는 자동 썸네일 적용 */}
        {attachments.length > 0 && (
          <>
            <div className="tw-mb-2 tw-mt-6 tw-text-14 tw-font-medium tw-text-black-title">
              {t('chat.attachPopup.video.thumbnail')}
            </div>
            <div className="tw-w-full tw-rounded-2">
              {attachments.map((attachment, index) => {
                return (
                  <video key={index.toString()} playsInline src={attachment.cloudFrontUrl} width={68} height={68} />
                );
              })}
            </div>
          </>
        )}

        <div className="tw-h-5" />
      </div>

      <Button
        size="lg"
        className="tw-w-full tw-text-center"
        color="primary"
        onClick={onConfirm}
        disabled={attachments.length === 0}
      >
        {isUploading ? t('chat.attachPopup.button.uploading') : t('common.button.confirm')}
      </Button>
    </div>
  );
};
export default ChatMessageAttachmentVideo;
