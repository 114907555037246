import React from 'react';

import { useRecoilCallback } from 'recoil';

import useCurrentUser from '../../../hooks/useCurrentUser';
import useGroupChannel from '../../../hooks/useGroupChannel';
import useMessageInput from '../../../hooks/useMessageInput';
import { fetchGroupChannel, fetchMessage } from '../../../libs/sendbird';
import groupChannelState from '../../../store/atoms/groupChannelState';
import messageMoreState, { MessageMoreState } from '../../../store/atoms/messageMoreState';
import { ChatMessage } from '../../../types/common';

import ChatMessageMoreMenu from './ChatMessageMoreMenu';

import { ReactComponent as HeartOnIcon } from 'assets/icons/ic-chat-heart-on.svg';
import { ReactComponent as HeartOutlineIcon } from 'assets/icons/ic-chat-heart-outline.svg';
import { ReactComponent as MoreIcon } from 'assets/icons/ic-more.svg';
import { ReactComponent as ReplyIcon } from 'assets/icons/ic-reply-sm-bk.svg';
import classNames from 'components/styled/util';

type Props = {
  message: ChatMessage;
  moreState: MessageMoreState;
  isLikedByMe: boolean;
  refChattingArea: React.RefObject<HTMLDivElement>;
};

const ChatMessageMoreButton: React.FC<Props> = ({ message, moreState, isLikedByMe }) => {
  const { currentChannel, updateMessage } = useGroupChannel();
  const { currentUser } = useCurrentUser();
  const { reply } = useMessageInput();

  const onReply = useRecoilCallback(
    ({ set }) =>
      () => {
        reply(message);
        set(messageMoreState, (state) => ({
          ...state,
          popup: false
        }));
      },
    [reply, message]
  );

  const onLike = useRecoilCallback(
    ({ snapshot }) =>
      async () => {
        if (!currentUser?.userId) {
          return;
        }

        const channel = snapshot.getLoadable(groupChannelState).getValue();

        if (!channel) {
          return;
        }

        const groupChannel = await fetchGroupChannel(channel.url);
        const groupChannelMessage = await fetchMessage(channel.url, message.messageId);

        // await currentChannel.deleteReaction(message, currentUser.userId);
        if (isLikedByMe === true) {
          await groupChannel.deleteReaction(groupChannelMessage, 'like');
          const reactionUserIds = message.reactionUserIds.filter(
            (reactionUserId) => reactionUserId !== currentUser.userId
          );
          const reactionCount = reactionUserIds.length;
          await updateMessage(message.messageId, {
            reactionCount,
            reactionUserIds
          });
        } else {
          await groupChannel.addReaction(groupChannelMessage, 'like');
          const reactionUserIds = [...message.reactionUserIds, currentUser.userId];
          const reactionCount = reactionUserIds.length;
          await updateMessage(message.messageId, {
            reactionCount,
            reactionUserIds
          });
        }
      },
    [reply, message, currentChannel, currentUser, isLikedByMe]
  );

  const onMore = useRecoilCallback(
    ({ set }) =>
      () => {
        set(messageMoreState, (state) => ({
          ...state,
          popup: true,
          messageId: message.messageId
        }));
      },
    []
  );

  return (
    <div className="tw-relative tw-hidden tw-h-fit tw-items-end tw-gap-1 tw-px-0.5 group-hover:tw-inline-flex">
      {message?.parentMessage == null && (
        <button type="button" className="tw-text-gray-900" onClick={onReply}>
          <span className="tw-sr-only">답글</span>
          <ReplyIcon className={classNames('tw-size-5 tw-text-gray-500 hover:tw-text-gray-900', {})} />
        </button>
      )}

      <button type="button" className={classNames({})} onClick={onLike}>
        {isLikedByMe && <HeartOnIcon className="tw-size-5" />}
        {!isLikedByMe && <HeartOutlineIcon className="tw-size-5 tw-text-gray-500 hover:tw-text-gray-900" />}

        <span className="tw-sr-only">좋아요</span>
      </button>

      <button type="button" className="tw-relative" onClick={onMore}>
        <MoreIcon className="tw-size-5 tw-text-gray-500 hover:tw-text-gray-900" />
        <span className="tw-sr-only">더보기</span>

        <div
          className={classNames({
            'tw-absolute tw-z-10 tw-w-screen -tw-left-1/2 -tw-translate-x-1/2 tw-h-4': moreState.popup
          })}
        />
        {moreState.popup === true && moreState.messageId === message.messageId && (
          <ChatMessageMoreMenu message={message} />
        )}
      </button>
    </div>
  );
};
export default ChatMessageMoreButton;
