import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { GroupChannel, type GroupChannelCreateParams } from '@sendbird/chat/groupChannel';
import type {
  BaseMessage,
  GroupChannelUpdateParams,
  MessageListParams,
  PreviousMessageListQueryParams,
  SendableMessage,
  UserMessage
} from '@sendbird/chat/lib/__definition';
import { MessageTypeFilter, ReplyType, type UserMessageCreateParams } from '@sendbird/chat/message';
import { produce } from 'immer';
import { keyBy, uniq } from 'lodash';
import { useRecoilCallback, useRecoilValue } from 'recoil';

import { DEFAULT_DM_PARTICIPANTS_LIMIT, SENDBIRD_ERROR_USER_NOT_FOUND } from '../constants/common';
import { getChannelCustomData, getChatChannel, getChatMessage } from '../libs/helper';
import getSendBird, {
  createUser,
  fetchBannedUsers,
  fetchGroupChannel,
  fetchGroupChannels,
  fetchMessage,
  fetchPublicChannels,
  fetchUser
} from '../libs/sendbird';
import blockedUserListState from '../store/atoms/blockedUserListState';
import chatMessagesState, {
  chatMessageIdsState,
  chatMessageStateByMessageId,
  lastUnreadMessageIdState
} from '../store/atoms/chatMessagesState';
import groupChannelState from '../store/atoms/groupChannelState';
import groupChannelsState, { groupChannelIdsState, groupChannelStateByUrl } from '../store/atoms/groupChannelsState';
import membersState from '../store/atoms/membersState';
import userState from '../store/atoms/userState';
import {
  ChannelCustomData,
  ChatChannel,
  ChatMessage,
  CustomChannelType,
  CustomMessageType,
  InvitationMessageProps,
  TransferMasterMessageProps
} from '../types/common';

import { CurrentChannelOptions } from 'hooks/use-current-channel';

const useGroupChannel = () => {
  const navigate = useNavigate();
  const groupChannelStateValue = useRecoilValue(groupChannelState);
  const groupChannelsStateValue = useRecoilValue(groupChannelsState);
  const groupChannelIdsStateValue = useRecoilValue(groupChannelIdsState);
  const membersStateValue = useRecoilValue(membersState);

  const isDmChannel = useMemo(
    () => groupChannelStateValue?.customType === CustomChannelType.DM,
    [groupChannelStateValue]
  );

  const fetchChannels = useRecoilCallback(
    ({ set }) =>
      async (): Promise<{
        groupChannels: GroupChannel[];
        publicChannels: GroupChannel[];
      }> => {
        const groupChannels = await fetchGroupChannels(); // 내가 속한 채널
        const publicChannels = await fetchPublicChannels(); // 전체 채널

        const channels = [...groupChannels, ...publicChannels].map((channel) => getChatChannel(channel));

        set(groupChannelsState, () => ({
          ...{},
          ...keyBy<ChatChannel>(channels, 'url')
        }));

        set(groupChannelIdsState, uniq(channels.map((channel) => channel.url)));

        return {
          groupChannels,
          publicChannels
        };
      },
    []
  );

  const upsertChannelState = useRecoilCallback(
    ({ set }) =>
      (groupChannel: GroupChannel): ChatChannel => {
        const chatChannel = getChatChannel(groupChannel);
        set(groupChannelsState, (state) => ({
          ...state,
          [groupChannel.url]: chatChannel
        }));

        set(groupChannelIdsState, (state) => {
          if (state.indexOf(groupChannel.url) > -1) {
            return state;
          }

          return [groupChannel.url, ...state];
        });

        return chatChannel;
      },
    []
  );

  const removeChannelState = useRecoilCallback(
    ({ set }) =>
      (removeChannelUrl: string) => {
        set(groupChannelsState, (state) => {
          const copiedState = { ...state };
          delete copiedState[removeChannelUrl];

          return copiedState;
        });

        set(groupChannelIdsState, (state) => {
          const index = state.findIndex((channelUrl) => channelUrl === removeChannelUrl);
          return [...state.slice(0, index), ...state.slice(index + 1)];
        });
      },
    []
  );

  const sendUserMessage = useCallback(
    async (channelUrl: string, params: UserMessageCreateParams): Promise<UserMessage> => {
      try {
        const groupChannel = await fetchGroupChannel(channelUrl);

        if (groupChannel.customType === CustomChannelType.DM && groupChannel?.joinedMemberCount < 2) {
          console.log('groupChannel?.joinedMemberCount :', groupChannel?.joinedMemberCount);
          // const toUser = groupChannel.members.find((member) => member.userId !== groupChannel.inviter?.userId);
          // if (toUser != null) {
          //   await acceptDMChannel(toUser.userId, groupChannel.url);
          //   groupChannel = await fetchGroupChannel(channelUrl, true);
          // }
        }

        const sendableMessage: SendableMessage = await new Promise((resolve, reject) => {
          groupChannel
            .sendUserMessage(params)
            .onFailed(async (err: Error) => {
              // Handle error.
              if (err.message.toString() === 'Not a member.') {
                await joinChannel(channelUrl);
                const message = await sendUserMessage(channelUrl, params);
                resolve(message);
                return;
              }

              if (err.message.toString() === 'Sender is still in pending status.') {
                await groupChannel.acceptInvitation();
                const message = await sendUserMessage(channelUrl, params);
                resolve(message);
                return;
              }

              if (err.message.toString() === 'Messages is blocked by profanity filter.') {
                alert('금지어 걸림!');
              }

              reject(err);
            })
            .onSucceeded((message: SendableMessage) => {
              resolve(message);
            });
        });
        return sendableMessage as UserMessage;
      } catch (err) {
        console.error(err);
        throw err; // 또는 적절한 에러 처리
      }
    },
    []
  );

  const filterBlockedUserMessaged = useRecoilCallback(
    ({ snapshot }) =>
      (messages: ChatMessage[]) => {
        const blockedUserList = snapshot.getLoadable(blockedUserListState).getValue();

        const filteredMessages = messages.filter(
          (message) => !blockedUserList.find((user) => user.userId === message.sender?.userId)
        );

        return filteredMessages;
      },
    []
  );

  const fetchPrevMessages = useRecoilCallback(
    ({ set }) =>
      async (channelUrl: string, lastMessageId: number) => {
        const channel = await fetchGroupChannel(channelUrl);

        const params: MessageListParams = {
          prevResultSize: 10,
          nextResultSize: 0,
          reverse: false
        };

        const response = await channel.getMessagesByMessageId(lastMessageId, params);

        if (response.length === 0) {
          return;
        }

        const messages = response.map((row) => getChatMessage(row as UserMessage));

        const filteredMessages = filterBlockedUserMessaged(messages);

        set(chatMessagesState, (state) => ({
          ...state,
          ...keyBy(filteredMessages, 'messageId')
        }));

        set(chatMessageIdsState, (state) => [...filteredMessages.map((message) => message.messageId), ...state]);
      },
    []
  );

  const fetchNextMessages = useRecoilCallback(
    ({ set }) =>
      async (channelUrl: string, firstMessageId: number) => {
        const channel = await fetchGroupChannel(channelUrl);

        const params: MessageListParams = {
          prevResultSize: 0,
          nextResultSize: 10,
          reverse: false
        };

        const response = await channel.getMessagesByMessageId(firstMessageId, params);

        if (response.length === 0) {
          return;
        }

        const messages = response.map((row) => getChatMessage(row as UserMessage));

        const filteredMessages = filterBlockedUserMessaged(messages);

        set(chatMessagesState, (state) => ({
          ...state,
          ...keyBy(filteredMessages, 'messageId')
        }));

        set(chatMessageIdsState, (state) => [...state, ...filteredMessages.map((message) => message.messageId)]);
      },
    []
  );

  const updateMessage = useRecoilCallback(
    ({ set }) =>
      async (messageId: number, params: Partial<ChatMessage>) => {
        set(chatMessagesState, (state) =>
          produce(state, (draft) => {
            draft[messageId] = {
              ...draft[messageId],
              ...params
            };
            return draft;
          })
        );
      },
    []
  );

  const updateNewMessage = useRecoilCallback(
    ({ snapshot, set }) =>
      async (receivedChannelUrl: string, message: UserMessage) => {
        const channel = snapshot.getLoadable(groupChannelStateByUrl(receivedChannelUrl)).getValue();

        if (!channel) {
          return;
        }

        // 이미 메시지가 있는 경우
        const searchedMessage = snapshot.getLoadable(chatMessageStateByMessageId(message.messageId)).getValue();
        if (searchedMessage) {
          return;
        }

        const blockedUserList = snapshot.getLoadable(blockedUserListState).getValue();

        if (blockedUserList.find((user) => user.userId === message.sender?.userId)) {
          return;
        }

        if (receivedChannelUrl === channel.url) {
          set(chatMessagesState, (state) => {
            return produce(state, (draft) => {
              draft[message.messageId] = getChatMessage(message);
              return draft;
            });
          });

          set(chatMessageIdsState, (state) => {
            if (state.indexOf(message.messageId) > -1) {
              return state;
            }
            return [...state, message.messageId];
          });

          set(groupChannelsState, (state) => {
            return produce(state, (draft) => {
              draft[receivedChannelUrl] = {
                ...channel,
                unreadMentionCount: 0,
                unreadMessageCount: 0,
                lastMessage: message
              };

              return draft;
            });
          });
        } else {
          const groupChannel = await fetchGroupChannel(receivedChannelUrl);

          let unreadMessageCount = groupChannel.unreadMessageCount;
          if (message.customType === CustomMessageType.SENDBIRD_AUTO_EVENT_MESSAGE) {
            if (message.message.indexOf('joined') > -1) {
              unreadMessageCount++;
            }
          }

          set(groupChannelsState, (state) => {
            return produce(state, (draft) => {
              draft[receivedChannelUrl] = {
                ...getChatChannel(groupChannel),
                unreadMentionCount: groupChannel.unreadMentionCount,
                unreadMessageCount,
                lastMessage: message
              };

              return draft;
            });
          });
        }
      },
    []
  );

  const updateRemovedMessage = useRecoilCallback(
    ({ set }) =>
      async (messageId: number) => {
        set(chatMessageIdsState, (state) => {
          const index = state.findIndex((id) => id === messageId);
          return [...state.slice(0, index), ...state.slice(index + 1)];
        });
        set(chatMessagesState, (state) => {
          const copiedState = { ...state };
          delete copiedState[messageId];
          return copiedState;
        });
      },
    []
  );

  const removeMessage = useCallback(async (channel: ChatChannel, message: ChatMessage) => {
    const groupChannel = await fetchGroupChannel(channel.url);
    const groupChannelMessage = await fetchMessage(channel.url, message.messageId);
    await groupChannel.deleteMessage(groupChannelMessage);
  }, []);

  const initMessages = useRecoilCallback(
    ({ set }) =>
      async (channelUrl: string) => {
        // 초기화
        const setInitialStates = () => {
          set(chatMessagesState, {});
          set(chatMessageIdsState, []);
          set(lastUnreadMessageIdState, 0);
        };

        // 메세지 중 블락된 유저의 메세지 필터링
        const processMessages = (messages: BaseMessage[]) => {
          const chatMessages = messages.map((row) => getChatMessage(row as UserMessage));

          const filteredChatMessages = filterBlockedUserMessaged(chatMessages);

          set(chatMessagesState, (state) => ({
            ...state,
            ...keyBy(filteredChatMessages, 'messageId')
          }));

          set(chatMessageIdsState, (state) => [...filteredChatMessages.map((message) => message.messageId), ...state]);
        };

        setInitialStates();

        try {
          const groupChannel = await fetchGroupChannel(channelUrl);
          const unreadCount = groupChannel.unreadMessageCount;

          // 읽지 않은 메세지가 있는 경우 읽지 않은 모든 메세지 + 이전 메세지 20개 불러오기
          if (unreadCount > 0) {
            const params: MessageListParams = {
              prevResultSize: 20,
              nextResultSize: unreadCount,
              isInclusive: true,
              reverse: false
            };

            const response = await groupChannel.getMessagesByMessageId(groupChannel.lastMessage.messageId, params);

            set(lastUnreadMessageIdState, response[response.length - unreadCount].messageId);
            processMessages(response);
          } else {
            // 읽지 않은 메세지가 없으면 30개만 불러오기
            const params: PreviousMessageListQueryParams = {
              limit: 30,
              reverse: false,
              messageTypeFilter: MessageTypeFilter.ALL,
              replyType: ReplyType.ALL,
              includeThreadInfo: true,
              includeParentMessageInfo: true,
              senderUserIdsFilter: [],
              includeReactions: true,
              includeMetaArray: true
            };
            const query = groupChannel.createPreviousMessageListQuery(params);

            if (query.hasNext) {
              const response = await query.load();
              processMessages(response);
            }
          }
        } catch (e) {
          setInitialStates();
        }
      },
    []
  );

  const initMessagesBySearch = useRecoilCallback(
    ({ set }) =>
      async (channel: ChatChannel, searchedMessage: UserMessage) => {
        const message = await fetchMessage(channel.url, searchedMessage.messageId);

        const groupChannel = await fetchGroupChannel(channel.url);

        try {
          const params: MessageListParams = {
            prevResultSize: 10,
            nextResultSize: 10,
            isInclusive: false,
            reverse: false,
            replyType: ReplyType.ALL,
            includeThreadInfo: true,
            includeParentMessageInfo: true,
            messageTypeFilter: MessageTypeFilter.ALL
          };

          const response = await groupChannel.getMessagesByTimestamp(message.createdAt, params);

          const prevOrNextMessages = response.map((row) => getChatMessage(row as UserMessage));

          const messages = [
            ...filterBlockedUserMessaged(prevOrNextMessages).filter(
              (message) => message.messageId < searchedMessage.messageId
            ),
            getChatMessage(message),
            ...filterBlockedUserMessaged(prevOrNextMessages).filter(
              (message) => message.messageId > searchedMessage.messageId
            )
          ];

          set(chatMessagesState, () => ({
            ...{},
            ...keyBy(messages, 'messageId')
          }));

          set(
            chatMessageIdsState,
            messages.map((message) => message.messageId)
          );
        } catch (e) {
          set(chatMessagesState, {});
          set(chatMessageIdsState, []);
        }
      },
    []
  );

  const setCurrentChannel = useRecoilCallback(
    ({ set }) =>
      async (channelUrl: string, options?: CurrentChannelOptions) => {
        if (!options?.noRedirect) {
          navigate(`/channel/${channelUrl}`, {
            replace: true
          });
        }

        if (channelUrl) {
          const groupChannel = await fetchGroupChannel(channelUrl, true);
          const chatChannel = upsertChannelState(groupChannel);

          set(groupChannelState, chatChannel);
          set(membersState, keyBy(groupChannel.members, 'userId'));

          return groupChannel;
        } else {
          set(groupChannelState, null);
          set(membersState, {});
        }
      },
    [navigate]
  );

  const refreshCurrentChannel = useRecoilCallback(
    ({ snapshot, set }) =>
      async () => {
        const channel = snapshot.getLoadable(groupChannelState).getValue();

        if (!channel) {
          return;
        }

        const groupChannel = await fetchGroupChannel(channel.url);

        if (groupChannel) {
          const refreshed = await groupChannel.refresh();
          set(groupChannelState, getChatChannel(refreshed));
          set(membersState, keyBy(refreshed.members, 'userId'));
        }

        return groupChannel;
      },
    []
  );

  const createChannel = useCallback(
    async (
      params: Required<
        Pick<
          GroupChannelCreateParams,
          | 'name'
          | 'coverUrl'
          | 'operatorUserIds'
          | 'isPublic'
          | 'isDiscoverable'
          | 'customType'
          | 'isDistinct'
          | 'invitedUserIds'
        >
      >,
      data: ChannelCustomData
    ): Promise<GroupChannel> => {
      const channel = await getSendBird().groupChannel.createChannel({
        ...params,
        data: JSON.stringify(data),
        isStrict: false
      });

      if (data?.clubId) {
        await Promise.all([
          channel.createMetaData({ clubId: data?.clubId }),
          channel.createMetaCounters({
            memberCount: channel.joinedMemberCount
          })
        ]);
      }

      // const chatChannel = upsertChannelState(channel);

      // try {
      //   const channelData = await postOpenChannel({
      //     thumbnail_image: thumbnailImage,
      //     title: chatChannel.name,
      //     description: chatChannel.data.introduction,
      //     user_limit: chatChannel.data.participantsLimit,
      //     public_type: chatChannel.isPublic ? "PUBLIC" : "PRIVATE",
      //     url: channel.url,
      //   });

      //   setAllChannelList([channelData, ...allChannelList]);
      //   if (chatChannel.isPublic)
      //     setJoinChannelList([channelData, ...joinChannelList]);
      // } catch (err1) {
      //   console.error("superclub channel creation", err1);
      // }

      return channel;
    },
    []
  );

  const deleteChannel = useRecoilCallback(
    ({ snapshot }) =>
      async () => {
        const channel = snapshot.getLoadable(groupChannelState).getValue();

        if (!channel) {
          return;
        }

        const groupChannel = await fetchGroupChannel(channel.url);
        await groupChannel.delete();
      },
    []
  );

  const sendFirstMessage = useCallback(async (channel: GroupChannel): Promise<SendableMessage> => {
    try {
      const message: SendableMessage = await new Promise((resolve, reject) => {
        channel
          .sendUserMessage({
            message: 'Create Channel',
            customType: CustomMessageType.SYSTEM
          })
          .onSucceeded((message: SendableMessage) => {
            resolve(message);
          })
          .onFailed((err) => {
            reject(err);
          });
      });
      return message;
    } catch (err) {
      console.error(err);
      throw err; // 또는 적절한 에러 처리
    }
  }, []);

  const updateChannel = useRecoilCallback(
    () =>
      async (
        channelUrl: string,
        params: Partial<Omit<GroupChannelUpdateParams, 'data'>> & {
          data?: Partial<ChannelCustomData>;
        }
      ) => {
        const groupChannel = await fetchGroupChannel(channelUrl, true);

        const channelCustomData = getChannelCustomData(groupChannel.data);
        const { data, ...restParams } = params;
        const updateParams: GroupChannelUpdateParams = restParams;

        if (data) {
          updateParams.data = JSON.stringify({
            ...channelCustomData,
            ...data
          });
        }

        // if (restParams.isPublic) {
        //   updateParams.isDiscoverable = restParams.isPublic;
        // }

        updateParams['isDiscoverable'] = updateParams.isPublic;

        const updatedChannel = await groupChannel.updateChannel({
          // is_discoverable: false,
          ...updateParams
        });

        const chatChannel = upsertChannelState(updatedChannel);
        // try {
        //   await patchOpenChannel(channelIdState.id, {
        //     title: chatChannel.name,
        //     description: chatChannel.data.introduction,
        //     user_limit: chatChannel.data.participantsLimit,
        //     public_type: chatChannel.isPublic ? "PUBLIC" : "PRIVATE",
        //     url: chatChannel.url,
        //   });
        // } catch (err1) {
        //   console.error("superclub channel update", err1);
        // }

        return chatChannel;
      },
    []
  );

  const addOperators = useRecoilCallback(
    () =>
      async (channelUrl: string, userIds: string[]): Promise<ChatChannel> => {
        const groupChannel = await fetchGroupChannel(channelUrl, true);

        await groupChannel.addOperators(userIds);
        const channelCustomData = getChannelCustomData(groupChannel.data);

        const filtered = channelCustomData.staffIds.filter((staffId) => userIds.indexOf(staffId) === -1);

        const updatedChannel = await updateChannel(channelUrl, {
          data: {
            staffIds: [...filtered, ...userIds]
          }
        });

        return updatedChannel;
      },
    []
  );

  const removeOperators = useRecoilCallback(
    () =>
      async (channelUrl: string, userIds: string[]): Promise<ChatChannel> => {
        const groupChannel = await fetchGroupChannel(channelUrl, true);

        await groupChannel.removeOperators(userIds);

        const channelCustomData = getChannelCustomData(groupChannel.data);

        const updatedChannel = await updateChannel(channelUrl, {
          data: {
            staffIds: channelCustomData.staffIds.filter((staffId) => userIds.indexOf(staffId) === -1)
          }
        });

        return updatedChannel;
      },
    []
  );

  const addBookmark = useRecoilCallback(
    () =>
      async (channelUrl: string, userIds: string[]): Promise<ChatChannel> => {
        const groupChannel = await fetchGroupChannel(channelUrl, true);
        const channelCustomData = getChannelCustomData(groupChannel.data);

        const filtered = channelCustomData.bookmarkUserIds.filter(
          (bookmarkUserId) => userIds.indexOf(bookmarkUserId) === -1
        );
        const updatedChannel = await updateChannel(channelUrl, {
          data: {
            bookmarkUserIds: [...filtered, ...userIds]
          }
        });

        return updatedChannel;
      },
    []
  );

  const removeBookmark = useRecoilCallback(
    () =>
      async (channelUrl: string, userIds: string[]): Promise<ChatChannel> => {
        const groupChannel = await fetchGroupChannel(channelUrl, true);
        const channelCustomData = getChannelCustomData(groupChannel.data);
        const updatedChannel = await updateChannel(channelUrl, {
          data: {
            bookmarkUserIds: channelCustomData.bookmarkUserIds.filter(
              (bookmarkUserId) => userIds.indexOf(bookmarkUserId) === -1
            )
          }
        });

        return updatedChannel;
      },
    []
  );

  const addNotice = useRecoilCallback(
    () => async (channel: GroupChannel, message: ChatMessage) => {
      const { noticeMessageIds } = getChannelCustomData(channel.data);

      let newNoticeMessageIds: number[] = [];
      const newNoticeMessageId = message.messageId;

      if (noticeMessageIds.indexOf(message.messageId) > -1) {
        newNoticeMessageIds = noticeMessageIds.filter((noticeMessageId) => noticeMessageId !== newNoticeMessageId);
      } else {
        newNoticeMessageIds = [newNoticeMessageId, ...noticeMessageIds];
      }

      const updatedChatChannel = await updateChannel(channel.url, {
        data: {
          noticeMessageIds: newNoticeMessageIds.slice(0, 3)
        }
      });
      return updatedChatChannel;
    },
    []
  );

  const joinChannel = useCallback(async (channelUrl: string) => {
    const groupChannel = await fetchGroupChannel(channelUrl);
    const newGroupChannel = await groupChannel.join();
    return upsertChannelState(newGroupChannel);
  }, []);

  const inviteChannel = useRecoilCallback(
    ({ snapshot, set }) =>
      async (channelOrUrl: ChatChannel | string, message: string, userIds: string[]) => {
        const user = snapshot.getLoadable(userState).getValue();

        if (!user) {
          return;
        }

        const fromUserId = user.userId;

        let url;
        if (typeof channelOrUrl === 'string') {
          url = channelOrUrl;
        } else {
          url = channelOrUrl.url;
        }

        const groupChannel = await fetchGroupChannel(url, true);
        const toUserId = userIds[0];

        const bannedUsers = await fetchBannedUsers(groupChannel);
        const isBanned = bannedUsers.find((bannedUser) => userIds.indexOf(bannedUser.userId) > -1);

        if (isBanned) {
          throw new Error('BANNED');
        }
        // 해당 유저와 채널 정보가 있는지 확인
        try {
          const updatedChannel = await groupChannel.inviteWithUserIds(userIds);
          set(groupChannelsState, (state) => ({
            ...state,
            [groupChannel.url]: getChatChannel(updatedChannel)
          }));
        } catch (error: any) {
          if (error?.code === SENDBIRD_ERROR_USER_NOT_FOUND) await createUser(userIds[0]);
          const updatedChannel = await groupChannel.inviteWithUserIds(userIds);
          set(groupChannelsState, (state) => ({
            ...state,
            [groupChannel.url]: getChatChannel(updatedChannel)
          }));
        }
        // DM 채널 생성
        await getSendBird().setChannelInvitationPreference(true);
        const dmChannel = await initDMChannel(toUserId);

        if (dmChannel) {
          // 유저초대 팝업에세 작성한 메모를 메세지로 전달
          const dmMessage1 = await sendUserMessage(dmChannel.url, {
            message,
            customType: CustomMessageType.TEXT
          });
          await updateNewMessage(dmChannel.url, dmMessage1);
          window.dispatchEvent(
            new CustomEvent('sentNewMessage', {
              detail: {
                customType: dmMessage1.customType
              }
            })
          );
          // 초대 join 메세지
          const dmMessage2 = await sendUserMessage(dmChannel.url, {
            message: JSON.stringify({
              fromUserId,
              toUserId,
              channelUrl: groupChannel.url,
              message,
              channelName: groupChannel.name,
              introduction: getChannelCustomData(groupChannel.data).introduction || ''
            } as InvitationMessageProps),
            customType: CustomMessageType.INVITATION
          });
          await updateNewMessage(dmChannel.url, dmMessage2);

          window.dispatchEvent(
            new CustomEvent('sentNewMessage', {
              detail: {
                customType: dmMessage2.customType
              }
            })
          );

          await getSendBird().setChannelInvitationPreference(false);
        }
      },
    []
  );

  const transferMaster = useRecoilCallback(
    ({ snapshot }) =>
      async (channel: ChatChannel, message: string, userIds: string[]) => {
        const user = snapshot.getLoadable(userState).getValue();

        if (!user) {
          return;
        }

        const fromUserId = user.userId;

        const groupChannel = await fetchGroupChannel(channel.url, true);

        const toUserId = userIds[0];

        const dmChannel = await initDMChannel(toUserId);

        if (dmChannel) {
          const dmMessage = await sendUserMessage(dmChannel.url, {
            message: JSON.stringify({
              fromUserId,
              toUserId,
              channelUrl: groupChannel.url,
              message,
              channelName: groupChannel.name
            } as TransferMasterMessageProps),
            customType: CustomMessageType.MASTER_TRANSFER
          });
          await updateNewMessage(dmChannel.url, dmMessage);
          updateChannel(groupChannel.url, {
            data: {
              transferingStaffIds: [toUserId]
            }
          });
          window.dispatchEvent(
            new CustomEvent('sentNewMessage', {
              detail: {
                customType: dmMessage.customType
              }
            })
          );
        }
      },
    [fetchChannels]
  );

  const cancelTransferMaster = useRecoilCallback(
    () => async (channel: ChatChannel) => {
      updateChannel(channel.url, {
        data: {
          transferingStaffIds: []
        }
      });
    },
    []
  );

  const leaveChannel = useRecoilCallback(
    ({ snapshot }) =>
      async (channelUrl: string, userId: string) => {
        const channel = snapshot.getLoadable(groupChannelStateByUrl(channelUrl)).getValue();

        if (!channel) {
          return;
        }

        const groupChannel = await fetchGroupChannel(channel.url);
        const removeChannelUrl = groupChannel.url;

        const groupChannelIds = snapshot.getLoadable(groupChannelIdsState).getValue();

        const foundIndex = groupChannelIds.findIndex((channelUrl) => channelUrl === removeChannelUrl);

        if (foundIndex === null) {
          return;
        }

        const channelCustomData = getChannelCustomData(groupChannel.data);

        if (channel.customType !== CustomChannelType.DM) {
          await updateChannel(removeChannelUrl, {
            data: {
              staffIds: channelCustomData.staffIds.filter((staffId) => staffId !== userId)
            }
          });
        }

        await groupChannel?.leave();
        removeChannelState(removeChannelUrl);
      },
    []
  );

  const markAsRead = useRecoilCallback(
    ({ set }) =>
      async (channelUrl: string) => {
        const groupChannel = await fetchGroupChannel(channelUrl);
        try {
          if (groupChannel.unreadMessageCount > 0) {
            await groupChannel.markAsRead();
          }
        } catch (e) {
          console.log(e);
        }

        set(groupChannelsState, (state) => {
          return produce(state, (draft) => {
            draft[groupChannel.url] = getChatChannel(groupChannel);
            return draft;
          });
        });
      },
    []
  );

  const initDMChannel = useRecoilCallback(
    ({ snapshot }) =>
      async (toUserId: string): Promise<GroupChannel | null> => {
        try {
          const user = snapshot.getLoadable(userState).getValue();
          const toUser = await fetchUser(toUserId);

          if (!toUser) {
            return null;
          }

          if (!user) {
            return null;
          }

          await getSendBird().setChannelInvitationPreference(true);
          const channel = await createChannel(
            {
              name: `dm`,
              coverUrl: user.profileUrl ?? '',
              operatorUserIds: [user.userId ?? '', toUser.userId],
              invitedUserIds: [user.userId ?? '', toUser.userId],
              isDistinct: true,
              isPublic: false,
              isDiscoverable: false,
              customType: CustomChannelType.DM
            },
            {
              introduction: 'dm',
              masterIds: [],
              staffIds: [],
              transferingStaffIds: [],
              participantsLimit: DEFAULT_DM_PARTICIPANTS_LIMIT,
              noticeMessageIds: [],
              forbiddenWords: [],
              bookmarkUserIds: [],
              sendMessageDisabledUserIds: [],
              viewChannelDisabledUserIds: [],
              viewChannelHistories: []
            }
          );
          // const channel = await postOpenChannel({
          //   thumbnail_image: (user?.profileUrl),
          //   title: "dm",
          //   description: "dm",
          //   user_limit: DEFAULT_DM_PARTICIPANTS_LIMIT,
          //   type: CustomChannelType.DM,
          //   public_type: "OPEN",
          // });
          await getSendBird().setChannelInvitationPreference(false);
          await setCurrentChannel(channel.url);
          return channel;
        } catch (e) {
          console.error(e);
          return null;
        }
      },
    []
  );

  const channels = useMemo(() => {
    return groupChannelIdsStateValue.map((url) => groupChannelsStateValue[url]);
  }, [groupChannelIdsStateValue, groupChannelsStateValue]);

  const fetchBlockedUserList = useRecoilCallback(
    ({ set }) =>
      async () => {
        const query = getSendBird().createBlockedUserListQuery({ limit: 100 });
        if (query.hasNext) {
          const blockedUsers = await query.next();
          set(blockedUserListState, blockedUsers);
        }
      },
    []
  );

  const sendMessageToCurrentChannel = useRecoilCallback(
    ({ snapshot }) =>
      async (params: UserMessageCreateParams): Promise<UserMessage> => {
        try {
          const channel = snapshot.getLoadable(groupChannelState).getValue();

          if (!channel) {
            throw new Error('Channel is not available');
          }

          const message = await sendUserMessage(channel.url, params);
          await updateNewMessage(channel.url, message);
          window.dispatchEvent(
            new CustomEvent('sentNewMessage', {
              detail: {
                customType: message.customType
              }
            })
          );
          return message;
        } catch (e) {
          console.error(e);
          throw e; // 이 부분에서 에러를 직접 던지게 되므로, 이 함수를 호출하는 쪽에서 적절한 에러 처리가 필요합니다.
        }
      },
    [updateNewMessage]
  );

  return {
    createChannel,
    deleteChannel,
    updateChannel,
    upsertChannelState,
    removeChannelState,
    addOperators,
    removeOperators,
    currentChannel: groupChannelStateValue,
    setCurrentChannel,
    fetchPrevMessages,
    fetchNextMessages,
    fetchChannels,
    members: membersStateValue,
    initMessages,
    initMessagesBySearch,
    initDMChannel,
    updateNewMessage,
    updateMessage,
    updateRemovedMessage,
    removeMessage,
    leaveChannel,
    joinChannel,
    inviteChannel,
    transferMaster,
    cancelTransferMaster,
    refreshCurrentChannel,
    isDmChannel,
    channels,
    sendFirstMessage,
    addNotice,
    markAsRead,
    sendUserMessage,
    addBookmark,
    removeBookmark,
    fetchBlockedUserList,
    sendMessageToCurrentChannel
  };
};

export default useGroupChannel;
