import React, { useCallback, useMemo } from 'react';

import { downloadURI } from '../../../../libs/helper';
import { UploadToS3Response } from '../../../../libs/s3';
import { ChatMessage } from '../../../../types/common';

import Ballon from 'components/jayden/ballon';
import classNames from 'components/styled/util';

import useCurrentUser from 'hooks/useCurrentUser';

type Props = {
  message: ChatMessage;
  parentMessage?: ChatMessage;
};

const ChatFileMessage: React.FC<Props> = ({ message }) => {
  const parsedMessage = useMemo<UploadToS3Response>(() => {
    try {
      if (message.data) return JSON.parse(message.data)[0];
      const parsed = JSON.parse(message.message);
      return parsed[0];
    } catch {
      return {};
    }
  }, [message.message]);

  const onDownload = useCallback(() => {
    downloadURI(parsedMessage.cloudFrontUrl, parsedMessage.orgFileName);
  }, [parsedMessage]);

  const { currentUser } = useCurrentUser();
  const isMe = currentUser?.userId === message.sender.userId;

  if (!parsedMessage) {
    return null;
  }

  return (
    <>
      <Ballon isMe={isMe}>
        <div className="tw-flex tw-items-center">
          <div
            className={classNames(
              'text-13 tw-relative tw-inline-flex tw-min-h-10 tw-max-w-[358px] tw-items-center tw-break-all tw-text-black-title'
            )}
          >
            {parsedMessage.orgFileName}
          </div>
          <button
            type="button"
            className="tw-relative tw-ml-5 tw-size-8 tw-rounded-4 tw-bg-gray-200"
            onClick={onDownload}
          >
            <img
              src="/img/ico/ic_download_bk.png"
              className="tw-absolute tw-left-1/2 tw-top-1/2 tw-size-6 -tw-translate-x-1/2 -tw-translate-y-1/2"
            />
            <span className="a11y">파일다운</span>
          </button>
        </div>
      </Ballon>
    </>
  );
};
export default ChatFileMessage;
