import React from 'react';
import { type RouteObject, createBrowserRouter } from 'react-router-dom';

import Index from './Index';
import ChannelAttach from './chat/ChannelAttach';
import ChannelCreate from './chat/ChannelCreate';
import ChannelPollCreate from './chat/ChannelPollCreate';
import ChannelPollVote from './chat/ChannelPollVote';
import ChannelSendCoin from './chat/ChannelSendCoin';
import ChannelSettingDeleteChannel from './chat/ChannelSettingDeleteChannel';
import ChannelSettingOverview from './chat/ChannelSettingOverview';
import ChannelSettingPermissions from './chat/ChannelSettingPermissions';
import ChatMain from './chat/ChatMain';
import ClubChannelCreate from './chat/ClubChannelCreate';
import Gallery from './chat/Gallery';
import Invite from './chat/Invite';
import InviteThroughMiniProfile from './chat/InviteThroughMiniProfile';
import Video from './chat/Video';
import MiniProfile from './profile/MiniProfile/MiniProfile';
import Report from './report/Report';

const children: RouteObject[] = [
  {
    path: 'create',
    element: <ChannelCreate />
  },
  {
    path: 'create/club/:clubId',
    element: <ClubChannelCreate />
  },
  {
    path: 'setting/overview',
    element: <ChannelSettingOverview />
  },
  {
    path: 'setting/permissions',
    element: <ChannelSettingPermissions />
  },
  {
    path: 'setting/deleteChannel',
    element: <ChannelSettingDeleteChannel />
  },
  // {
  //   path: "polls",
  //   element: <ChannelPollList />,
  // },
  {
    path: 'poll/:pollId',
    element: <ChannelPollVote />
  },
  {
    path: 'poll/create',
    element: <ChannelPollCreate />
  },
  {
    path: 'report/message/:messageId',
    element: <Report />
  },
  {
    path: 'report/channel',
    element: <Report />
  },
  {
    path: 'miniprofile/:userId',
    element: <MiniProfile />
  },
  {
    path: 'gallery/:messageId',
    element: <Gallery />
  },
  {
    path: 'video/:messageId',
    element: <Video />
  },
  {
    path: 'invite/:userId',
    element: <Invite />
  },
  {
    path: 'invite/:userId/:inviteChannelUrl',
    element: <InviteThroughMiniProfile />
  },
  {
    path: 'send-coin',
    element: <ChannelSendCoin />
  },
  {
    path: 'attach',
    element: <ChannelAttach />
  }
];

const router = createBrowserRouter([
  {
    path: '/',
    caseSensitive: true,
    element: <Index />,
    children: [
      {
        path: 'channel/:channelUrl?',
        element: <ChatMain />,
        children
      },
      {
        path: 'dm/:dmUserId',
        element: <ChatMain />,
        children
      }
    ]
  }
]);

export default router;
