import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useRecoilCallback } from 'recoil';

import { downloadURI } from '../../libs/helper';
import { UploadToS3Response } from '../../libs/s3';
import { fetchMessage } from '../../libs/sendbird';
import groupChannelState from '../../store/atoms/groupChannelState';

type Props = {
  images?: UploadToS3Response[];
  focusImage?: UploadToS3Response | null;
  onClose?: () => void;
};

type Params = {
  messageId: string;
};

const Gallery: React.FC<Props> = ({ images = [], focusImage, onClose }) => {
  const navigate = useNavigate();
  const { messageId } = useParams<Params>();
  const [imageList, setImageList] = useState<UploadToS3Response[]>(images);
  const [index, setIndex] = useState<number>(0);

  const onLeft = useCallback(() => {
    setIndex((state) => {
      let newIndex = state - 1;
      if (newIndex < 0) {
        newIndex = imageList.length - 1;
      }
      return newIndex;
    });
  }, [imageList]);

  const onRight = useCallback(() => {
    setIndex((state) => {
      let newIndex = state + 1;
      if (newIndex > imageList.length - 1) {
        newIndex = 0;
      }
      return newIndex;
    });
  }, [imageList]);

  const onDownload = useCallback(async () => {
    downloadURI(imageList[index].cloudFrontUrl, imageList[index].orgFileName);
  }, [imageList, index]);

  const init = useRecoilCallback(
    ({ snapshot }) =>
      async () => {
        const channel = snapshot.getLoadable(groupChannelState).getValue();

        if (!channel) {
          return;
        }

        if (!messageId) {
          return;
        }

        const message = await fetchMessage(channel.url, parseInt(messageId, 10));

        let parsedMessage: UploadToS3Response[] = [];
        if (message.data) {
          parsedMessage = JSON.parse(message.data) as UploadToS3Response[];
        } else {
          parsedMessage = JSON.parse(message.message) as UploadToS3Response[];
        }

        setImageList(parsedMessage);
      },
    [messageId]
  );

  useEffect(() => {
    if (!messageId) return;
    init();
  }, [messageId]);

  useEffect(() => {
    const focusImageIndex = imageList.findIndex((image) => image.fileKey === focusImage?.fileKey);
    if (focusImageIndex >= 0) {
      setIndex(focusImageIndex);
    }
  }, [imageList]);

  if (imageList.length === 0) {
    return null;
  }

  return (
    <>
      <div id="modal" onClick={onClose} />
      <div className="chat_img_preview" onClick={(e) => e.stopPropagation()}>
        {/* 미리비로기에 보여지는 이미지는 가로 1120px, 세로 600px을 넘지 않습니다.*/}
        <div className="img_preview_area">
          <button
            type="button"
            className="img_pre_btn left !disabled:hover:tw-opacity-40"
            disabled={index === 0}
            onClick={onLeft}
          >
            <span className="a11y">좌측으로</span>
          </button>
          <button
            type="button"
            className="img_pre_btn right !disabled:hover:tw-opacity-40"
            disabled={index === imageList.length - 1}
            onClick={onRight}
          >
            <span className="a11y">우측으로</span>
          </button>
          <button type="button" className="img_btn down" onClick={onDownload}>
            <span className="a11y">다운로드</span>
          </button>
          <button
            type="button"
            className="img_btn close"
            onClick={() => {
              if (onClose) {
                onClose();
                return;
              }
              navigate(-1);
            }}
          >
            <span className="a11y">닫기</span>
          </button>

          {imageList[index]?.contentType.indexOf('image') > -1 && (
            <img src={imageList[index]?.cloudFrontUrl} style={{ maxWidth: '1000px', maxHeight: '500px' }} />
          )}
          {imageList[index]?.contentType.indexOf('video') > -1 && (
            <video autoPlay={false} controls={true} style={{ zIndex: 1, maxWidth: '1000px', maxHeight: '500px' }}>
              <source src={`${imageList[index]?.cloudFrontUrl}#t=0.1`} type={imageList[index]?.contentType} />
            </video>
          )}
          <div className="img_number">
            <span>
              <em>{index + 1}</em> / {imageList.length}
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default Gallery;
