import React, { useCallback, useEffect, useState } from 'react';
import { FileUploader } from 'react-drag-drop-files';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useRecoilCallback } from 'recoil';

import { MESSAGE_UPLOAD_FILE_MAX_COUNT } from '../../../constants/common';
import useAlert from '../../../hooks/useAlert';
import useMessageInput from '../../../hooks/useMessageInput';
import { IMAGE_TYPES, getIsImage } from '../../../libs/helper';
import { UploadToS3Response } from '../../../libs/s3';
import groupChannelState from '../../../store/atoms/groupChannelState';
import { CustomMessageType } from '../../../types/common';

import { mediaApi } from 'apis/media';
import { ReactComponent as CloseIcon } from 'assets/icons/ic-close.svg';
import { ReactComponent as FileIcon } from 'assets/icons/ic-file.svg';
import { ReactComponent as InfoIcon } from 'assets/icons/ic-info.svg';
import { ReactComponent as UploadIcon } from 'assets/icons/ic-upload-purple.svg';
import Button from 'components/jayden/Button';
import classNames from 'components/styled/util';

import useGroupChannel from 'hooks/useGroupChannel';

import { _changeS3Form } from 'utils/s3-form';

const FILE_MAX_SIZE = 10 * 1024 * 1024; // 10MB

const ChatMessageAttachmentUpload = () => {
  const navigate = useNavigate();
  const { messageText, sendMessageToChannel } = useMessageInput();
  const { currentChannel } = useGroupChannel();
  const [attachments, setAttachments] = useState<UploadToS3Response[]>([]);
  const [isUploading, setIsUploading] = useState(false);
  const [multiple, _setMultiple] = useState<boolean>(true);
  const { show, close } = useAlert();
  const [_availableFileTypes, setAvailableFileTypes] = useState<string[] | undefined>(undefined);
  const { t } = useTranslation();

  const onConfirm = useRecoilCallback(
    ({ snapshot }) =>
      async () => {
        const channel = snapshot.getLoadable(groupChannelState).getValue();
        if (!channel) {
          return;
        }
        const images = attachments.filter((attachment) => getIsImage(attachment.contentType));
        const files = attachments.filter((attachment) => !getIsImage(attachment.contentType));

        if (images.length > 0) {
          await sendMessageToChannel(channel.url, {
            message: 'upload image',
            data: JSON.stringify(images),
            customType: CustomMessageType.IMAGE
          });
        }

        if (files.length > 0) {
          await sendMessageToChannel(channel.url, {
            message: 'upload image',
            data: JSON.stringify(files),
            customType: CustomMessageType.FILE
          });
        }
        navigate(-1);
      },
    [attachments, messageText, navigate]
  );

  const onRemoveFile = useCallback(
    (index: number) => async () => {
      setAttachments((state) => [...state.slice(0, index), ...state.slice(index + 1)]);
    },
    []
  );

  useEffect(() => {
    if (attachments.length > 0) {
      const isExistsImage = attachments.find((attachment) => getIsImage(attachment.contentType));
      if (isExistsImage) {
        setAvailableFileTypes(IMAGE_TYPES.map((type) => type.replace('image/', '')));
      } else {
        setAvailableFileTypes(undefined);
      }
    }
  }, [attachments]);

  const handleDrop = async (files: any) => {
    if (!currentChannel) return;
    if (files.length === 0) return;

    if (files.length > MESSAGE_UPLOAD_FILE_MAX_COUNT) {
      show({
        content: t('chat.attachPopup.upload.validate.upTo12'),
        buttons: [
          {
            title: t('common.button.confirm'),
            async onClick() {
              close();
            }
          }
        ]
      });
      return;
    }

    setIsUploading(true);

    const testArray: UploadToS3Response[] = [];
    for (let i = 0; i < files.length; i++) {
      const file = files[i];

      if (file.size > FILE_MAX_SIZE) {
        show({
          content: t('chat.attachPopup.upload.guide'),
          buttons: [
            {
              title: t('common.button.confirm'),
              async onClick() {
                close();
              }
            }
          ]
        });
        setIsUploading(false);
        return;
      }

      const formData = new FormData();
      formData.append('file', file);

      const result = await mediaApi.postFileUpload(formData);
      testArray.push(_changeS3Form(result, file.type));
    }
    setAttachments((prev) => [...prev, ...testArray]);
    setIsUploading(false);
  };

  return (
    <>
      {/* S : 파일 첨부하기 */}
      <div className="tw-h-[calc(100%-52px)] tw-pt-5">
        <div className="scroll-none tw-h-full tw-overflow-y-auto">
          {/* 첨부할 수없는 파일일 경우 Alert(Invalid file format. Cannot upload.)
            / Button : Confirm */}
          <FileUploader handleChange={handleDrop} name="file" multiple={multiple}>
            <div className="tw-mx-auto tw-flex tw-w-full tw-cursor-pointer tw-flex-col tw-items-center tw-justify-center tw-rounded-3 tw-border tw-border-gray-300 tw-bg-gray-200 tw-py-[43px]">
              <UploadIcon className="tw-size-[60px]" />
              <div className="tw-text-center tw-text-16 tw-text-gray-700">
                {t('chat.attachPopup.upload.dragAndDrop')}
              </div>
            </div>
          </FileUploader>

          <div className="tw-h-2" />
          <div className="tw-flex tw-items-center tw-gap-1 tw-text-13 tw-text-gray-700">
            <InfoIcon className="" />
            {t('chat.attachPopup.upload.guide')}
          </div>

          {attachments?.length > 0 && (
            <div className="tw-mb-2 tw-mt-6 tw-text-14 tw-font-medium tw-text-black-title">
              {t('chat.attachPopup.upload.uploadFiles')}
            </div>
          )}

          {attachments.map((attachment, index) => {
            const isImage = getIsImage(attachment.contentType);
            return (
              <React.Fragment key={index}>
                {/* 업로드 이미지가 1개일 때 */}
                <div className="tw-flex tw-w-full tw-items-center tw-border-b tw-border-gray-200 tw-bg-none tw-p-0">
                  <div className={classNames({})}>
                    {isImage && <img className="tw-size-10" src={attachment.cloudFrontUrl} />}
                    {!isImage && <FileIcon className="tw-size-10" />}
                  </div>
                  <div className="tw-ml-3 tw-mr-5 tw-block tw-w-[170px]">
                    <div className="tw-h-10 tw-truncate tw-pt-2.5 tw-text-14">{attachment.orgFileName}</div>
                  </div>
                  <button
                    type="button"
                    className="tw-rounded-full tw-bg-gray-100 tw-p-1 hover:tw-bg-gray-400"
                    onClick={onRemoveFile(index)}
                  >
                    <CloseIcon className="tw-size-3" />
                    <span className="tw-sr-only">삭제</span>
                  </button>
                </div>
              </React.Fragment>
            );
          })}
          <div className="tw-h-4" />
        </div>
      </div>

      <Button
        color="primary"
        size="lg"
        className="tw-w-full tw-text-center"
        onClick={onConfirm}
        disabled={attachments.length === 0}
      >
        {isUploading ? t('chat.attachPopup.button.uploading') : t('common.button.confirm')}
      </Button>
    </>
  );
};
export default ChatMessageAttachmentUpload;
