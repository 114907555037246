import React, { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useInfiniteQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';

import { isAxiosError } from 'axios';
import { debounce } from 'lodash';
import { useOnClickOutside } from 'usehooks-ts';

import chatApi from 'apis/chat-api';
import { ReactComponent as MemberPlusIcon } from 'assets/icons/ic-chat-member-plus.svg';
import { ReactComponent as CheckIcon } from 'assets/icons/ic-check.svg';
import { ReactComponent as SearchIcon } from 'assets/icons/ic-search.svg';
import Button from 'components/jayden/Button';
import Input from 'components/jayden/Input';
import Loader from 'components/jayden/Loader';
import Tooltip from 'components/jayden/Tooltip';
import ProfileAvatar from 'components/profileAvatar/ProfileAvatar';

import useAlert from 'hooks/useAlert';
import useGroupChannel from 'hooks/useGroupChannel';

import { getChannelIdByChannelUrl } from 'utils/common';

const ChatInviteButton = () => {
  const qc = useQueryClient();
  const { t } = useTranslation();
  const alert = useAlert();

  const { channelUrl } = useParams<{ channelUrl: string }>();

  const { fetchChannels } = useGroupChannel();

  const [keyword, setKeyword] = useState('');
  const [showPopup, setShowPopup] = useState(false);
  const inviteRef = useRef<HTMLDivElement>(null);

  const { data, isLoading } = useInfiniteQuery({
    queryKey: ['inviteableUserList', channelUrl, keyword],
    queryFn: ({ pageParam = 1 }) =>
      chatApi.getGroupChannelsInviteInviteableUsers({
        channelId: getChannelIdByChannelUrl(channelUrl || ''),
        page: pageParam,
        page_size: 9999,
        search: keyword.trim()
      }),

    getNextPageParam: (lastPage) => {
      return Number(lastPage.data.currentPage || '1') + 1;
    }
  });

  const inviteableUserList = useMemo(() => {
    return data?.pages.flatMap((page) => page.data.users) || [];
  }, [data]);

  const handleButtonClick = () => {
    setShowPopup(!showPopup);
  };

  const handleKeywordChange = debounce((e: React.ChangeEvent<HTMLInputElement>) => {
    setKeyword(e.target.value.trim());
  }, 300);

  const handleClickInvite = async (item: any) => {
    try {
      if (!channelUrl) throw new Error('Cannot find channel Info');

      await chatApi.postGroupChannelsInviteUserId({
        channelId: getChannelIdByChannelUrl(channelUrl),
        userId: String(item.id)
      });

      await qc.invalidateQueries({
        queryKey: ['inviteableUserList', channelUrl]
      });

      await fetchChannels();
    } catch (error) {
      const content = isAxiosError(error) ? error.response?.data.message : (error as Error).message;
      alert.show({
        content,
        buttons: [
          {
            title: t('common.button.confirm'),
            onClick: alert.close
          }
        ]
      });
    }
  };

  useOnClickOutside(inviteRef, () => setShowPopup(false));

  return (
    <div ref={inviteRef}>
      <button
        type="button"
        className="tw-group tw-relative tw-flex tw-size-9 tw-items-center tw-justify-center tw-rounded-2 data-[open=true]:tw-bg-slate-default"
        onClick={handleButtonClick}
        data-open={showPopup}
      >
        <MemberPlusIcon className="tw-text-white tw-opacity-50 group-hover:tw-opacity-100" />
        {!showPopup && (
          <Tooltip position="bottom">
            <span>{t('chat.channelHeader.tooltip.invite')}</span>
          </Tooltip>
        )}
      </button>

      {showPopup && (
        <div className="tw-absolute tw-right-2 tw-top-[76px] tw-z-10 tw-flex tw-w-[262px] tw-flex-col tw-rounded-3 tw-border tw-border-gray-300 tw-bg-white tw-shadow-md">
          <div className="tw-p-3">
            <Input
              className="tw-peer tw-transition-all"
              type="text"
              placeholder={t('chat.menu.search.placeholder')}
              // value={keyword}
              onChange={handleKeywordChange}
              suffix={
                <SearchIcon className="tw-absolute tw-right-3 tw-top-1/2 tw-inline-block tw-size-6 -tw-translate-y-1/2 tw-cursor-pointer tw-text-gray-500 tw-transition-all peer-focus:tw-text-black-body" />
              }
            />
          </div>

          {!!keyword && isLoading && (
            <div className="tw-relative tw-flex tw-h-[125px] tw-items-center tw-justify-center">
              <Loader />
            </div>
          )}

          {!isLoading && (
            <div className="tw-max-h-[418px] tw-overflow-y-auto">
              {/* 검색 결과 없을 때 */}
              {!inviteableUserList && (
                <div className="tw-flex tw-h-[125px] tw-flex-col tw-items-center tw-justify-center tw-gap-1 tw-text-gray-600">
                  <img src="/img/com/img_unsmile3_gr.png" alt="no result" className="tw-size-[60px]" />
                  <span className="tw-text-18 tw-font-bold tw-text-gray-300">{t('chat.header.invite.noResult')}</span>
                </div>
              )}

              {/* 검색 결과 있을 때 */}
              {inviteableUserList?.length > 0 && (
                <ul className="tw-mb-1.5 tw-max-h-[200px] tw-overflow-y-auto">
                  {inviteableUserList.map((item: any) => {
                    return (
                      <li key={item.id} className="tw-flex tw-h-10 tw-justify-between tw-py-1.5 tw-pl-4 tw-pr-3">
                        <div className="tw-flex tw-items-center tw-gap-1.5">
                          <ProfileAvatar
                            userInfo={{
                              profile_image_url: item.profile_image_url,
                              ring_color: item.ring_color,
                              grade_title: item.grade_title,
                              badge_image_url: item.badge_image_url
                            }}
                            size={28}
                          />
                          <div className="tw-flex tw-max-w-[124px] tw-text-13">
                            <div className="tw-truncate tw-font-medium tw-text-black-title">
                              {/* // 키워드 하이라이팅 */}
                              {item.username?.split(keyword).map((text: string, index: number) => (
                                <span key={index}>
                                  {text}
                                  {index !== item.username?.split(keyword).length - 1 && (
                                    <span className="tw-text-point-100">{keyword}</span>
                                  )}
                                </span>
                              ))}
                            </div>
                          </div>
                        </div>

                        <Button
                          size="xs"
                          color="default"
                          className="tw-flex tw-h-7 tw-items-center tw-justify-center"
                          onClick={() => handleClickInvite(item)}
                        >
                          <CheckIcon className="tw-size-4" />
                          {t('chat.header.invite.button')}
                        </Button>
                      </li>
                    );
                  })}
                </ul>
              )}
            </div>
          )}

          {/* <div className="tw-mx-4 tw-border-t tw-border-t-gray-200">
            <div className="tw-mt-3 tw-text-13 tw-text-gray-700">Send an invite link to friends</div>

            <div className="tw-mt-1" />
            <Input
              className="tw-rounded-2 tw-border-gray-300 tw-text-13 tw-text-gray-700"
              disabled
              value="asdfasdfasdf/asdfasdf/asdf/asdf/asdf/asdf/asdfasdfasf/"
              onChange={() => {}}
              suffix={
                <CopyIcon
                  onClick={() => {
                    console.log(`copy`);
                    navigate(`/invite/${'test123'}`);
                  }}
                  className="tw-absolute tw-right-3 tw-top-1/2 tw-inline-block tw-size-5 -tw-translate-y-1/2 tw-cursor-pointer tw-text-gray-500 tw-transition-all peer-focus:tw-text-black-body"
                />
              }
            />
            <div className="tw-h-4" />
          </div> */}
        </div>
      )}
    </div>
  );
};

export default ChatInviteButton;
