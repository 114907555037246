import React, { PropsWithChildren } from 'react';

import classNames from 'classnames';

interface BallonProps extends PropsWithChildren {
  isMe?: boolean;
  className?: string;
}

const Ballon = (props: BallonProps) => {
  const { children, className, isMe } = props;

  const commonBallonClassNames =
    'tw-inline-block tw-border tw-border-gray-300 tw-py-1.5 tw-px-3 tw-relative tw-max-w-[450px]';

  return (
    <div
      className={classNames(
        commonBallonClassNames,
        'tw-rounded-b-5',
        {
          'tw-rounded-tl-5 tw-rounded-tr-0 tw-bg-gray-300': isMe,
          'tw-rounded-tr-5 rounded-tl-0 tw-bg-white': !isMe
        },
        className
      )}
    >
      {children}
    </div>
  );
};

export default Ballon;
