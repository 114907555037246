import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { MemberDto } from 'apis/types/chat.type';
import ProfileAvatar from 'components/profileAvatar/ProfileAvatar';
import classNames from 'components/styled/util';
import UserInfo from 'components/userInfo/UserInfo';

import { useCurrentChannel } from 'hooks/use-current-channel';
import useUserInfo from 'hooks/useUserInfo';

type Props = {
  memberList: MemberDto[];
};

const ChannelInfoMemberList: React.FC<Props> = ({ memberList }) => {
  return (
    <ul className="">
      {/* 방장은 master class */}
      {/* 스텝은 staff class */}
      {memberList.map((member) => {
        return <MemberItem key={member.userId} member={member} />;
      })}
    </ul>
  );
};
export default ChannelInfoMemberList;

type MemberItemProps = { member: MemberDto };

const MemberItem: React.FC<MemberItemProps> = (props) => {
  const { member } = props;

  const { data: channel } = useCurrentChannel();

  const navigate = useNavigate();
  const { data: userInfo, isLoading } = useUserInfo(member.userId);

  const onSelectMember = useCallback(
    (member: MemberDto) => () => {
      navigate(`miniprofile/${member.userId}`, {
        replace: false
      });
    },
    [navigate]
  );

  const isMaster = member.role === 'operator' && channel?.masterIds?.[0] == member.userId;

  if (isLoading || !userInfo) return <></>;

  return (
    <li
      className={classNames(
        'tw-mb-3 tw-flex tw-h-9 tw-cursor-pointer tw-items-center tw-justify-between tw-text-14 tw-font-medium',
        {
          'tw-opacity-40': member.isBlocked
        }
      )}
      onClick={onSelectMember(member)}
    >
      <div className="tw-flex tw-items-center tw-gap-2">
        <ProfileAvatar userInfo={userInfo} trustRing size={40} className="tw-z-0" />

        <p
          className={classNames('tw-w-full tw-truncate', {
            '!tw-font-semibold': !!member.role
          })}
        >
          <UserInfo.Username userId={member.userId} />
        </p>
      </div>

      <img
        src={`${process.env.PUBLIC_URL}/img/ico/ic_master_pp.png`}
        className={classNames('tw-hidden', {
          'tw-block': isMaster
        })}
      />
    </li>
  );
};
