import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { SendbirdError } from '@sendbird/chat';
import { MentionType, UserMessage, type UserMessageCreateParams } from '@sendbird/chat/message';
import { useRecoilCallback, useRecoilState } from 'recoil';

import mentionedUsersState from '../store/atoms/mentionedUsersState';
import messageInputState from '../store/atoms/messageInputState';
import { ChatMessage } from '../types/common';

import useAlert from './useAlert';
import useGroupChannel from './useGroupChannel';

const useMessageInput = () => {
  const { updateNewMessage, sendUserMessage } = useGroupChannel();

  const [messageInputStateValue, setMessageInputStateValue] = useRecoilState(messageInputState);

  const openEmojiPicker = useCallback(() => {
    setMessageInputStateValue((state) => ({
      ...state,
      isOpenedEmojiPopup: true,

      isOpenedMemberSearchPopup: false
    }));
  }, [setMessageInputStateValue]);

  const openMemberSearch = useCallback(() => {
    setMessageInputStateValue((state) => ({
      ...state,
      isOpenedEmojiPopup: false,

      isOpenedMemberSearchPopup: true
    }));
  }, [setMessageInputStateValue]);

  const closeEmojiPicker = useCallback(() => {
    setMessageInputStateValue((state) => ({
      ...state,
      isOpenedEmojiPopup: false
    }));
  }, [setMessageInputStateValue]);

  const closeMemberSearch = useCallback(() => {
    setMessageInputStateValue((state) => ({
      ...state,
      isOpenedMemberSearchPopup: false
    }));
  }, [setMessageInputStateValue]);

  const reply = useCallback(
    (parentMessage: ChatMessage) => {
      setMessageInputStateValue((state) => ({
        ...state,
        parentMessage,
        isReplyMode: true
      }));
    },
    [setMessageInputStateValue]
  );

  const closeReply = useRecoilCallback(
    ({ set }) =>
      () => {
        set(messageInputState, (state) => ({
          ...state,
          parentMessage: null,
          isReplyMode: false
        }));
      },
    []
  );

  const setMessageText = useRecoilCallback(
    ({ set }) =>
      (messageText: string) => {
        set(messageInputState, (state) => {
          return {
            ...state,
            messageText
          };
        });
      },
    []
  );

  const { show, close } = useAlert();
  const { t } = useTranslation();
  const sendMessageToChannel = useRecoilCallback(
    ({ snapshot, set }) =>
      async (channelUrl: string, params: UserMessageCreateParams): Promise<UserMessage> => {
        try {
          // 추출된 상태를 사용하여 mentionedUserIds 설정
          const mentionedUserIds = snapshot
            .getLoadable(mentionedUsersState)
            .getValue()
            .map((user) => user.userId);

          // 최종 파라미터 설정
          const finalParams: UserMessageCreateParams = {
            ...params,
            mentionedUserIds,
            mentionType: MentionType.USERS
          };
          // 메시지 전송 및 후속 처리
          const message = await sendUserMessage(channelUrl, finalParams);
          await updateNewMessage(channelUrl, message);
          window.dispatchEvent(
            new CustomEvent('sentNewMessage', {
              detail: { customType: message.customType }
            })
          );
          closeReply(); // 답장 모드 종료
          set(mentionedUsersState, []); // 언급된 사용자 상태 초기화
          return message; // 성공적으로 처리된 메시지 반환
        } catch (e) {
          let content = t('chat.messageInput.placeholder.disabled');

          if (e instanceof SendbirdError) {
            switch (e.code) {
              case 900080:
                content = t('chat.sendMessage.blockReceiver.alert');
                break;

              default:
                content = `${e.message}`;
                break;
            }
          }

          show({
            content,
            buttons: [
              {
                title: t('common.button.confirm'),
                shortcut: ['Enter', 'Escape'],
                onClick: close
              }
            ]
          });

          throw e; // 에러를 호출자에게 전달
        }
      },
    [
      // 의존성 배열 업데이트
      messageInputStateValue,
      closeReply,
      updateNewMessage
    ]
  );

  return {
    isOpenedEmojiPopup: messageInputStateValue.isOpenedEmojiPopup,

    isOpenedMemberSearchPopup: messageInputStateValue.isOpenedMemberSearchPopup,
    isReplyMode: messageInputStateValue.isReplyMode,
    parentMessage: messageInputStateValue.parentMessage,
    messageText: messageInputStateValue.messageText,
    setMessageText,
    openEmojiPicker,
    openMemberSearch,
    closeEmojiPicker,
    closeMemberSearch,
    reply,
    closeReply,
    sendMessageToChannel
  };
};

export default useMessageInput;
