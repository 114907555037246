import React, { useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { UploadToS3Response } from '../../../../libs/s3';
import { ChatMessage } from '../../../../types/common';

type Props = {
  message: ChatMessage;
  parentMessage?: ChatMessage;
};

const ChatVideoMessage: React.FC<Props> = ({ message }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const isOpenedFilePopup = location.pathname.endsWith('/attach');

  // 앱 요청으로 변경된 타입
  const parsedMessages = useMemo<UploadToS3Response[]>(() => {
    if (message.data) return JSON.parse(message.data);
    return JSON.parse(message.message);
  }, [message.message]);

  const onVideo = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      e.preventDefault();
      navigate(`video/${message.messageId}`);
    },
    [message]
  );

  return (
    <>
      <div className="">
        {parsedMessages.map((row, index) => {
          return (
            <React.Fragment key={index.toString()}>
              {isOpenedFilePopup === true && <div style={{ width: 259, height: 194 }} />}
              {isOpenedFilePopup === false && (
                <div className="tw-overflow-hidden tw-rounded-2" key={index} onClick={onVideo}>
                  <video width={259} height={194} controls autoPlay={false} style={{ zIndex: 1 }}>
                    <source src={`${row.cloudFrontUrl}#t=0.1`} type={row.contentType} />
                  </video>
                </div>
              )}
            </React.Fragment>
          );
        })}
      </div>
    </>
  );
};
export default ChatVideoMessage;
