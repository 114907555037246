import React, { ButtonHTMLAttributes, LabelHTMLAttributes, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { User } from '@sendbird/chat';
import { useRecoilValue } from 'recoil';
import styled, { css } from 'styled-components';
import { useOnClickOutside } from 'usehooks-ts';

import { ReactComponent as InviteIcon } from '../../../assets/icons/ic-invite-letter.svg';
import mixin from '../../../components/styled/mixin';
import { StyledButton, StyledColumn, StyledText } from '../../../components/styled/styled';
import { groupChannelListForInvite } from '../../../store/atoms/groupChannelsState';

import chatApi from 'apis/chat-api';
import { fetchGroupChannel } from 'libs/sendbird';

import useAlert from 'hooks/useAlert';
import useCurrentUser from 'hooks/useCurrentUser';

import { getChannelIdByChannelUrl } from 'utils/common';

interface InviteButtonProps {
  user: User;
}

interface ActiveProps {
  $isActive: boolean;
}

const InviteButton: React.FC<InviteButtonProps> = () => {
  const { userId } = useParams<{ userId: string }>();

  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  useOnClickOutside(ref, () => setIsOpen(false));

  const { t } = useTranslation();

  const groupChannelList = useRecoilValue(groupChannelListForInvite(null));

  const { currentUser } = useCurrentUser();

  const alert = useAlert();

  const onClickInviteChannel = async (url: string) => {
    const groupChannel = await fetchGroupChannel(url, true);
    const data = JSON.parse(groupChannel.data);
    if (data.participantsLimit <= groupChannel.memberCount) {
      return alert.show({
        content: t('chat.channel.limit.alert.description'),
        buttons: [
          {
            title: t('common.button.confirm'),
            onClick: alert.close
          }
        ]
      });
    }

    setIsOpen(false);

    if (!userId) return;

    try {
      await chatApi.postGroupChannelsInviteUserId({
        channelId: getChannelIdByChannelUrl(url),
        userId: String(userId)
      });
      navigate(`/channel/${url}`);
    } catch (e: any) {
      alert.show({
        content: e.response.data?.message || 'Internal Server Error',
        buttons: [
          {
            title: t('common.button.confirm'),
            onClick: alert.close
          }
        ]
      });
    }
  };

  return (
    <div className="tw-relative tw-flex tw-flex-col" ref={ref}>
      <StyledInviteButtonWrapper>
        <StyledInviteButton onClick={() => setIsOpen((prev) => !prev)}>
          <StyledInviteIcon $isActive={isOpen} />
        </StyledInviteButton>
        <StyledButtonLabel $isActive={isOpen}>{t('chat.miniProfile.button.invite')}</StyledButtonLabel>
      </StyledInviteButtonWrapper>

      {isOpen && (
        <StyledInviteChannelListPopup>
          <StyledInviteChannelListPopupHeader>
            <span>{t('chat.miniProfile.invite.title')}</span>
          </StyledInviteChannelListPopupHeader>
          <StyledInviteChannelListDivider />
          <StyledInviteChannelList>
            {groupChannelList.map((groupChannel, index) => {
              const staffList = [...groupChannel.data.masterIds, ...groupChannel.data.staffIds];
              const isStaffOrMaster = staffList.find((v) => currentUser?.userId === v);

              if (isStaffOrMaster) {
                return (
                  <StyledInviteChannel key={index} onClick={() => onClickInviteChannel(groupChannel.url)}>
                    {groupChannel.name}
                  </StyledInviteChannel>
                );
              }
            })}
          </StyledInviteChannelList>
        </StyledInviteChannelListPopup>
      )}
    </div>
  );
};

export default InviteButton;

const StyledInviteChannel = styled(StyledButton)<ButtonHTMLAttributes<any>>`
  padding: 6px 16px;
  font-size: 14px;
  line-height: 1.57;
  letter-spacing: -0.28px;
  color: #121212;
  justify-content: start;

  background-color: white;

  :hover {
    background-color: ${({ theme }) => theme.colors.gray100};
  }
`;

const StyledInviteChannelList = styled(StyledColumn)`
  overflow-y: scroll;
  padding: 8px 0;
  align-items: stretch;
`;

const StyledInviteChannelListDivider = styled(StyledColumn)`
  height: 1px;
  width: calc(100% - 30px);
  margin: 0 15px;
  background-color: ${({ theme }) => theme.colors.gray300};
`;

const StyledInviteChannelListPopupHeader = styled(StyledColumn)`
  align-items: start;
  padding: 16px 16px 8px 16px;
  span {
    font-size: 18px;
    font-weight: 600;
    line-height: 1.44;
    letter-spacing: -0.36px;
    color: ${({ theme }) => theme.colors.black200};
  }
`;

const StyledInviteChannelListPopup = styled(StyledColumn)`
  position: absolute;
  bottom: 80px;
  transform: translateX(calc(-50% + 24px));
  z-index: 10;
  width: 212px;
  max-height: 400px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  border: 1px solid ${({ theme }) => theme.colors.gray300};
  background-color: #fff;
  border-radius: 8px;
  padding-bottom: 20px;
`;

const StyledButtonLabel = styled(StyledText)<ActiveProps & LabelHTMLAttributes<any>>`
  font-size: 13px;
  line-height: 1.38;
  letter-spacing: -0.26px;
  color: ${({ theme }) => theme.colors.gray800};
  ${({ $isActive }) =>
    $isActive &&
    css`
      color: ${({ theme }) => theme.colors.primary100};
    `}
`;

const StyledInviteIcon = styled(InviteIcon)<ActiveProps>`
  width: 24px;
  height: 24px;
  ${({ $isActive }) =>
    $isActive &&
    css`
      color: ${({ theme }) => theme.colors.primary100};
    `}
`;

const StyledInviteButton = styled(StyledButton)<ButtonHTMLAttributes<any>>`
  ${mixin.transitionMixin}
  width: 48px;
  height: 48px;
  background-color: white;
  border-radius: 100%;
  border: 1px solid ${({ theme }) => theme.colors.gray300};

  :hover {
    background-color: ${({ theme }) => theme.colors.gray100};
  }

  :active {
    ${StyledInviteIcon} {
      fill: ${({ theme }) => theme.colors.primary100};
    }
  }
`;

const StyledInviteButtonWrapper = styled(StyledColumn)`
  ${mixin.transitionMixin}
  gap: 4px;

  :active {
    ${StyledButtonLabel} {
      color: ${({ theme }) => theme.colors.primary100};
    }
  }
`;
