import { useMutation } from 'react-query';

import chatApi from 'libs/apis/chat';

export const useCheckBadWordMutation = () => {
  return useMutation({
    mutationFn: chatApi.postGlobalBadWordCheck
  });
};

export const useCheckBadWord = () => {
  const { mutate } = useCheckBadWordMutation();

  const check = (text: string) => {
    return new Promise<void>((resolve, reject) => {
      mutate(
        { text },
        {
          onSuccess: (response) => {
            if (response.data.containsBadWords) {
              reject(new Error('Bad words detected'));
            } else {
              resolve();
            }
          },
          onError: (error) => {
            reject(error);
          }
        }
      );
    });
  };

  return { check };
};
