import internal from 'stream';

import { type GetObjectOutput, PutObjectCommand, S3 } from '@aws-sdk/client-s3';
import dayjs from 'dayjs';
import { v4 as uuidv4 } from 'uuid';

const s3Params = {
  region: 'ap-northeast-2',
  credentials: {
    accessKeyId: process.env.REACT_APP_AWS_S3_ACCESS_KEY || '',
    secretAccessKey: process.env.REACT_APP_AWS_S3_ACCESS_SECRET || ''
  }
};

const s3 = new S3(s3Params);

export default s3;

export enum FileType {
  File = 'FILE',
  Gif = 'GIF',
  Photo = 'PHOTO',
  Video = 'VIDEO'
}

export interface UploadToS3Response {
  localUrl: string;
  cloudFrontUrl: string;
  fileKey: string;
  fileSize: number;
  fileType: FileType;
  contentType: string;
  orgFileName: string;
}

export type UploadToS3Params = {
  dir: string;
  fileName: string;
  fileSize: number;
  contentType: string;
  orgFileName: string;
  Body: string | Blob | internal.Readable | ReadableStream<any> | Uint8Array | Buffer | undefined;
};

const imageExts = /^(jpg|jpeg|png|bmp)$/;
const videoExts = /^(mp4|avi)$/;

export async function uploadToS3({
  dir,
  fileName,
  orgFileName,
  fileSize,
  contentType,
  Body
}: UploadToS3Params): Promise<UploadToS3Response> {
  try {
    const date = dayjs().format('YYYYMMDD');
    const extName = fileName.split('.').pop() || '';

    const newFileName = uuidv4();
    const fileKey = `media/chat/${dir}/${date}/${newFileName}${extName ? `.${extName}` : ''}`;

    await s3.send(
      new PutObjectCommand({
        Bucket: process.env.REACT_APP_AWS_S3_BUCKET_NAME,
        Body,
        Key: fileKey,
        ContentType: contentType
      })
    );

    let fileType: FileType;
    if (extName.match(imageExts)) {
      fileType = FileType.Photo;
    } else if (extName === 'gif') {
      fileType = FileType.Gif;
    } else if (extName.match(videoExts)) {
      fileType = FileType.Video;
    } else {
      fileType = FileType.File;
    }

    const cloudFrontUrl = `${process.env.REACT_APP_CLOUD_FRONT_URL}/${fileKey}`;

    return {
      localUrl: fileName,
      fileKey,
      fileSize,
      fileType,
      cloudFrontUrl,
      contentType,
      orgFileName
    };
  } catch (e) {
    console.log('## e', e);
    throw e;
  }
}

// export function getCloudFrontUrl(fileKey: string | undefined | null) {
//   if (!fileKey) {
//     return `${process.env.REACT_APP_CLOUD_FRONT_URL}/empty`;
//   }
//   const cloudFrontUrl = `${process.env.REACT_APP_CLOUD_FRONT_URL}/${fileKey}`;
//   return cloudFrontUrl;
// }

export const getS3Object = async (filename: string): Promise<GetObjectOutput | undefined> =>
  new Promise((resolve, reject) => {
    s3.getObject(
      {
        Bucket: process.env.REACT_APP_AWS_S3_BUCKET_NAME_CGP_SERVER || '',
        Key: `media/common/image/${filename}`
      },
      (err, data) => {
        if (err) {
          reject(err);
        } else {
          resolve(data);
        }
      }
    );
  });

export const downloadS3File = async (fileName: string) => {
  try {
    const response = await getS3Object(fileName);
    if (response != null) {
      let blob: Blob;
      if (response.Body instanceof ReadableStream) {
        const reader = response.Body.getReader();
        const stream = new ReadableStream({
          start: async (controller) => {
            const push = () => {
              reader.read().then(({ done, value }) => {
                if (done) {
                  controller.close();
                  return;
                }
                controller.enqueue(value);
                push();
              });
            };

            push();
          }
        });
        blob = await new Response(stream).blob();
      } else if (response.Body instanceof Blob) {
        blob = response.Body;
      }
      // else if (response.Body instanceof internal.Readable) {
      //   response.Body.read();
      //   blob = await new Response(response.Body).blob();
      // }
      else {
        return;
      }

      const fileUrl = URL.createObjectURL(blob);

      const link: HTMLAnchorElement = document.createElement('a');
      link.href = fileUrl;

      link.setAttribute('download', fileName); // Use the file's original name
      document.body.appendChild(link);
      link.click();
      if (link.parentNode != null) {
        link.parentNode.removeChild(link);
      }
    }
  } catch (error) {
    console.log(error);
  }
};
