import React, { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useOnClickOutside } from 'usehooks-ts';

import { ReactComponent as CoinIcon } from 'assets/icons/ic-chat-coin.svg';
import { ReactComponent as FileIcon } from 'assets/icons/ic-file.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/ic-plus.svg';
import { ReactComponent as VoteIcon } from 'assets/icons/ic-vote.svg';

import useGroupChannel from 'hooks/useGroupChannel';
import useMessageInput from 'hooks/useMessageInput';

interface ChatMessageInputMoreProps {
  isEditable: boolean;
}

const ChatMessageInputMore = ({ isEditable }: ChatMessageInputMoreProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { closeMemberSearch } = useMessageInput();
  const { currentChannel, isDmChannel } = useGroupChannel();

  const moreRef = useRef<HTMLDivElement>(null);
  const [showMore, setShowMore] = useState(false);

  const handleClick = () => {
    if (!isEditable) return;

    setShowMore(!showMore);
    closeMemberSearch();
  };

  const handleItemClick = useCallback(
    (path: string) => {
      if (!currentChannel) return;

      setShowMore(false);
      navigate(path);
    },
    [currentChannel, navigate]
  );

  useOnClickOutside(moreRef, () => setShowMore(false));

  return (
    <div ref={moreRef} className="tw-absolute tw-bottom-1.5 tw-left-2">
      {showMore && (
        <ul className="tw-absolute tw-bottom-11 tw-z-10 tw-w-fit tw-min-w-32 tw-rounded-2 tw-border tw-border-gray-300 tw-bg-white tw-py-2">
          <li
            onClick={() => handleItemClick('attach')}
            className="tw-flex tw-cursor-pointer tw-items-center tw-gap-0.5 tw-px-3 tw-py-1.5 hover:tw-bg-gray-100"
          >
            <FileIcon className="tw-size-6" />
            <span className="tw-whitespace-nowrap tw-break-all tw-text-14 tw-text-black-title">
              {t('chat.inputMore.label.files')}
            </span>
          </li>
          <li
            onClick={() => handleItemClick('poll/create')}
            className="tw-flex tw-cursor-pointer tw-items-center tw-gap-0.5 tw-px-3 tw-py-1.5 hover:tw-bg-gray-100"
          >
            <VoteIcon className="tw-size-6" />
            <span className="tw-whitespace-nowrap tw-break-all tw-text-14 tw-text-black-title">
              {t('chat.inputMore.label.poll')}
            </span>
          </li>
          {/* DM 채널이 아닐 때만 코인 전송 메뉴 보이기 */}
          {!isDmChannel && (
            <li
              onClick={() => handleItemClick('send-coin')}
              className="tw-flex tw-cursor-pointer tw-items-center tw-gap-0.5 tw-px-3 tw-py-1.5 hover:tw-bg-gray-100"
            >
              <CoinIcon className="tw-size-6" />
              <span className="tw-whitespace-nowrap tw-break-all tw-text-14 tw-text-black-title">
                {t('chat.inputMore.label.coins')}
              </span>
            </li>
          )}
        </ul>
      )}
      <button
        type="button"
        className="tw-flex tw-size-8 tw-items-center tw-justify-center tw-rounded-full tw-bg-gray-400 tw-text-white hover:tw-bg-black-title active:tw-bg-black-title"
        disabled={!isEditable}
        onClick={handleClick}
      >
        <PlusIcon className="tw-size-6" />
      </button>
    </div>
  );
};

export default ChatMessageInputMore;
