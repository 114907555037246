import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import LinkButton from '../../../components/buttons/LinkButton';
import ModalContainer from '../../../components/layout/ModalContainer';

import classNames from 'components/styled/util';

export enum ChannelSettingTab {
  OVERVIEW,
  PERMISSIONS,
  INVITES,
  DELETE_CHANNEL
}

type Props = {
  tab: ChannelSettingTab;
  Buttons?: ReactNode;
  HeaderContent?: ReactNode;
  children: ReactNode;
  BottomContent?: ReactNode;
};

const ChannelSettingContainer: React.FC<Props> = ({ tab, Buttons, children, HeaderContent, BottomContent }) => {
  const { t } = useTranslation();

  return (
    <ModalContainer width="w680" Buttons={Buttons} HeaderContent={HeaderContent}>
      <div className="title">{t('chat.settingPopup.title')}</div>
      <div className="white_con">
        <div className="channel tabs">
          <div className="tabs_menu size_md">
            <LinkButton
              to={`../setting/overview`}
              className={classNames({
                active: tab === ChannelSettingTab.OVERVIEW
              })}
              replace={true}
            >
              <span>{t('chat.settingPopup.tab.overview')}</span>
            </LinkButton>
            <LinkButton
              to={`../setting/permissions`}
              className={classNames({
                active: tab === ChannelSettingTab.PERMISSIONS
              })}
              replace={true}
            >
              <span>{t('chat.settingPopup.tab.permissions')}</span>
            </LinkButton>

            <LinkButton
              to={`../setting/deleteChannel`}
              className={classNames({
                active: tab === ChannelSettingTab.DELETE_CHANNEL
              })}
              replace={true}
            >
              <span>{t('chat.settingPopup.tab.deleteChannel')}</span>
            </LinkButton>
          </div>
        </div>
        <div className={classNames('setting_scroll', Buttons != null ? 'tw-h-[416px]' : 'tw-h-[500px]')}>
          <div
            className={classNames({
              setting_con: true,
              tab01: tab === ChannelSettingTab.OVERVIEW
            })}
          >
            {children}
          </div>
        </div>
        {BottomContent}
      </div>
    </ModalContainer>
  );
};

export default ChannelSettingContainer;
