import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useRecoilCallback, useRecoilValue } from 'recoil';

import chatRoomExpandedState from '../../../store/atoms/chatRoomExpandedState';
import { groupChannelStateByUrl } from '../../../store/atoms/groupChannelsState';

import ChatRoomInfoNoticeList from './ChatRoomInfoNoticeList';
import ChatRoomSharedFileList from './ChatRoomSharedFileList';

import { ReactComponent as CloseIcon } from 'assets/icons/ic-close.svg';
import ProfileAvatar from 'components/profileAvatar/ProfileAvatar';
import classNames from 'components/styled/util';
import { SuperClubUserInterface } from 'libs/ApiRepository';
import MiniProfileLeftButton, { FriendRequestStatus } from 'routers/profile/MiniProfile/MiniProfileLeftButton';
import MoreOptionButton from 'routers/profile/MiniProfile/MoreOptionButton';
import SendCoinButton from 'routers/profile/MiniProfile/SendCoinButton';

import useUserInfo from 'hooks/useUserInfo';

type Props = {
  toUserId: string;
  channelUrl: string;
};

const DMChannelInfo: React.FC<Props> = ({ toUserId, channelUrl }) => {
  const channel = useRecoilValue(groupChannelStateByUrl(channelUrl));

  const { data: userInfo } = useUserInfo(toUserId);

  const [isProfileCollapsed, setIsProfileCollapsed] = useState(false);

  const { t } = useTranslation();
  const counts = useMemo(
    () => [
      { title: t('common.profile.me.item.friends'), count: userInfo?.friend_count, path: 'friends' },
      { title: t('common.profile.me.item.heart'), count: userInfo?.heart_count, path: 'hearts' },
      { title: t('common.profile.me.item.respect'), count: userInfo?.respect_count, path: 'respects' }
    ],
    [userInfo]
  );

  const onExpand = useRecoilCallback(
    ({ set }) =>
      () => {
        set(chatRoomExpandedState, true);
      },
    []
  );

  return (
    <div className="tw-h-screen tw-w-[300px] !tw-overflow-x-hidden tw-px-5 tw-pt-5">
      <div
        className={classNames('tw-relative -tw-m-5 tw-mb-0 tw-h-[140px]', {
          'tw-bg-[#c3c5cc]': !userInfo?.banner_image_url
        })}
      >
        <button type="button" className="tw-absolute tw-right-4 tw-top-4" onClick={onExpand}>
          <CloseIcon className="tw-size-6 hover:tw-text-gray-700" />
        </button>
        {userInfo?.banner_image_url && (
          <img className="tw-h-[140px] tw-w-[300px] tw-object-cover" src={userInfo?.banner_image_url} />
        )}
        <div className="tw-absolute tw-left-1/2 tw-top-[90px] tw-z-0 tw-size-[100px] -tw-translate-x-1/2">
          <ProfileAvatar userInfo={userInfo as SuperClubUserInterface} size="md" badge level trustRing />
        </div>
      </div>
      <div className="tw-mt-[54px] tw-overflow-y-auto">
        <div className="">
          <div className="tw-flex tw-items-center tw-justify-center">
            <span className="tw-ml-1 tw-text-20 tw-font-semibold tw-leading-[18px] tw-text-black-title">
              {userInfo?.username}
            </span>
          </div>
          <div className="tw-mt-2 tw-flex tw-justify-center tw-gap-0.5">
            {userInfo?.icons?.map((icon: any) => {
              if (icon.is_representative) return <></>;
              return <img src={icon?.image_url} key={icon?.id} className="tw-size-5 tw-object-cover" />;
            })}
          </div>
          {isProfileCollapsed && (
            <div>
              <div className="tw-mt-4 tw-flex tw-items-center tw-justify-center">
                {counts.map((item) => {
                  return (
                    <div className="tw-flex tw-w-1/3 tw-flex-col tw-items-center tw-justify-center" key={item.title}>
                      <div className="tw-pt-[7px] tw-text-24 tw-font-semibold tw-text-black-title">{item.count}</div>
                      <div className="tw-text-14 tw-font-light tw-text-gray-600">{item.title}</div>
                    </div>
                  );
                })}
              </div>

              <textarea
                className="tw-mt-4 tw-h-[88px] tw-w-[260px] tw-rounded-3 tw-border tw-border-gray-100 tw-bg-gray-100 tw-px-4 tw-py-3.5 tw-pr-1 tw-text-14 tw-text-secondary-100 focus:tw-border-black-body focus:tw-text-black-body"
                value={userInfo?.description}
                readOnly
                disabled
              />

              <div className="tw-h-5" />

              <div className="tw-mb-5 tw-flex tw-gap-2 tw-border-t tw-border-gray-300 tw-pt-5">
                <MiniProfileLeftButton
                  isMyProfile={false}
                  friendRequestStatus={(userInfo?.friend_request_status as FriendRequestStatus) || null}
                  userId={userInfo?.id}
                  requestId={userInfo?.friend_request_id}
                />
                <SendCoinButton channel={channel} user={userInfo as any} />
              </div>
            </div>
          )}
          <MoreOptionButton
            onClick={() => setIsProfileCollapsed((prev) => !prev)}
            isCollapsed={isProfileCollapsed}
            className="!tw-mt-5 tw-w-full"
            moreView
          />
        </div>
        <div className="chat_info_div tw-mt-4 tw-flex tw-flex-col tw-gap-4">
          <ChatRoomInfoNoticeList />
          <ChatRoomSharedFileList />
        </div>
      </div>
    </div>
  );
};

export default DMChannelInfo;
