import React from 'react';

import classNames from 'classnames';

/**
 * Primary UI component for user interaction
 */
const Loader = ({ className = '' }) => {
  return (
    <div>
      <div className={classNames('absolute left-1/2 z-[999] mt-[100px] w-[180px] -translate-x-1/2', className)}>
        {[...new Array(5)].map((_, i) => (
          <div
            key={i}
            className="[&:nth-child(2)]:tw-animation-delay-200 [&:nth-child(3)]:tw-animation-delay-300 [&:nth-child(4)]:tw-animation-delay-400 [&:nth-child(5)]:tw-animation-delay-500 tw-m-2 tw-inline-block tw-size-5 tw-scale-[0.2] tw-animate-loading-dots-scaling tw-rounded-full tw-bg-primary-100 tw-opacity-20 tw-transition-all"
          />
        ))}
        <div className="tw-mt-4 tw-flex tw-items-center tw-justify-center tw-text-15 tw-text-primary-100">loading</div>
      </div>
    </div>
  );
};

export default Loader;
