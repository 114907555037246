import React, { useCallback, useMemo } from 'react';

import useMessage from '../../../hooks/useMessage';
import useMessageInput from '../../../hooks/useMessageInput';
import { UploadToS3Response } from '../../../libs/s3';
import { ChatMessage, CustomMessageType } from '../../../types/common';

import { ReactComponent as CloseIcon } from 'assets/icons/ic-close.svg';
import { ReactComponent as ReplyIcon } from 'assets/icons/ic-reply-sm-bk.svg';

type Props = {
  parentMessage: ChatMessage;
};
const ChatMessageReplyInput: React.FC<Props> = ({ parentMessage }) => {
  const message = useMessage({
    message: parentMessage
  });

  const { closeReply } = useMessageInput();

  const onClose = useCallback(() => {
    closeReply();
  }, [closeReply]);

  const fileList = useMemo<UploadToS3Response[]>(() => {
    try {
      const parsedList = JSON.parse(parentMessage.message).slice(0, 5);
      return parsedList;
    } catch (e) {
      return [];
    }
  }, [parentMessage]);

  return (
    <div className="tw-absolute tw-top-[-46px] tw-z-10 tw-mx-5 tw-h-10 tw-w-[calc(100%-40px)] tw-rounded-5 tw-bg-[rgba(36,42,60,0.8)]">
      <div className="tw-relative tw-flex tw-h-10 tw-w-full tw-items-center tw-justify-between tw-px-4">
        <div className="tw-flex tw-items-center">
          <span className="tw-relative tw-mr-3 tw-flex tw-gap-2 tw-text-14 tw-font-medium tw-text-gray-400">
            <ReplyIcon className="tw-size-5" />
            <span>{message.user?.nickname}</span>
          </span>
          {/* 첨부파일 답글 시에는 댓글처럼 파일명만 텍스트로 보여주시면 됩니다. */}
          {message.customType === CustomMessageType.TEXT && (
            <p className="tw-truncate tw-text-13 tw-text-white">{parentMessage.message}</p>
          )}
          {/* 이미지 답글 시 이미지 미리보기 (최대 5개 / 링크없음) */}
          {message.customType === CustomMessageType.IMAGE && (
            <ul className="tw-inline-flex tw-items-center tw-gap-1">
              {fileList.map((image) => (
                <li key={image.cloudFrontUrl} className="tw-size-7 tw-overflow-hidden tw-rounded">
                  <img className="tw-size-full tw-object-cover" src={image.cloudFrontUrl} />
                </li>
              ))}
            </ul>
          )}
          {/* 동영상 답글 시 동영상 미리보기 (링크없음) */}
          {message.customType === CustomMessageType.VIDEO && (
            <ul>
              {fileList.map((video) => (
                <li key={video.cloudFrontUrl} className="tw-size-7 tw-overflow-hidden tw-rounded">
                  <video width="100%" height="100%">
                    <source src={video.cloudFrontUrl} type={video.contentType} />
                  </video>
                </li>
              ))}
            </ul>
          )}
        </div>

        <button type="button" className="" onClick={onClose}>
          <CloseIcon className="tw-size-5 tw-text-white" />
          <span className="tw-sr-only" />
        </button>
      </div>
    </div>
  );
};

export default ChatMessageReplyInput;
