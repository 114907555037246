import { useCallback, useEffect } from 'react';
import { useQueryClient } from 'react-query';

import { BaseChannel, User } from '@sendbird/chat';
import { GroupChannel, GroupChannelHandler } from '@sendbird/chat/groupChannel';
import { useRecoilCallback } from 'recoil';

import getSendBird from '../libs/sendbird';
import { groupChannelIdsState } from '../store/atoms/groupChannelsState';
import userState from '../store/atoms/userState';
import { CustomChannelType } from '../types/common';

import useGroupChannel from './useGroupChannel';

const useGroupChannelHandler = () => {
  const qc = useQueryClient();
  const { fetchChannels, upsertChannelState, removeChannelState, setCurrentChannel } = useGroupChannel();

  const onChannelChanged = useCallback((channel: BaseChannel) => {
    upsertChannelState(channel as GroupChannel);
  }, []);

  const onChannelDeleted = useRecoilCallback(
    ({ snapshot }) =>
      async (channelUrl: string) => {
        const updatedGroupChannelIds = snapshot.getLoadable(groupChannelIdsState).getValue();

        const filtered = updatedGroupChannelIds.filter((url) => url !== channelUrl);

        if (filtered.length > 0) {
          await setCurrentChannel(filtered[0]);
          console.log('from to', filtered[0], channelUrl);
        }

        removeChannelState(channelUrl);
      },
    []
  );

  const onUserReceivedInvitation = useRecoilCallback(
    ({ snapshot }) =>
      (channel: GroupChannel, inviter: User, invitees: User[]) => {
        const currentUser = snapshot.getLoadable(userState).getValue();

        if (!currentUser) {
          return;
        }

        const itsMe = invitees.find((invitee) => invitee.userId === currentUser.userId);

        if (itsMe) {
          const chatChannel = upsertChannelState(channel);
          if (chatChannel.customType === CustomChannelType.DM) {
            channel.acceptInvitation();
          }
        }
      },
    [fetchChannels]
  );

  useEffect(() => {
    const handler = new GroupChannelHandler({
      onChannelChanged,
      onChannelDeleted,
      onChannelFrozen: () => {},
      onChannelUnfrozen: () => {},
      onMetaDataCreated: (channel: BaseChannel) => {
        console.log('useGroupChannelHandler', 'onMetaDataCreated', channel);
      },
      onMetaCounterUpdated: () => {},
      onMetaCounterDeleted: () => {},
      onChannelHidden: () => {},
      onUserReceivedInvitation,
      onUserDeclinedInvitation: () => {},
      onUserJoined: (channel) => {
        qc.invalidateQueries(['channel', channel.url]);
      },
      onUserLeft: (channel) => {
        qc.invalidateQueries(['channel', channel.url]);
      },
      onUndeliveredMemberStatusUpdated: () => {},
      onUnreadMemberStatusUpdated: () => {},
      onTypingStatusUpdated: () => {},
      onUserMuted: () => {},
      onUserUnmuted: () => {},
      onUserBanned: () => {},
      onUserUnbanned: () => {},
      onChannelMemberCountChanged: () => {}
    });

    const handlerId = `global_handler`;

    getSendBird().groupChannel.addGroupChannelHandler(handlerId, handler);

    return () => {
      getSendBird().groupChannel.removeGroupChannelHandler(handlerId);
    };
  }, [onChannelChanged, onChannelDeleted]);
};

export default useGroupChannelHandler;
