import React from 'react';

import { useRecoilValue } from 'recoil';

import classNames from 'components/styled/util';
import chatRoomExpandedState from 'store/atoms/chatRoomExpandedState';

const ChatDiv = (props: React.PropsWithChildren) => {
  const { children } = props;

  const isChatRoomExpanded = useRecoilValue(chatRoomExpandedState);

  return (
    <div
      className={classNames('tw-relative tw-grid tw-h-screen tw-w-screen tw-bg-white', {
        'tw-grid-cols-[60px_300px_1fr_300px]': !isChatRoomExpanded,
        'tw-grid-cols-[60px_300px_1fr]': isChatRoomExpanded
      })}
    >
      {children}
    </div>
  );
};

export default ChatDiv;
