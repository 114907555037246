import React from 'react';

import Ballon from 'components/jayden/ballon';
import { ChatMessage } from 'types/common';

type Props = {
  url: string;
  parentMessage?: ChatMessage;
  isMe: boolean;
};

const ChatGifMessage: React.FC<Props> = ({ url, parentMessage, isMe }) => {
  return (
    <>
      <Ballon isMe={isMe}>
        {!!parentMessage && (
          <>
            {/* 답글 원글 내용이 상단에 나옵니다. (글쓴이 / 내용 두 줄)  */}
            {/* 답글 원글 클릭 시 원글로 이동합니다.  */}
            <div className="tw-text-12 tw-font-medium tw-text-secondary-100">{parentMessage.sender.nickname}</div>
            <span className="tw-mb-2 tw-line-clamp-2 tw-text-ellipsis tw-text-12 tw-text-gray-500">
              {parentMessage.message}
            </span>
          </>
        )}
        <span className="tw-relative tw-block tw-max-w-[300px] tw-border-t tw-border-gray-200 tw-pt-2">
          <img src={url} />
        </span>
      </Ballon>
    </>
  );
};
export default ChatGifMessage;
