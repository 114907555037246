import React, { MouseEventHandler, useCallback, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { User } from '@sendbird/chat';
import dayjs from 'dayjs';
import { useRecoilValue } from 'recoil';

import { groupChannelStateByUrl } from '../../../store/atoms/groupChannelsState';
import { ChatChannel, CustomChannelType, CustomMessageType } from '../../../types/common';

import { ReactComponent as BookmarkIcon } from 'assets/icons/bookmark-star.svg';
import { ReactComponent as DefaultProfileImage } from 'assets/images/default_user_profile_01.svg';
import classNames from 'components/styled/util';
import UserInfo from 'components/userInfo/UserInfo';

import useUserMe from 'hooks/use-user-me';

// import useGroupChannel from 'hooks/useGroupChannel';

interface ChannelThumbnailProps {
  channel: ChatChannel;
}

const ChannelThumbnail: React.FC<ChannelThumbnailProps> = (props) => {
  const { channel } = props;

  const myInfo = useUserMe();
  // const { leaveChannel } = useGroupChannel();

  if (channel.customType === CustomChannelType.CLUB) {
    return <img className="tw-size-full tw-rounded-br-4 tw-rounded-tl-4 tw-object-cover" src={channel.coverUrl} />;
  }
  if (channel.customType === CustomChannelType.GROUP) {
    return <img className="tw-size-full tw-rounded-3 tw-object-cover" src={channel.coverUrl} />;
  }
  const members = channel.members.filter(
    (member) => Number(member.userId) !== myInfo?.id && !member.userId.includes('Guest')
  );
  if (members.length === 0) {
    // leaveChannel(channel.url, myInfo?.id?.toString());
    return <DefaultProfileImage className="tw-size-full" />;
  }
  return (
    <UserInfo.ProfileImage userId={members?.[0]?.userId} className="tw-size-full tw-rounded-full tw-object-cover" />
  );
};

const BookmarkButton: React.FC<{
  className?: string;
  isBookmarked: boolean;
  onClick: MouseEventHandler<HTMLOrSVGElement>;
}> = (props) => {
  const { className = '', isBookmarked, onClick } = props;
  return (
    <BookmarkIcon
      className={classNames(
        'tw-cursor-pointer [&>*]:tw-transition-colors',
        {
          '[&>.bookmark-background]:tw-text-primary-100 [&>.bookmark-shadow]:tw-text-[#710fc8] [&>.bookmark-star-shadow]:tw-text-[#6311ac]':
            isBookmarked,
          '[&>.bookmark-background]:tw-text-[#cdccd2] [&>.bookmark-shadow]:tw-text-[#b2b2bc] [&>.bookmark-star-shadow]:tw-text-[#b2b2bc]':
            !isBookmarked
        },
        className
      )}
      onClick={onClick}
    />
  );
};

export enum FilterType {
  ON_CHAT,
  CLUBS,
  ALL
}

type Props = {
  isNow?: boolean;
  channelUrl: string;
  currentUser: User;
  onChange: () => void;
  onToggleBookMark?: () => void;
};

const ChannelListItem: React.FC<Props> = ({ isNow = false, currentUser, channelUrl, onChange, onToggleBookMark }) => {
  const channel = useRecoilValue(groupChannelStateByUrl(channelUrl));

  const myInfo = useUserMe();
  const { t } = useTranslation();

  const lastMessageTime = channel.lastMessage?.createdAt;
  const diffBetweenLastMessage = useMemo(() => {
    const diffSecond = dayjs().diff(dayjs(lastMessageTime), 'second');
    if (diffSecond < 60) {
      return `${diffSecond}s`;
    }
    if (diffSecond < 60 * 60) {
      return `${Math.floor(diffSecond / 60)}m`;
    }
    if (diffSecond < 60 * 60 * 24) {
      return `${Math.floor(diffSecond / 60 / 60)}h`;
    }

    return dayjs(lastMessageTime).format('MMM. D');
  }, [lastMessageTime]);

  const onClick = useCallback(() => {
    onChange();
  }, [onChange]);

  const lastMessage = useMemo(() => {
    if (channel.lastMessage?.customType === CustomMessageType.VIDEO) {
      return CustomMessageType.VIDEO;
    }

    if (channel.lastMessage?.customType === CustomMessageType.FILE) {
      return CustomMessageType.FILE;
    }

    if (channel.lastMessage?.customType === CustomMessageType.IMAGE) {
      return CustomMessageType.IMAGE;
    }

    if (channel.lastMessage?.customType === CustomMessageType.INVITATION) {
      return null;
    }

    if (channel.lastMessage?.customType === CustomMessageType.MASTER_TRANSFER) {
      return null;
    }

    if (channel.lastMessage?.customType === CustomMessageType.SYSTEM) {
      return null;
    }

    if (channel.lastMessage?.customType === CustomMessageType.JOIN) {
      return (
        <Trans i18nKey="common.globalChat.joinMsg.text" values={{ username: channel.lastMessage?.message }}>
          <span className="tw-font-medium" />
        </Trans>
      );
    }

    if (channel.lastMessage?.customType === CustomMessageType.LEAVE) {
      return (
        <Trans i18nKey="common.globalChat.leaveMsg.text" values={{ username: channel.lastMessage?.message }}>
          <span className="tw-font-medium" />
        </Trans>
      );
    }

    return channel.lastMessage?.message;
  }, [channel.lastMessage]);

  const isBookMarked = useMemo(() => {
    return channel.data.bookmarkUserIds.indexOf(currentUser.userId) > -1;
  }, [channel.data.bookmarkUserIds, currentUser]);

  const channelTitle = useMemo(() => {
    if (channel.customType === CustomChannelType.GROUP || channel.customType === CustomChannelType.CLUB) {
      return channel.name;
    }

    const filteredMembers = channel.members.filter(
      (member) => Number(member.userId) !== myInfo?.id && !member.userId.includes('Guest')
    );

    if (filteredMembers.length === 0) {
      return `(${t('chat.dmChannelInfo.title.unknown')})`;
    }

    return filteredMembers.map((member) => member.nickname).join(', ');
  }, [channel.members, myInfo?.id]);

  return (
    <div
      className={classNames(
        'tw-flex tw-h-[88px] tw-w-full tw-cursor-pointer tw-gap-3 tw-px-5 tw-py-2.5 tw-transition-all',
        {
          'tw-bg-primary-100/5': isNow,
          'hover:tw-bg-gray-100': !isNow
        }
      )}
      onClick={onClick}
    >
      <div className="tw-relative tw-size-[68px] tw-shrink-0">
        <ChannelThumbnail channel={channel} />

        {channel.customType !== CustomChannelType.DM && (
          <BookmarkButton
            isBookmarked={isBookMarked}
            onClick={(e) => {
              e.stopPropagation();
              onToggleBookMark?.();
            }}
            className="tw-absolute tw-right-0.5 tw-top-0 tw-size-5"
          />
        )}

        {channel.unreadMessageCount > 0 && (
          <span className="tw-absolute tw-bottom-1 tw-left-1 tw-flex tw-h-4 tw-items-center tw-justify-center tw-rounded-2 tw-bg-point-100 tw-px-1 tw-text-11 tw-font-medium tw-text-white">
            {channel.unreadMessageCount}
          </span>
        )}
      </div>
      <div className="tw-flex tw-w-full tw-flex-col tw-gap-0.5 tw-py-0.5">
        <div className="tw-flex tw-w-full tw-items-center tw-justify-between tw-gap-2">
          <p
            className={classNames(
              'tw-line-clamp-1 tw-h-6 tw-break-all tw-text-16 tw-font-semibold tw-text-black-body',
              { 'tw-text-primary-100': isNow }
            )}
          >
            {channelTitle}
          </p>
          <span className="tw-shrink-0 tw-text-14 tw-text-gray-500">{diffBetweenLastMessage}</span>
        </div>
        <div className="tw-line-clamp-2 tw-w-full tw-break-all tw-text-13 tw-text-gray-700">{lastMessage}</div>
      </div>
    </div>
  );
};
export default ChannelListItem;
