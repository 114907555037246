import React from 'react';
import { useTranslation } from 'react-i18next';

import Ballon from 'components/jayden/ballon';

interface ChatCoinTransactionMessageProps {
  senderName: string;
  data: any;
  isMe: boolean;
}

const ChatCoinTransactionMessage: React.FC<ChatCoinTransactionMessageProps> = (props) => {
  const { senderName, data, isMe } = props;

  const { t } = useTranslation();

  return (
    <Ballon isMe={isMe} className="ballon tw-max-w-[300px] !tw-border-gray-300 !tw-bg-white tw-text-primary-100">
      <div className="tw-text-18 tw-font-semibold">{t('chat.message.coinCompleted.title')}</div>
      <div className="tw-mt-2 tw-flex tw-flex-col tw-gap-2 tw-border-t tw-border-gray-300 tw-px-1 tw-pt-2">
        <span className="tw-text-12">
          {t('chat.message.coinCompleted.description', {
            sender: senderName,
            symbol: data?.symbol ?? 'COIN',
            receiver: data?.receiverName
          })}
        </span>
      </div>
      {/* <StyledFooter>
        <StyledVoteButton onClick={handleClickVoteButton}>
          <span>Vote</span>
        </StyledVoteButton>
      </StyledFooter> */}
    </Ballon>
  );
};

export default ChatCoinTransactionMessage;
