import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { RouterProvider } from 'react-router-dom';

import { useSetRecoilState } from 'recoil';

import router from './routers/router';
import { PostMessagePath, RequestCommand, ResponseCommand } from './types/postMessageCommand';

import getSendBird from 'libs/sendbird';
import navigatePathState from 'store/atoms/navigatePathState';

import { setAccessToken } from 'utils/common';
import { clearStorage } from 'utils/storage';

import './i18n';
import 'tailwind.css';

function App() {
  const { i18n } = useTranslation();

  const setNavigatePath = useSetRecoilState(navigatePathState);
  const messageHandler = useCallback(
    (event: MessageEvent) => {
      if (event.origin.indexOf('localhost') === -1 && event.origin.indexOf('superclub') === -1) {
        return;
      }

      if (typeof event.data !== 'object' || event.data.from !== PostMessagePath.Superclub) {
        return;
      }

      if (event.data.command === ResponseCommand.Initialize) {
        const accessToken = event.data.accessToken as string;
        const navigatePath = event.data?.navigatePath as string;
        const language = event.data?.language as string;

        if (navigatePath) {
          localStorage.setItem('navigatePath', navigatePath);
          setNavigatePath(navigatePath);
        }

        if (language) {
          i18n.changeLanguage(language);
        }

        setAccessToken(accessToken);
      }
    },
    [i18n]
  );

  useEffect(() => {
    window.addEventListener('message', messageHandler, false);

    window?.opener?.postMessage(
      {
        from: PostMessagePath.Chat,
        command: RequestCommand.Ready
      },
      '*'
    );

    return () => {
      window.removeEventListener('message', messageHandler);
    };
  }, [messageHandler]);

  useEffect(() => {
    if (!window.opener) {
      clearStorage();
      window.location.href = process.env.REACT_APP_MAIN_URL as string;
    }

    return () => {
      getSendBird().disconnect();
    };
  }, []);

  return <RouterProvider router={router} />;
}

export default App;
