import React, { ButtonHTMLAttributes, HTMLAttributes, LabelHTMLAttributes, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { User } from '@sendbird/chat';
import styled, { css } from 'styled-components';
import { useOnClickOutside } from 'usehooks-ts';

import { ReactComponent as SwitchIcon } from '../../../assets/icons/ic-switch.svg';
import mixin from '../../../components/styled/mixin';
import { StyledButton, StyledColumn, StyledText } from '../../../components/styled/styled';
import Switch from '../../../components/switch/Switch';
import useGroupChannel from '../../../hooks/useGroupChannel';
import { fetchGroupChannel, fetchUser } from '../../../libs/sendbird';

import { useCurrentChannel } from 'hooks/use-current-channel';

interface PermitButtonProps {
  // channel: ChatChannel;
  user: User;
  disabled?: boolean;
}

const PermitButton: React.FC<PermitButtonProps> = (props) => {
  const { user, disabled = false } = props;

  const { data: channel } = useCurrentChannel({ noRedirect: true });

  const [isOpen, setIsOpen] = useState(false);
  const { updateChannel, inviteChannel } = useGroupChannel();
  const ref = useRef<HTMLDivElement>(null);
  useOnClickOutside(ref, () => setIsOpen(false));

  const { t } = useTranslation();

  enum UserPermit {
    VIEW_CHANNEL,
    SEND_MESSAGE,
    SEND_VOTE_MESSAGE,
    SHARE_SCREEN
  }

  const isBannedMember = (channel?.viewChannelDisabledUserIds || [])?.indexOf?.(user.userId) > -1;
  const isSendable = !((channel?.sendMessageDisabledUserIds || [])?.indexOf?.(user.userId) > -1);

  const [userPermitList, setUserPermitList] = useState([
    { key: UserPermit.VIEW_CHANNEL, value: !isBannedMember },
    { key: UserPermit.SEND_MESSAGE, value: isSendable },
    { key: UserPermit.SEND_VOTE_MESSAGE, value: false },
    { key: UserPermit.SHARE_SCREEN, value: false }
  ]);

  const userPermitUIList = useMemo(
    () => [
      {
        label: t('chat.miniProfile.permit.viewChannel'),
        key: UserPermit.VIEW_CHANNEL,
        disabled: false
      },
      {
        label: t('chat.miniProfile.permit.sendMessage'),
        key: UserPermit.SEND_MESSAGE,
        disabled: false
      }
      // {
      //   label: "Send Vote Message",
      //   key: UserPermit.SEND_VOTE_MESSAGE,
      //   disabled: true,
      // },
      // { label: "Share Screen", key: UserPermit.SHARE_SCREEN, disabled: true },
    ],
    [t]
  );

  if (!channel) return <></>;

  const onToggleSendable = async () => {
    if (isSendable) {
      await updateChannel(channel.channelUrl, {
        data: {
          sendMessageDisabledUserIds: [...channel.sendMessageDisabledUserIds, user.userId]
        }
      });
    } else {
      await updateChannel(channel?.channelUrl, {
        data: {
          sendMessageDisabledUserIds: channel.sendMessageDisabledUserIds.filter((userId) => user.userId !== userId)
        }
      });
    }
  };

  const onToggleViewChannel = async () => {
    const groupChannel = await fetchGroupChannel(channel?.channelUrl);
    const selectedUserId = user.userId;

    if (isBannedMember) {
      const user = await fetchUser(selectedUserId);
      try {
        await groupChannel.unbanUserWithUserId(selectedUserId);
        if (user) {
          await inviteChannel(channel.channelUrl, '', [user.userId]);
        }
      } catch (e) {
        console.log(e);
      }

      await updateChannel(channel?.channelUrl, {
        data: {
          viewChannelDisabledUserIds: channel.viewChannelDisabledUserIds.filter((userId) => selectedUserId !== userId),
          viewChannelHistories: [
            ...channel.viewChannelHistories,
            {
              disabled: false,
              userId: selectedUserId,
              reason: '',
              duration: 60 * 5,
              createdAt: Date.now()
            }
          ]
        }
      });
    } else {
      await groupChannel.banUserWithUserId(selectedUserId, 60 * 5, '');
      await updateChannel(channel?.channelUrl, {
        data: {
          viewChannelDisabledUserIds: [...channel.viewChannelDisabledUserIds, selectedUserId],
          viewChannelHistories: [
            ...channel.viewChannelHistories,
            {
              disabled: true,
              userId: selectedUserId,
              reason: '',
              duration: 60 * 5,
              createdAt: Date.now()
            }
          ]
        }
      });
    }
  };

  const handleSwitch = (key: UserPermit) => {
    setUserPermitList((prev) => prev.map((item) => (item.key === key ? { ...item, value: !item.value } : item)));
    switch (key) {
      case UserPermit.VIEW_CHANNEL:
        onToggleViewChannel();
        break;
      case UserPermit.SEND_MESSAGE:
        onToggleSendable();
        break;
      case UserPermit.SEND_VOTE_MESSAGE:
        break;
      case UserPermit.SHARE_SCREEN:
        break;
      default:
        break;
    }
  };

  return (
    <div className="tw-relative tw-flex tw-flex-col" ref={ref}>
      <StyledPermitButtonWrapper $disabled={disabled}>
        <StyledPermitButton onClick={() => setIsOpen((prev) => !prev)} disabled={disabled}>
          <StyledSwitchIcon $isActive={isOpen} />
        </StyledPermitButton>
        <StyledButtonLabel $isActive={isOpen}>{t('chat.miniProfile.button.permit')}</StyledButtonLabel>
      </StyledPermitButtonWrapper>

      {isOpen && (
        <StyledUserPermitPopup>
          <StyledUserPermitPopupHeader>
            <span>{t('chat.miniProfile.permit.title')}</span>
          </StyledUserPermitPopupHeader>
          <StyledUserPermitDivider />

          <StyledUserPermitList>
            {userPermitUIList.map((item) => (
              <div className="tw-flex tw-justify-between tw-bg-white tw-px-4 tw-py-1.5" key={item.key}>
                <StyledUserPermitLabel>{item.label}</StyledUserPermitLabel>

                <Switch
                  isChecked={userPermitList.find((permit) => permit.key === item.key)?.value}
                  onToggle={() => {
                    handleSwitch(item.key);
                  }}
                  disabled={item.disabled}
                />
              </div>
            ))}
          </StyledUserPermitList>
        </StyledUserPermitPopup>
      )}
    </div>
  );
};

export default PermitButton;

interface StyledActiveProps {
  $isActive: boolean;
}

const StyledUserPermitLabel = styled(StyledText)`
  font-size: 14px;
  line-height: 1.57;
  letter-spacing: -0.28px;
  color: #121212;
`;

const StyledUserPermitList = styled(StyledColumn)`
  overflow-y: scroll;
  padding: 8px 0;
  align-items: stretch;
`;

const StyledUserPermitDivider = styled(StyledColumn)`
  height: 1px;
  width: calc(100% - 30px);
  margin: 0 15px;
  background-color: ${({ theme }) => theme.colors.gray300};
`;

const StyledUserPermitPopupHeader = styled(StyledColumn)`
  align-items: start;
  padding: 16px 16px 8px 16px;
  span {
    font-size: 18px;
    font-weight: 600;
    line-height: 1.44;
    letter-spacing: -0.36px;
    color: ${({ theme }) => theme.colors.black200};
  }
`;

const StyledUserPermitPopup = styled(StyledColumn)`
  position: absolute;
  bottom: 80px;
  transform: translateX(calc(-50% + 24px));
  z-index: 10;
  width: 240px;
  max-height: 400px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  border: 1px solid ${({ theme }) => theme.colors.gray300};
  background-color: #fff;
  border-radius: 8px;
  padding-bottom: 20px;
`;

interface StyledButtonLabelProps extends LabelHTMLAttributes<HTMLLabelElement>, StyledActiveProps {}

const StyledButtonLabel = styled(StyledText)<StyledButtonLabelProps>`
  font-size: 13px;
  line-height: 1.38;
  letter-spacing: -0.26px;
  color: ${({ theme }) => theme.colors.gray800};
  ${({ $isActive }) =>
    $isActive &&
    css`
      color: ${({ theme }) => theme.colors.primary100};
    `}
`;

const StyledSwitchIcon = styled(SwitchIcon)<StyledActiveProps>`
  width: 24px;
  height: 24px;
  ${({ $isActive }) =>
    $isActive &&
    css`
      color: ${({ theme }) => theme.colors.primary100};
    `}
`;

const StyledPermitButton = styled(StyledButton)<ButtonHTMLAttributes<any>>`
  ${mixin.transitionMixin}
  width: 48px;
  height: 48px;
  background-color: white;
  border-radius: 100%;
  border: 1px solid ${({ theme }) => theme.colors.gray300};

  :hover:not(:disabled) {
    background-color: ${({ theme }) => theme.colors.gray100};
  }

  :active:not(:disabled) {
    ${StyledSwitchIcon} {
      fill: ${({ theme }) => theme.colors.primary100};
    }
  }
`;

interface StyledPermitButtonWrapperProps extends HTMLAttributes<HTMLDivElement> {
  $disabled?: boolean;
}

const StyledPermitButtonWrapper = styled(StyledColumn)<StyledPermitButtonWrapperProps>`
  ${mixin.transitionMixin}
  gap: 4px;

  ${({ $disabled }) =>
    !$disabled
      ? css`
          &:active {
            ${StyledButtonLabel} {
              color: ${({ theme }) => theme.colors.primary100};
            }
          }
        `
      : css`
          ${StyledButtonLabel} {
            color: ${({ theme }) => theme.colors.gray500};
          }
        `}
`;
