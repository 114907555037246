import { useCallback } from 'react';

import chatApi, { PostGroupChannelPayload, PutGroupChannelInfoPayload } from 'apis/chat-api';

const useNewGroupChannel = () => {
  const createChannel = useCallback(async (payload: PostGroupChannelPayload) => {
    try {
      const response = await chatApi.postGroupChannel(payload);

      return response;
    } catch (error) {
      console.error('useNewGroupChannel > createChannel', error);
    }
  }, []);

  const updateChannel = useCallback(async (payload: PutGroupChannelInfoPayload) => {
    try {
      const response = await chatApi.putGroupChannelInfo(payload);
      return response;
    } catch (error) {
      console.error('useNewGroupChannel > updateChannel', error);
    }
  }, []);

  const deleteChannel = useCallback(async (channelId: string) => {
    try {
      const response = await chatApi.deleteGroupChannel({ channelId });

      return response;
    } catch (error) {
      console.error('useNewGroupChannel > deleteChannel', error);
    }
  }, []);

  return {
    createChannel,
    updateChannel,
    deleteChannel
  };
};

export default useNewGroupChannel;
