import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

// import { ReactComponent as TradeIcon } from '../../../assets/icons/ic-wallet-coin.svg';
import CretaLogo from 'assets/images/creta.png';
import Button from 'components/jayden/Button';
import { SuperClubUserInterface } from 'libs/ApiRepository';
import { ChatChannel } from 'types/common';
import { PostMessagePath, RequestCommand } from 'types/postMessageCommand';

import useUserMe from 'hooks/use-user-me';

interface SendCoinButtonProps {
  user: SuperClubUserInterface;
  channel: ChatChannel;
}

const SendCoinButton = (props: SendCoinButtonProps) => {
  const [busy, setBusy] = useState(false);
  const { user, channel } = props;
  const { t } = useTranslation();
  const myInfo = useUserMe();

  const toAddress = user.wallet_address;
  const toUserId = user.id;
  const urlPath = `/user-profile/wallet?toAddress=${toAddress}&toUserId=${toUserId}&toUsername=${user.username}&channelUrl=${channel?.url}`;

  const handleClickSend = () => {
    if (busy) return;
    setBusy(true);
    window?.opener?.postMessage(
      {
        from: PostMessagePath.Chat,
        command: RequestCommand.SendCoins,
        urlPath
      },
      '*'
    );

    setTimeout(() => {
      setBusy(false);
    }, 1000);
  };

  return (
    <div className="">
      <Button
        color="default"
        className="tw-flex tw-items-center tw-justify-center tw-gap-1 tw-whitespace-nowrap tw-text-15"
        size="md"
        onClick={handleClickSend}
        disabled={busy || !toAddress || !myInfo?.wallet_address}
      >
        <img src={CretaLogo} className="tw-size-6" />
        {t('chat.inputMore.label.coins')}
      </Button>
    </div>
  );
};

export default SendCoinButton;
