import React, { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';

import type { FileMessage, UserMessage } from '@sendbird/chat/lib/__definition';
import { useOnClickOutside } from 'usehooks-ts';

import ModalContainer from '../../components/layout/ModalContainer';
import useAlert from '../../hooks/useAlert';
import useGroupChannel from '../../hooks/useGroupChannel';
import useTextInputChange from '../../hooks/useTextInputChange';
import api from '../../libs/apis';
import queryKeys from '../../libs/apis/queryKeys';
import { ReportChoiceInterface } from '../../libs/apis/response.type';

import classNames from 'components/styled/util';
import getSendBird, { postReportChannel, postReportMessage } from 'libs/sendbird';

import useCurrentUser from 'hooks/useCurrentUser';

type ReportMessage = UserMessage | FileMessage;

type Params = {
  messageId: string;
};

const CHANNEL_TYPE = {
  group: 'group_channels',
  open: 'open_channels'
} as { [key: string]: string };

const Report: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { show, close } = useAlert();
  const { messageId } = useParams<Params>();
  const { currentUser: myInfo } = useCurrentUser();
  const { currentChannel } = useGroupChannel();

  const [isOpenedReason, setIsOpenedReason] = useState<boolean>(false);
  const [description, onChangeDescription] = useTextInputChange('', 100);
  const [reason, setReason] = useState<ReportChoiceInterface | null>(null);

  const selectRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(selectRef, () => {
    setIsOpenedReason(false);
  });

  const reportChoicesQuery = useQuery<ReportChoiceInterface[]>({
    queryKey: [queryKeys.report.choices],
    queryFn: async () => {
      const response = await api.getReportChoices();
      return response.data;
    },
    onSuccess: (data) => {
      setReason(data[0]);
    }
  });

  const onSuccess = () => {
    show({
      title: t('common.reportPopup.alert.finish.title'),
      content: t('common.reportPopup.alert.finish.contents'),
      buttons: [
        {
          title: t('common.button.confirm'),
          onClick() {
            close();
            navigate(-1);
          }
        }
      ]
    });
  };

  const onError = () => {
    show({
      content: t('common.reportPopup.alert.finish.contents'),
      buttons: [
        {
          title: t('common.button.confirm'),
          onClick() {
            close();
            navigate(-1);
          }
        }
      ]
    });
  };

  const postReportChannelMutation = useMutation({
    mutationFn: postReportChannel,
    onSuccess,
    onError
  });

  const postReportMessageMutation = useMutation({
    mutationFn: postReportMessage,
    onSuccess,
    onError
  });

  const onSendReport = async () => {
    if (reason == null || currentChannel == null) return;

    const channelType = CHANNEL_TYPE[currentChannel.channelType];
    const channelUrl = currentChannel.url;

    if (messageId) {
      const message = (await getSendBird().message.getMessage({
        channelType: currentChannel.channelType,
        channelUrl,
        messageId: Number(messageId)
      })) as ReportMessage;

      const payload = {
        report_category: 'custom',
        custom_report_category_name: reason.title,
        offending_user_id: message?.sender?.userId,
        reporting_user_id: String(myInfo?.userId),
        report_description: description
      };

      postReportMessageMutation.mutate({
        channelType,
        channelUrl,
        messageId,
        payload
      });
    } else {
      const payload = {
        report_category: 'custom',
        custom_report_category_name: reason.title,
        reporting_user_id: String(myInfo?.userId),
        report_description: description
      };
      postReportChannelMutation.mutate({
        channelType,
        channelUrl,
        payload
      });
    }
  };

  const onToggleReason = useCallback(() => {
    setIsOpenedReason((state) => !state);
  }, []);

  return (
    <ModalContainer
      width="w480"
      height={560}
      Buttons={[
        <button className="btn primary button_lg" key={0} onClick={onSendReport} disabled={reason == null}>
          <span>Report</span>
        </button>
      ]}
    >
      <>
        <div className="title">{t('common.reportPopup.title')}</div>
        <div className="gray_con line report" style={{ height: 394 }}>
          <div className="report_text">{t('common.reportPopup.description.progress')}</div>
          <div className="select_wrap" ref={selectRef}>
            <div
              className={classNames({
                select: true,
                active: isOpenedReason
              })}
            >
              <div className="selected" onClick={onToggleReason} style={{ cursor: 'pointer' }}>
                <div className="selected-value">{reason?.title}</div>
                <button type="button" className="arrow">
                  <span className="a11y">선택</span>
                </button>
              </div>
              <ul>
                {reportChoicesQuery.data?.map((choice) => {
                  return (
                    <li
                      className="option"
                      key={choice.id}
                      onClick={() => {
                        setReason(choice);
                        setIsOpenedReason(false);
                      }}
                    >
                      {choice.title}
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
          <div className="select_text">{reason?.title === 'Etc' ? '' : reason?.content}</div>
          <div className="textarea_div !tw-h-[152px]">
            <textarea
              placeholder={t('common.reportPopup.reason.placeholder')}
              value={description}
              onChange={onChangeDescription}
            />
          </div>
        </div>
      </>
    </ModalContainer>
  );
};

export default Report;
