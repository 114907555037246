import axios from 'axios';

import { createAxiosInstance } from './create-axios-instance';

/**
 * Sendbird API instance
 */
const SENDBIRD_API_URL = `https://api-${process.env.REACT_APP_SENDBIRD_APP_KEY}.sendbird.com/v3`;

const sendbirdInstance = axios.create({
  baseURL: SENDBIRD_API_URL,
  headers: {
    'Content-Type': 'application/json; charset=utf-8',
    'Api-Token': process.env.REACT_APP_SENDBIRD_API_TOKEN
  }
});

/**
 * SuperClub API instances
 */
const API_VERSION = 'v1';
const COMMON_BASE_URL = `${process.env.REACT_APP_COMMON_API_URL}/${API_VERSION}/`;
const CHAT_BASE_URL = `${process.env.REACT_APP_CHAT_API_URL}/${API_VERSION}/`;
const CLUB_BASE_URL = `${process.env.REACT_APP_CLUB_API_URL}/${API_VERSION}/`;
const FORUM_BASE_URL = `${process.env.REACT_APP_FORUM_API_URL}/${API_VERSION}/`;
const VOTE_BASE_URL = `${process.env.REACT_APP_VOTE_API_URL}/${API_VERSION}/`;

const commonInstance = createAxiosInstance({ baseURL: COMMON_BASE_URL, withToken: true, withLang: true });
const chatInstance = createAxiosInstance({ baseURL: CHAT_BASE_URL, withToken: true, withLang: true });
const clubInstance = createAxiosInstance({ baseURL: CLUB_BASE_URL, withToken: true, withLang: true });
const forumInstance = createAxiosInstance({ baseURL: FORUM_BASE_URL, withToken: true, withLang: true });
const voteInstance = createAxiosInstance({ baseURL: VOTE_BASE_URL, withToken: true, withLang: true });

export { sendbirdInstance, commonInstance, chatInstance, clubInstance, forumInstance, voteInstance };
