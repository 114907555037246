import React from 'react';
import { useTranslation } from 'react-i18next';

import EmojiPicker, { type EmojiClickData } from 'emoji-picker-react';
import { useRecoilCallback } from 'recoil';

import useElementSize from '../../../hooks/useElementSize';
import useMessageInput from '../../../hooks/useMessageInput';
import groupChannelState from '../../../store/atoms/groupChannelState';
import { CustomMessageType } from '../../../types/common';

const ChatMessageEmoticonEmojiInput = () => {
  const { closeEmojiPicker, sendMessageToChannel, messageText, setMessageText } = useMessageInput();

  const [containerRef, { width: containerWidth, height: containerHeight }] = useElementSize();

  const { t } = useTranslation();

  const onSelectEmoji = useRecoilCallback(
    ({ snapshot }) =>
      async (emoji: EmojiClickData) => {
        const channel = snapshot.getLoadable(groupChannelState).getValue();

        if (!channel) {
          return;
        }

        if (!messageText) {
          await sendMessageToChannel(channel.url, {
            message: emoji.emoji,
            customType: CustomMessageType.TEXT
          });
        } else {
          setMessageText(`${messageText}${emoji.emoji}`);
        }

        closeEmojiPicker();
      },
    [closeEmojiPicker]
  );

  return (
    <>
      <div className="tw-h-[calc(100%-83px)] [&_.epr-emoji-category-label]:tw-max-h-2.5" ref={containerRef}>
        {containerWidth > 0 && containerHeight > 0 && (
          <EmojiPicker
            onEmojiClick={onSelectEmoji}
            searchDisabled={true}
            height={containerHeight}
            width={containerWidth}
            previewConfig={{
              showPreview: false
            }}
            skinTonesDisabled
            searchPlaceHolder={t('chat.stickerPopup.tabs.emoji')}
          />
        )}
      </div>
    </>
  );
};

export default ChatMessageEmoticonEmojiInput;
