import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import useCurrentUser from '../../hooks/useCurrentUser';

import { ReactComponent as ChatIcon } from 'assets/icons/ic-chat-logo.svg';
import ProfileAvatar from 'components/profileAvatar/ProfileAvatar';

import useUserMe from 'hooks/use-user-me';

const LeftMenu = () => {
  // const { t } = useTranslation();
  const navigate = useNavigate();
  // const { currentChannel } = useGroupChannel();
  const { currentUser } = useCurrentUser();
  // const onVote = useCallback(async () => {
  //   if (!currentChannel) {
  //     return;
  //   }
  //   navigate('poll/create');
  // }, [currentChannel, navigate]);

  const userInfo = useUserMe();

  const onProfile = useCallback(() => {
    if (document.location.href.indexOf('miniprofile') > -1) {
      navigate(-1);
      return;
    }

    navigate(`miniprofile/${currentUser?.userId}`, {
      replace: false
    });
  }, [navigate]);

  return (
    <div className="tw-flex tw-h-full tw-max-h-screen tw-flex-col tw-items-center tw-justify-between tw-bg-black-title">
      <div className="tw-px-2.5 tw-py-5">
        <ChatIcon className="tw-m-1 tw-size-8" />

        <div className="tw-h-[100px]" />

        {/* <div className="tw-group tw-relative">
          <button type="button" className="tw-cursor-pointer hover:tw-rounded-2 hover:tw-bg-[#50566a]" onClick={onVote}>
            <VoteIcon className="tw-m-1 tw-text-white tw-opacity-70 hover:tw-opacity-100" />
          </button>
          <Tooltip position="right">{t('chat.pollPopup.title')}</Tooltip>
        </div> */}
      </div>

      {userInfo && (
        <div className="tw-flex tw-max-h-[164px] tw-w-10 tw-flex-col tw-gap-5 tw-border-t tw-border-t-slate-default tw-py-5">
          <ProfileAvatar size={40} userInfo={userInfo} onClick={onProfile} trustRing />
        </div>
      )}
    </div>
  );
};

export default LeftMenu;
