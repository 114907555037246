import { GroupChannelDto } from './types/chat.type';

import { chatInstance } from 'apis';
import { DjangoInterface } from 'libs/apis/response.type';

export type PostGroupChannelPayload = {
  name: string;
  coverUrl: string;
  isPublic: boolean;
  participantsLimit: number;
  introduction: string;
};

export type PostClubChannelPayload = PostGroupChannelPayload & {
  onlyClubMembers: boolean;
  clubId: number;
};

export type GroupChannelParams = {
  channelId: string;
};

export type GroupChannelInviteableUserListParams = GroupChannelParams & {
  search?: string;
  page?: number;
  page_size?: number;
};

export type PutGroupChannelInfoPayload = GroupChannelParams &
  Partial<PostGroupChannelPayload> & {
    forbiddenWords: string[];
  };

export type GroupChannelListParams = {
  type?: 'CLUB' | 'DM' | 'GROUP';
};

const chatApi = {
  getGroupChannelList: async (params?: GroupChannelListParams) => {
    const { data } = await chatInstance.get('/group-channels', { params });
    return data;
  },

  postGroupChannel: async (payload: PostGroupChannelPayload) => {
    const { data } = await chatInstance.post('/group-channels', payload);
    return data;
  },
  getGroupChannel: async ({ channelId }: GroupChannelParams) => {
    const { data } = await chatInstance.get<DjangoInterface<GroupChannelDto>>(`/group-channels/${channelId}`);
    return data;
  },
  putGroupChannelInfo: async ({ channelId, ...payload }: PutGroupChannelInfoPayload) => {
    const { data } = await chatInstance.put(`/group-channels/${channelId}/info`, payload);
    return data;
  },
  deleteGroupChannel: async ({ channelId }: GroupChannelParams) => {
    const { data } = await chatInstance.delete(`/group-channels/${channelId}`);
    return data;
  },

  /**
   * media
   */
  postFileUploadImageChannelId: async ({ channelId, formData }: GroupChannelParams & { formData: FormData }) => {
    const { data } = await chatInstance.post(`/file/upload/image/${channelId}`, {
      data: {
        formData
      }
    });
    return data;
  },

  /**
   * 03. GroupChannel (user)
   */
  putGroupChannelChannelIdJoin: async ({ channelId }: GroupChannelParams) => {
    const { data } = await chatInstance.put(`/group-channels/${channelId}/join`);
    return data;
  },
  putGroupChannelChannelIdLeave: async ({ channelId }: GroupChannelParams) => {
    const { data } = await chatInstance.put(`/group-channels/${channelId}/leave`);
    return data;
  },

  /**
   * 04. GroupChannelInvites
   */
  postGroupChannelsInviteUserId: async ({ channelId, userId }: { channelId: string; userId: string }) => {
    const { data } = await chatInstance.post(`/group-channels/invite/${channelId}/invite/${userId}`);
    return data;
  },
  /**
   * @see https://chat.server.dev.superclubs.io/docs/#/04.%20GroupChannelInvites/GroupChannelInviteController_getInviteableUsers
   */
  getGroupChannelsInviteInviteableUsers: async ({ channelId, ...params }: GroupChannelInviteableUserListParams) => {
    const { data } = await chatInstance.get(`/group-channels/invite/${channelId}/inviteable-users`, { params });
    return data;
  },

  /**
   * 05. ClubChannels
   */
  postClubChannels: async (payload: PostClubChannelPayload) => {
    const { data } = await chatInstance.post('/club-channels', payload);
    return data;
  },
  /**
   * 05. DMChannels
   */
  postDmChannels: async (payload: { toUserId: number }) => {
    const { data } = await chatInstance.post('/dm-channels', payload);
    return data;
  },

  /**
   * 10. Users
   */
  getUserFriends: async ({ userId }: { userId: string }) => {
    const { data } = await chatInstance.get(`/users/${userId}/friends`);
    return data;
  }
};

export default chatApi;
