import React, { MouseEventHandler, useMemo } from 'react';

import LevelCap from './LevelCap';
import TrustRing from './TrustRing';

import classNames from 'components/styled/util';

interface ProfileAvatarProps {
  userInfo: any;
  trustRing?: boolean;
  badge?: boolean;
  level?: boolean;
  size?: number | string;
  className?: string;
  onClick?: MouseEventHandler;
}

const ProfileAvatar: React.FC<ProfileAvatarProps> = (props) => {
  const { userInfo, className, onClick, trustRing = false, level = false, badge = false, size = 100 } = props;
  const {
    profile_image_url,
    ring_color: ringColor,
    grade_title: grade,
    badge_image_url: badgeImageUrl
  } = userInfo ?? {};

  const getSize = useMemo<number>(() => {
    const isNumber = typeof size === 'number';

    if (isNumber) {
      return size;
    } else {
      switch (size) {
        case 'sm':
          return 48;
        case 'md':
          return 100;
        case 'lg':
          return 200;
        default:
          return 100;
      }
    }
  }, [size]);

  return (
    <div
      style={{
        width: getSize,
        height: getSize
      }}
      className={classNames(
        'tw-relative tw-z-0 tw-flex tw-shrink-0 tw-cursor-pointer tw-items-center tw-justify-center tw-rounded-full',
        className
      )}
      onClick={onClick}
    >
      {level && (
        <div className="tw-absolute tw-top-[-16%] tw-z-[3] tw-flex tw-justify-center">
          <LevelCap grade={grade} />
        </div>
      )}
      <div className="tw-z-[2] tw-flex tw-h-[92%] tw-w-[92%] tw-items-center tw-justify-center tw-rounded-full">
        {trustRing && <TrustRing ringColor={ringColor} width={getSize} />}
        <img className="!tw-size-[92%] tw-rounded-full tw-object-cover" src={profile_image_url} alt="profile image" />
      </div>
      {badge && (
        <div className="tw-absolute tw-bottom-0 tw-right-0 tw-z-[3] tw-h-[32%] tw-w-[32%]">
          {badgeImageUrl && <img className="!tw-size-full tw-object-cover" src={badgeImageUrl} alt="badge" />}
        </div>
      )}
    </div>
  );
};

export default ProfileAvatar;
