import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { Link, useNavigate, useParams } from 'react-router-dom';

import { User } from '@sendbird/chat';
import { MemberState } from '@sendbird/chat/groupChannel';
import { isAxiosError } from 'axios';
import { orderBy } from 'lodash';

import useAlert from '../../../hooks/useAlert';
import useCurrentUser from '../../../hooks/useCurrentUser';
import useGroupChannel from '../../../hooks/useGroupChannel';
import { ChatChannel, CustomChannelType } from '../../../types/common';

import ChannelListItem, { FilterType } from './ChannelListItem';

import chatApi from 'apis/chat-api';
import { ReactComponent as CreateChannelIcon } from 'assets/icons/ic-create-channel.svg';
import { ReactComponent as SearchIcon } from 'assets/icons/ic-search.svg';
import Input from 'components/jayden/Input';
import Tooltip from 'components/jayden/Tooltip';
import classNames from 'components/styled/util';
import apis from 'libs/apis';
import queryKeys from 'libs/apis/queryKeys';

import { useCurrentChannel } from 'hooks/use-current-channel';

import { getChannelIdByChannelUrl } from 'utils/common';

enum OrderType {
  NEW,
  BEST
}

const ChannelList: React.FC = () => {
  const { channels, addBookmark, removeBookmark, fetchChannels } = useGroupChannel();
  const { channelUrl, clubId, dmUserId } = useParams<{ channelUrl?: string; clubId?: string; dmUserId?: string }>();

  const { data: currentChannel } = useCurrentChannel();

  const { currentUser } = useCurrentUser();
  const { t } = useTranslation();

  const navigate = useNavigate();
  const { show, close } = useAlert();
  const [filterType, setFilterType] = useState(FilterType.ON_CHAT);
  const [orderType, setOrderType] = useState<OrderType | null>(OrderType.NEW);
  const [isShowSearchInput, setIsShowSearchInput] = useState(false);

  const [keyword, setKeyword] = useState('');

  const onChangeKeyword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setKeyword(e.target.value);
  };

  const myClubIdQuery = useQuery({
    queryKey: queryKeys.user.getMyClubIdList(),
    queryFn: apis.getMyClubIdList,
    select: (response) => response.data?.map((club) => club.id)
  });

  const onChange = useCallback(
    (channel: ChatChannel) => async () => {
      const channelUrl = channel.url;
      const joined = channel.members.find((member) => member.userId === currentUser?.userId);

      try {
        if (!joined) {
          await chatApi.putGroupChannelChannelIdJoin({ channelId: getChannelIdByChannelUrl(channelUrl) });
        }
        navigate(`/channel/${channelUrl}`);
        // await setCurrentChannel(channelUrl);
      } catch (e) {
        let content = t('chat.alert.joinError.contents');

        if (isAxiosError(e)) {
          const message = e.response?.data?.message;
          switch (message) {
            case 'This is a private channel':
              content = t('chat.menu.alert.privateChannel');
              break;
            case 'The channel has reached its member limit':
              content = t('chat.menu.alert.overflow');
              break;
            case 'You are not a member of this club':
              content = t('chat.menu.alert.notClubMember');
              break;
          }
        }

        show({
          content,
          buttons: [
            {
              title: t('common.button.confirm'),
              onClick() {
                close();
              }
            }
          ]
        });
      }
    },
    [navigate, currentUser, myClubIdQuery]
  );

  const totalOnChat = useMemo(() => {
    let filtered = channels.filter((channel) => channel.joinedAt > 0);
    if (keyword) {
      filtered = filtered.filter((channel) => channel.name.indexOf(keyword) > -1);
    }
    return filtered.length;
  }, [channels, keyword]);

  const totalClubs = useMemo(() => {
    if (myClubIdQuery.isLoading || !myClubIdQuery.data?.length) return 0;

    let filtered = channels.filter(
      (channel) =>
        channel.customType === CustomChannelType.CLUB && myClubIdQuery.data.includes(Number(channel.data.clubId))
    );

    if (keyword) {
      filtered = filtered.filter((channel) => channel.name.indexOf(keyword) > -1);
    }
    return filtered.length;
  }, [channels, keyword, myClubIdQuery.isLoading]);

  const totalAll = useMemo(() => {
    let filtered = channels;
    if (keyword) {
      filtered = filtered.filter((channel) => channel.name.indexOf(keyword) > -1);
    }

    filtered = filtered.filter((channel) => channel.customType !== CustomChannelType.DM);

    return filtered.length;
  }, [channels, keyword]);

  const filteredChannel = useMemo(() => {
    let filtered: ChatChannel[] = [];

    if (filterType === FilterType.ON_CHAT) {
      filtered = channels.filter((channel) => channel.myMemberState === MemberState.JOINED);
    }
    if (filterType === FilterType.CLUBS) {
      if (myClubIdQuery.isLoading || !myClubIdQuery.data?.length) return [];

      filtered = channels.filter(
        (channel) =>
          channel.customType === CustomChannelType.CLUB && myClubIdQuery.data.includes(Number(channel.data.clubId))
      );
    }
    if (filterType === FilterType.ALL) {
      filtered = channels.filter((channel) => channel.customType !== CustomChannelType.DM);
    }

    if (keyword) {
      filtered = filtered.filter((channel) => channel.name.indexOf(keyword) > -1);
    }

    if (orderType === OrderType.NEW) {
      return orderBy(filtered, ['createdAt', 'memberCount', 'name'], ['desc', 'desc', 'asc']);
    }

    if (orderType === OrderType.BEST) {
      return orderBy(filtered, ['memberCount', 'createdAt', 'name'], ['desc', 'desc', 'asc']);
    }

    return filtered;
  }, [filterType, channels, keyword, orderType, myClubIdQuery.isLoading]);

  /**
   * On Chat으로 이동했는데 filteredChannel에 부합하는 채널이 없다면 All 탭으로 이동시키기
   */
  useEffect(() => {
    if (filterType === FilterType.ON_CHAT) {
      if (!filteredChannel.length) {
        setFilterType(FilterType.ALL);
      }
    }
  }, [filterType]);

  useEffect(() => {
    if (channelUrl || clubId || dmUserId) return;

    if (channels.length) {
      const onChat = channels.filter((channel) => channel.myMemberState === MemberState.JOINED);
      // setCurrentChannel(onChat?.[0]?.url);
      navigate(`/channel/${onChat?.[0]?.url}`);
      // navigate(`/channel/${filteredChannel[0].url}`);
    }
  }, [channels]);

  /**
   * 현재 띄워놓은 채널이 없어도 All로 이동 (empty)
   */
  useEffect(() => {
    if (!currentChannel) {
      setFilterType(FilterType.ALL);
    }
  }, [currentChannel]);

  const bookMarkedChannel = useMemo(() => {
    if (typeof currentUser?.userId !== 'string') {
      return [];
    }
    return filteredChannel.filter((channel) => channel.data.bookmarkUserIds.some((id) => id === currentUser.userId));
  }, [filterType, filteredChannel, currentUser?.userId]);

  const unbookMarkedChannel = useMemo(() => {
    if (typeof currentUser?.userId !== 'string') {
      return filteredChannel;
    }
    return filteredChannel.filter((channel) => !channel.data.bookmarkUserIds.some((id) => id === currentUser.userId));
  }, [filterType, filteredChannel, currentUser?.userId]);

  const onChangeOrderType = useCallback(
    (newOrderType: OrderType) => () => {
      setOrderType(newOrderType);
    },
    []
  );

  const onTabFilter = useCallback(
    (value: FilterType) => async () => {
      setFilterType(value);

      if (value === FilterType.ON_CHAT) {
        setOrderType(null);
      } else {
        if (orderType === null) {
          setOrderType(OrderType.BEST);
        }
      }

      if (value === FilterType.ALL) {
        await fetchChannels();
      }
    },
    [orderType]
  );

  const onToggleBookMark = useCallback(
    (channel: ChatChannel) => async () => {
      if (!currentUser?.userId) return;
      if (channel.data.bookmarkUserIds.some((id) => id === currentUser.userId)) {
        await removeBookmark(channel.url, [currentUser.userId]);
      } else {
        await addBookmark(channel.url, [currentUser.userId]);
      }
    },
    [currentUser?.userId]
  );

  const tabs = [
    {
      key: FilterType.ON_CHAT,
      label: t('chat.menu.tabs.onChat'),
      count: totalOnChat || 0
    },
    {
      key: FilterType.CLUBS,
      label: t('chat.menu.tabs.clubs'),
      count: totalClubs
    },
    {
      key: FilterType.ALL,
      label: t('chat.menu.tabs.all'),
      count: totalAll
    }
  ];

  const listWrapperHeight = useMemo(() => {
    const headerHight = isShowSearchInput ? 133 : 77;
    const filterHight = filterType === FilterType.ON_CHAT ? 0 : 41;
    const paddingTop = 14;
    return `calc(100vh - ${headerHight + filterHight}px - ${paddingTop}px)`;
  }, [isShowSearchInput, filterType]);

  const tabRefs = useRef<HTMLButtonElement[]>([]);

  useEffect(() => {
    fetchChannels();
  }, []);

  return (
    <div className="tw-h-screen tw-w-[300px] tw-pt-3.5">
      {/* CHANNEL HEAD */}
      <div className="tw-flex tw-flex-col">
        <div className="tw-mx-5 tw-mb-3 tw-flex tw-items-center tw-justify-between">
          <span className="tw-text-20 tw-font-semibold tw-text-black-title">{t('chat.menu.header.title')}</span>
          <div className="tw-flex tw-gap-2">
            <div className="tw-group tw-relative">
              <button
                type="button"
                className="tw-flex tw-size-8 tw-items-center tw-justify-center tw-rounded-2 tw-transition-all hover:tw-bg-gray-200"
                onClick={() => setIsShowSearchInput(!isShowSearchInput)}
              >
                <SearchIcon />
              </button>
              <Tooltip position="bottom">
                <span className="tw-whitespace-nowrap">{t('chat.header.searchChannel.tooltip')}</span>
              </Tooltip>
            </div>
            <Link to={'create'} className="tw-group tw-relative">
              <button
                type="button"
                className="tw-flex tw-size-8 tw-items-center tw-justify-center tw-rounded-2 tw-transition-all hover:tw-bg-gray-200"
              >
                <CreateChannelIcon />
              </button>
              <Tooltip position="bottom">
                <span className="tw-whitespace-nowrap">{t('chat.header.createChannel.tooltip')}</span>
              </Tooltip>
            </Link>
          </div>
        </div>

        {/* CHANNEL SEARCH */}
        <div
          className={classNames('tw-mx-5 tw-h-14 tw-pb-3 tw-transition-all', {
            'tw-overflow-hidden tw-h-0 tw-pb-0': !isShowSearchInput
          })}
        >
          <div className="tw-w-[260px]">
            <div className="tw-relative">
              <Input
                className="tw-peer tw-transition-all"
                type="text"
                placeholder={t('chat.menu.search.placeholder')}
                value={keyword}
                onChange={onChangeKeyword}
                suffix={
                  <SearchIcon className="tw-absolute tw-right-3 tw-top-1/2 tw-inline-block tw-size-6 -tw-translate-y-1/2 tw-cursor-pointer tw-text-gray-500 tw-transition-all peer-focus:tw-text-black-body" />
                }
              />
            </div>
          </div>
        </div>

        {/* CHANNEL TABS */}
        <div className="tw-relative tw-mx-5 tw-mt-0 tw-border-b tw-border-gray-400">
          <div className="tw-flex tw-items-center tw-gap-7">
            {tabs.map((tab, i) => (
              <button
                key={tab.key}
                ref={(el) => {
                  if (el) tabRefs.current[i] = el;
                }}
                type="button"
                className={classNames(
                  'tw-group tw-px-0.5 tw-pb-2 tw-text-14 tw-text-gray-600 disabled:tw-cursor-not-allowed disabled:tw-opacity-60',
                  {
                    'tw-font-semibold tw-text-primary-100': filterType === tab.key
                  }
                )}
                onClick={onTabFilter(tab.key)}
                disabled={tab.count === 0}
              >
                <div className="tw-flex tw-items-center tw-gap-0.5">
                  <span>{tab.label}</span>
                  <span
                    className={classNames(
                      'tw-flex tw-h-4 tw-items-center tw-rounded-2 tw-px-1 tw-text-11 tw-font-medium tw-transition-all',
                      {
                        'tw-text-white tw-bg-primary-100': filterType === tab.key,
                        'tw-text-gray-600 tw-bg-gray-100 group-hover:tw-text-gray-800': filterType !== tab.key
                      }
                    )}
                  >
                    {tab.count}
                  </span>
                </div>
              </button>
            ))}
          </div>

          {/* tab bar */}
          <div
            className="tw-absolute tw-bottom-0 tw-h-0.5 tw-bg-primary-100 tw-transition-all"
            style={{
              width: tabRefs.current?.[filterType]?.offsetWidth || 0,
              left: tabRefs?.current?.[filterType]?.offsetLeft || 0
            }}
          />
        </div>
      </div>

      {/* CHANNEL BEST, NEW */}
      {/* 현재 참여중인 클럽, 북마크 된 채널을 제외한 리스트 정렬순서 : 1. 참여인원 많은 순 -> 2. 동순위 시 최근 개설일시 -> 3. 동순위 시 abc 순 */}
      {filterType !== FilterType.ON_CHAT && (
        <div className="tw-inline-flex tw-h-[41px] tw-w-[300px] tw-items-center tw-justify-end tw-gap-5 tw-pr-5">
          <button type="button" onClick={onChangeOrderType(OrderType.BEST)} className="tw-flex tw-items-center">
            <img
              src={`${process.env.PUBLIC_URL}/img/ico/ic_check_bk.png`}
              className={classNames('tw-size-4', {
                'tw-invisible': orderType !== OrderType.BEST,
                'tw-visible': orderType === OrderType.BEST
              })}
            />

            <span
              className={classNames('tw-text-13 tw-leading-[20px]', {
                'tw-font-medium tw-text-black-title': orderType === OrderType.BEST,
                'tw-text-gray-600': orderType !== OrderType.BEST
              })}
            >
              {t('chat.menu.tabs.best')}
            </span>
          </button>

          <button type="button" onClick={onChangeOrderType(OrderType.NEW)} className="tw-flex tw-items-center">
            <img
              src={`${process.env.PUBLIC_URL}/img/ico/ic_check_bk.png`}
              className={classNames('tw-size-4', {
                'tw-invisible': orderType !== OrderType.NEW,
                'tw-visible': orderType === OrderType.NEW
              })}
            />
            <span
              className={classNames('tw-text-13 tw-leading-[20px]', {
                'tw-font-medium tw-text-black-title': orderType === OrderType.NEW,
                'tw-text-gray-600': orderType !== OrderType.NEW
              })}
            >
              {t('chat.menu.tabs.new')}
            </span>
          </button>

          {/* 현재 참여중인 클럽, 북마크 된 채널을 제외한 리스트 정렬순서 : 1. 최근 개설일시 -> 2. 동순위 시 참여인원 많은 순 -> 3. 동순위 시 abc 순  */}
        </div>
      )}

      {/* CHANNEL BOOKMARK LIST */}
      <div className="tw-overflow-y-auto tw-p-0 tw-transition-all" style={{ height: listWrapperHeight }}>
        {bookMarkedChannel.map((channel) => {
          const isNow = currentChannel?.channelUrl === channel.url;
          const key = channel.url;
          return (
            <ChannelListItem
              key={key}
              isNow={isNow}
              currentUser={currentUser as User}
              channelUrl={channel.url}
              onChange={onChange(channel)}
              onToggleBookMark={onToggleBookMark(channel)}
            />
          );
        })}

        {/* CHANNEL LIST */}

        {unbookMarkedChannel.map((channel) => {
          const isNow = currentChannel?.channelUrl === channel.url;
          const key = channel.url;
          return (
            <ChannelListItem
              key={key}
              isNow={isNow}
              currentUser={currentUser as User}
              channelUrl={channel.url}
              onChange={onChange(channel)}
              onToggleBookMark={onToggleBookMark(channel)}
            />
          );
        })}
      </div>
    </div>
  );
};
export default ChannelList;

//https://lclink.locuschain.com/locus-meet/#/room/test4
