import React, { useCallback, useState } from 'react';

import { downloadS3File } from '../../libs/s3';

interface GalleryVoteOptionProps {
  index: number;
  sourceList: string[];
  onClose?: () => void;
}

const GalleryVoteOption: React.FC<GalleryVoteOptionProps> = (props) => {
  const { index: _index, sourceList, onClose } = props;
  const [index, setIndex] = useState<number>(_index);

  const onLeft = useCallback(() => {
    setIndex((state) => {
      let newIndex = state - 1;
      if (newIndex < 0) {
        newIndex = sourceList.length - 1;
      }
      return newIndex;
    });
  }, [sourceList]);

  const onRight = useCallback(() => {
    setIndex((state) => {
      let newIndex = state + 1;
      if (newIndex > sourceList.length - 1) {
        newIndex = 0;
      }
      return newIndex;
    });
  }, [sourceList]);

  // /**
  //  * @TODO s3에 있는 이미지는 어떻게 다운받는지?
  //  */
  const onDownload = useCallback(
    async (sourceIndex: number) => {
      const imageUrl = sourceList[sourceIndex];
      const fileName = imageUrl.split('/').pop();

      if (fileName != null) {
        await downloadS3File(fileName);
      }
    },
    [sourceList]
  );

  if (sourceList.length === 0) {
    return null;
  }

  return (
    <>
      <div id="modal" />
      <div className="chat_img_preview">
        {/* 미리비로기에 보여지는 이미지는 가로 1120px, 세로 600px을 넘지 않습니다.*/}
        <div className="img_preview_area">
          <button type="button" className="img_pre_btn left" onClick={onLeft}>
            <span className="a11y">좌측으로</span>
          </button>
          <button type="button" className="img_pre_btn right" onClick={onRight}>
            <span className="a11y">우측으로</span>
          </button>
          <button type="button" className="img_btn down" onClick={() => onDownload(index)}>
            <span className="a11y">다운로드</span>
          </button>
          <button type="button" className="img_btn close" onClick={onClose}>
            <span className="a11y">닫기</span>
          </button>
          <img src={sourceList[index]} style={{ maxHeight: '100%', maxWidth: '100%', objectFit: 'cover' }} />
          <div className="img_number">
            <span>
              <em>{index + 1}</em> / {sourceList.length}
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default GalleryVoteOption;
