import React from 'react';

import Ballon from 'components/jayden/ballon';

export interface ChatCustomMessageProps {
  name: string;
  imageUrl?: string;
  content: string;

  buttonGroup?: React.ReactNode;
}

const ChatCustomMessage: React.FC<ChatCustomMessageProps> = (props) => {
  const { name, imageUrl, content, buttonGroup = null } = props;

  return (
    // ballon power
    <Ballon>
      {/* chat_con */}
      <span className="!tw-text-left tw-text-primary-200">
        {/* channel_name */}
        <div className="tw-mb-2 tw-inline-flex tw-h-10 tw-items-center">
          {imageUrl != null && <img className="tw-size-10 tw-object-cover" src={imageUrl} />}

          {/* channel_name_text */}
          <span className="tw-ml-3 tw-text-16 tw-font-semibold tw-text-black-title">{name}</span>
        </div>
        {/* power_con */}
        <div className="tw-whitespace-pre-line tw-border-t tw-border-gray-300 tw-px-1 tw-pt-3 tw-text-left tw-text-13 tw-font-light tw-text-black-body">
          {content}
        </div>

        {buttonGroup}
      </span>
    </Ballon>
  );
};

export default ChatCustomMessage;
