import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import useGroupChannel from './useGroupChannel';

import chatApi from 'apis/chat-api';

import { getChannelIdByChannelUrl } from 'utils/common';

export interface CurrentChannelOptions {
  noRedirect?: boolean;
}

export const useCurrentChannel = (options?: CurrentChannelOptions) => {
  const { channelUrl } = useParams();

  const { setCurrentChannel } = useGroupChannel();

  const query = useQuery({
    queryKey: ['channel', channelUrl],
    queryFn: () => chatApi.getGroupChannel({ channelId: getChannelIdByChannelUrl(channelUrl!) }),
    select: (response) => response.data,
    enabled: !!channelUrl
  });

  useEffect(() => {
    if (!channelUrl) return;

    setCurrentChannel(channelUrl, options);
  }, [channelUrl]);

  return {
    ...query,
    url: channelUrl || ''
  };
};
