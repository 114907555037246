import { atom } from 'recoil';

import { ChatChannel } from '../../types/common';

const groupChannelState = atom<ChatChannel | null>({
  key: 'groupChannelState',
  default: null
});

export default groupChannelState;
