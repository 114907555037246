import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { UserMessage } from '@sendbird/chat/message';
import { useRecoilValueLoadable } from 'recoil';

import { downloadURI, getIsMaster, getIsStaff } from '../../../libs/helper';
import { UploadToS3Response } from '../../../libs/s3';
import noticesSelector from '../../../store/selectors/noticesSelector';
import { CustomMessageType } from '../../../types/common';

import { ReactComponent as ChevronDownIcon } from 'assets/icons/ic-chevron-down.svg';
import classNames from 'components/styled/util';

import { useCurrentChannel } from 'hooks/use-current-channel';
import useDateFormatter from 'hooks/useDateFormatter';

const FileNotice = ({ message }: { message: string }) => {
  const parsedMessage = JSON.parse(message)[0] as UploadToS3Response;

  const onDownload = useCallback(() => {
    downloadURI(parsedMessage.cloudFrontUrl, parsedMessage.orgFileName);
  }, [parsedMessage]);

  return <button onClick={onDownload}>{parsedMessage.orgFileName}</button>;
};

const ChatRoomInfoNoticeList = () => {
  const { dateFormatLong } = useDateFormatter();

  const { data: channel, url } = useCurrentChannel();

  const [isExpanded, setIsExpanded] = useState(false);
  const { state, contents } = useRecoilValueLoadable(noticesSelector(url));
  const [messages, setMessages] = useState<UserMessage[]>([]);
  const { t } = useTranslation();

  useEffect(() => {
    if (state === 'hasError') {
      return;
    }

    if (state === 'loading') {
      return;
    }

    setMessages(contents);
  }, [state, contents]);

  useEffect(() => {
    setIsExpanded(false);
  }, [url]);

  return (
    <>
      <div className="notice tw-flex tw-flex-col tw-gap-2">
        <div className="tw-flex tw-items-center tw-gap-1.5">
          <span className="tw-text-13 tw-font-medium tw-text-gray-600">
            {t('chat.channelInfo.notice.label')} - {messages.length}
          </span>
          <button
            type="button"
            className="tw-size-6 tw-p-1"
            onClick={() => {
              setIsExpanded(!isExpanded);
            }}
          >
            <ChevronDownIcon
              className={classNames('tw-size-4 tw-text-gray-600 tw-transition-all hover:tw-text-black-title', {
                'tw-rotate-180': isExpanded
              })}
            />
          </button>
        </div>

        <div className="tw-flex tw-flex-col tw-gap-2">
          {/* 채팅방 공지 펼쳤을 때, 시스템공지가 생겼을 때 최상단에 노출 */}
          {/* 시스템공지에는 system class */}
          {(!isExpanded ? messages.slice(0, 1) : messages).map((message) => {
            const isMaster = getIsMaster({ channel, user: message.sender });
            const isStaff = getIsStaff({ channel, user: message.sender });
            const isSystem = message.customType === CustomMessageType.SYSTEM;

            return (
              <div
                className={classNames('tw-rounded-3 tw-bg-gray-100 tw-px-4 tw-py-3.5', { system: isSystem })}
                key={message.messageId}
              >
                <div className="tw-flex tw-items-center tw-justify-between">
                  {/* 방장이 쓴 공지일 경우 master class */}
                  <div
                    className={classNames('tw-inline-flex tw-items-center', {
                      'tw-text-primary-100': isMaster,
                      'tw-text-blue-like': isStaff
                    })}
                  >
                    {isSystem !== true && (
                      <>
                        <img className="tw-size-7 tw-shrink-0 tw-rounded-full" src={message.sender.profileUrl} />
                        <span className="text-13 tw-ml-2">{message.sender.nickname}</span>
                      </>
                    )}
                    {isSystem === true && (
                      <>
                        <div className="tw-size-7 tw-rounded-full tw-bg-[#242a3c]">
                          <img
                            className="tw-inline-block tw-size-5 tw-bg-[length:20px_20px] tw-bg-center tw-bg-no-repeat"
                            src={`${process.env.PUBLIC_URL}/img/ico/ic_info_sm_wh.png`}
                          />
                        </div>
                        <span className="text-13 tw-ml-2">Notification</span>
                      </>
                    )}
                  </div>
                  <div className="tw-inline-flex tw-items-center">
                    <div className="tw-text-12 tw-text-gray-500">{dateFormatLong(message.createdAt)}</div>
                  </div>
                </div>
                <div className="tw-mt-2 tw-break-all tw-text-14 tw-text-black-body">
                  {message.customType === CustomMessageType.TEXT && (
                    <p className="tw-text-14 tw-text-black-body">{message.message}</p>
                  )}
                  {message.customType === CustomMessageType.GIF && 'GIF'}
                  {message.customType === CustomMessageType.URL && 'URL'}
                  {message.customType === CustomMessageType.VIDEO && (
                    <Link to={`video/${message.messageId}`}>VIDEO</Link>
                  )}
                  {message.customType === CustomMessageType.IMAGE && (
                    <Link to={`gallery/${message.messageId}`}>IMAGE</Link>
                  )}
                  {message.customType === CustomMessageType.FILE && <FileNotice message={message.message} />}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default ChatRoomInfoNoticeList;
