import React, { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { UploadToS3Response } from '../../../../libs/s3';
import { ChatMessage } from '../../../../types/common';

import classNames from 'components/styled/util';

import 'video-react/dist/video-react.css';
type Props = {
  message: ChatMessage;
  parentMessage?: ChatMessage;
};

const ChatImageMessage: React.FC<Props> = ({ message }) => {
  const navigate = useNavigate();

  // 앱 요청으로 변경된 타입
  const parsedMessages = useMemo<UploadToS3Response[]>(() => {
    if (message.data) return JSON.parse(message.data);
    return JSON.parse(message.message);
  }, [message.message]);

  const overQuantity = useMemo(() => {
    try {
      return parsedMessages.slice(5).length;
    } catch (e) {
      return 0;
    }
  }, [parsedMessages]);

  const onGallery = useCallback(() => {
    navigate(`gallery/${message.messageId}`);
  }, [message]);

  const slicedMessage = useMemo(() => {
    try {
      return parsedMessages.slice(0, 5);
    } catch (e) {
      return [];
    }
  }, [parsedMessages]);

  return (
    <>
      <div className="tw-relative tw-w-fit" onClick={onGallery}>
        <ul className="tw-mt-1 tw-flex tw-items-center tw-gap-1">
          {slicedMessage.map((row, index) => {
            return (
              <li
                key={index.toString()}
                className={classNames({
                  over: overQuantity > 0 && index === 4
                })}
                data-over={overQuantity > 0 && index === 4}
              >
                <img className="tw-size-[68px] tw-rounded-2 tw-object-cover" src={row.cloudFrontUrl} />
              </li>
            );
          })}
          {overQuantity > 0 && (
            <div className="tw-absolute tw-right-0 tw-flex tw-size-[68px] tw-items-center tw-justify-center tw-rounded-2 tw-bg-black/50">
              <span className="tw-text-16 tw-text-white">+{overQuantity}</span>
            </div>
          )}
        </ul>
      </div>
    </>
  );
};

export default ChatImageMessage;
