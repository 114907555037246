import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ContentEditableEvent } from 'react-contenteditable';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { User } from '@sendbird/chat';
import { Member } from '@sendbird/chat/groupChannel';
import classNames from 'classnames';
import { useRecoilCallback, useRecoilValue } from 'recoil';

import useAlert from '../../../hooks/useAlert';
import useCurrentUser from '../../../hooks/useCurrentUser';
import useMessageInput from '../../../hooks/useMessageInput';
import { fetchBannedUsers, fetchGroupChannel } from '../../../libs/sendbird';
import { AlertType } from '../../../store/atoms/alertsState';
import mentionedUsersState from '../../../store/atoms/mentionedUsersState';
import { ChatChannel, CustomMessageType } from '../../../types/common';

import ChatMessageEmoticonInput from './ChatMessageEmoticonInput';
import ChatMessageInputMore from './ChatMessageInputMore';
import ChatMessageMentionInput from './ChatMessageMentionInput';
import ChatMessageMentionedMembers from './ChatMessageMentionedMembers';
import ChatMessageReplyInput from './ChatMessageReplyInput';

import { ReactComponent as EmojiIcon } from 'assets/icons/ic-emotion.svg';
import { ReactComponent as SendIcon } from 'assets/icons/ic-send.svg';
import { getUserPermission } from 'libs/ApiRepository';

import { useCheckBadWord } from 'hooks/use-check-bad-word';
import useUserInfo from 'hooks/useUserInfo';

type Props = {
  channel: ChatChannel;
  left: number;
  onSave: () => void;
};

const ChatMessageInput: React.FC<Props> = ({ channel, onSave }) => {
  const navigate = useNavigate();
  const { show, close } = useAlert();
  const mentionedMembers = useRecoilValue(mentionedUsersState);
  const [isHiddenPlaceHolder, setIsHiddenPlaceHolder] = useState(false);
  const { currentUser } = useCurrentUser();
  const { t } = useTranslation();

  const [isInputFocus, setIsInputFocus] = useState(false);

  const refInput = useRef<HTMLInputElement | null>(null);

  const [selectionStart, setSelectionStart] = useState<number | null>(null);
  const [selectionEnd, setSelectionEnd] = useState<number | null>(null);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    openEmojiPicker,
    openMemberSearch,
    closeMemberSearch,
    isOpenedEmojiPopup,
    isOpenedMemberSearchPopup,
    isReplyMode,
    parentMessage,
    sendMessageToChannel,
    messageText,
    setMessageText
  } = useMessageInput();

  const [toUserId, setToUserId] = useState('');

  const { data: dmUserPermission } = useQuery({
    queryKey: ['user', 'permission', toUserId],
    queryFn: () => getUserPermission(toUserId),
    select: (data) => data?.data.data,
    enabled: !!toUserId
  });

  const { data: userData } = useUserInfo(toUserId);

  const { check } = useCheckBadWord();

  const onSend = useRecoilCallback(
    () => async (text: string) => {
      if (!channel || !isEditable) {
        return;
      }

      const trimmed = text.trim();

      if (!trimmed) {
        show({
          type: AlertType.EmptyMessage,
          content: t('chat.alert.emptyMessage.contents'),
          buttons: [
            {
              title: t('common.button.confirm'),
              shortcut: ['Enter', 'Escape'],
              onClick: () => {
                close();
                refInput.current?.focus();
              }
            }
          ]
        });
        refInput.current?.blur();
        return;
      }

      try {
        await check(trimmed); // 비속어 체크
      } catch (error) {
        show({
          content: t('common.chat.prohibited.alert'),
          buttons: [
            {
              title: t('common.button.confirm'),
              shortcut: ['Enter', 'Escape'],
              onClick: () => {
                close();
                refInput.current?.focus();
              }
            }
          ]
        });
        refInput.current?.blur();
        return; // 비속어가 감지되면 이후 로직 실행 안함
      }

      const split = trimmed.split(' ');
      const foundForbiddenWord = split.find((word) =>
        channel.data.forbiddenWords.find((forbiddenWord) => word.indexOf(forbiddenWord) > -1)
      );

      if (foundForbiddenWord) {
        show({
          content: t('chat.messageInput.alert.containForbiddenWord'),
          buttons: [
            {
              title: t('common.button.confirm'),
              onClick: close
            }
          ]
        });
        return;
      }

      try {
        await sendMessageToChannel(channel.url, {
          message: trimmed,
          customType: CustomMessageType.TEXT,

          /**
           * 샌드버드는 2중답글을 지원하지않음
           *
           * @see https://community.sendbird.com/t/feature-reply-a-reply/5418
           */
          parentMessageId: parentMessage?.parentMessageId || parentMessage?.messageId,
          mentionedUserIds: parentMessage?.sender.userId ? [parentMessage?.sender.userId] : undefined
        });
      } catch (error) {
        refInput.current?.blur();
        return;
      }

      setMessageText('');
      onSave();
    },
    [channel, parentMessage, sendMessageToChannel, setMessageText]
  );

  const onKeyDown = useRecoilCallback(
    () => (event: React.KeyboardEvent<HTMLDivElement>) => {
      // console.log("event.key", event.key);
      if (event.nativeEvent.isComposing) {
        // isComposing 이 true 이면
        return; // 조합 중이므로 동작을 막는다.
      }

      if (event.code === 'Backspace') {
        if (messageText.charAt(messageText.length - 1) === '@') {
          closeMemberSearch();
        }
      }

      if (event.code === 'Space') {
        closeMemberSearch();
      }

      if (event.code.indexOf('Arrow') > -1) {
        closeMemberSearch();
      }

      if (event.shiftKey === true) {
        if (event.key === '@') {
          if (isOpenedMemberSearchPopup === false) {
            openMemberSearch();
          }
        }
        return;
      }

      if (event.key !== 'Enter') {
        return;
      }

      // 중복 제출 방지
      if (isSubmitting) return;

      // 이벤트 동작 방지
      event.preventDefault();
      event.stopPropagation();

      // 중복 제출 방지 플래그 설정
      setIsSubmitting(true);

      // 메시지 전송
      onSend(messageText);

      // 일정 시간 후 플래그 해제
      setTimeout(() => {
        setIsSubmitting(false);
      }, 500);
    },
    [messageText, mentionedMembers, selectionStart, selectionEnd, isSubmitting]
  );

  const onSendMessage = useCallback(() => {
    onSend(messageText);
  }, [messageText, onSend]);

  const onChangeText = useRecoilCallback(
    ({ set }) =>
      (event: ContentEditableEvent) => {
        const text = event.target.value.replace(/<[^>]*>?/gm, '');

        const inputSelectionStart = refInput.current?.selectionStart || 0;
        const inputSelectionEnd = refInput.current?.selectionEnd || 0;

        if (text.length < messageText.length) {
          const lastIndex = text.substring(0, inputSelectionStart || 0).lastIndexOf(' ');

          const startPos = lastIndex === -1 ? 0 : lastIndex + 1;
          const endPos = inputSelectionStart || 0;

          const lastWord = text.substring(startPos, endPos);

          if (lastWord.charAt(0) === '@') {
            const previewText = `${text.substring(
              0,
              (inputSelectionStart || 0) - 1
            )}${text.substring(inputSelectionEnd || 0)}`;

            const split = previewText.split(' ');
            const filtered = split.filter((text) => text.charAt(0) === '@');
            const remainMembers = mentionedMembers.filter(
              (prevMember) => filtered.indexOf(`@${prevMember.nickname}`) > -1
            );

            set(mentionedUsersState, remainMembers);
          }
        }

        setMessageText(text.slice(0, 2000));
        setSelectionStart(inputSelectionStart);
        setSelectionEnd(inputSelectionEnd);
      },
    [messageText, setMessageText, mentionedMembers]
  );

  const onFocus = useCallback(() => {
    setIsInputFocus(true);
    setIsHiddenPlaceHolder(true);
    setSelectionStart(refInput.current?.selectionStart || null);
    setSelectionEnd(refInput.current?.selectionEnd || null);
    closeMemberSearch();
  }, []);

  const onBlur = useCallback(() => {
    setIsInputFocus(false);
    setIsHiddenPlaceHolder(false);
  }, []);

  const onInvite = useCallback(
    (user: User) => async () => {
      if (!channel) {
        return;
      }

      const groupChannel = await fetchGroupChannel(channel.url);

      if (!groupChannel) {
        return;
      }

      const bannedUsers = await fetchBannedUsers(groupChannel);

      const isBanned = bannedUsers.find((bannedUser) => bannedUser.userId === user.userId);

      if (isBanned) {
        show({
          content: t('chat.alert.userBanned.contents'),
          buttons: [
            {
              title: t('common.button.confirm'),
              async onClick() {
                close();
              }
            }
          ]
        });
        return;
      }

      navigate(`invite/${user.userId}`);
    },
    [channel]
  );

  const onMention = useRecoilCallback(
    ({ snapshot, set }) =>
      (newMember: Member) =>
      async () => {
        const prevMembers = snapshot.getLoadable(mentionedUsersState).getValue();

        if (!prevMembers.find((prevMember) => prevMember.userId === newMember.userId)) {
          set(mentionedUsersState, [...prevMembers, newMember]);

          const prevChars = messageText.substring(0, messageText.substring(0, selectionStart || 0).lastIndexOf(' '));
          const newChars = ` @${newMember.nickname}`;
          const nextChars = messageText.substring(selectionEnd || 0);

          const newText = `${prevChars}${newChars}${nextChars}`;
          setMessageText(newText);
          closeMemberSearch();
        }
      },
    [channel, messageText, selectionStart, selectionEnd]
  );

  const isSendable = useMemo(() => {
    if (!messageText) {
      return false;
    }

    if (messageText.length > 2000) {
      return false;
    }

    if (channel.data.sendMessageDisabledUserIds.indexOf(currentUser?.userId || '') > -1) {
      return false;
    }

    return true;
  }, [channel, messageText]);

  const isEditable = useMemo(() => {
    const found = channel.members.find((member) => member.userId === currentUser?.userId);

    if (!found) {
      return false;
    }

    if (channel.data.sendMessageDisabledUserIds.indexOf(currentUser?.userId || '') > -1) {
      return false;
    }

    if (userData?.is_banned) {
      return false;
    }

    if (channel.customType === 'DM' && !!dmUserPermission) {
      if (!userData) return true;

      const permission = dmUserPermission.direct_message_permission;

      switch (permission) {
        case 'PUBLIC':
          return true;
        case 'FRIEND':
          return !!userData.is_friend;

        case 'NO_ONE':
          return false;

        default:
          return true;
      }
    }

    return true;
  }, [channel, messageText, dmUserPermission, userData]);

  const placeholder = useMemo(() => {
    if (isHiddenPlaceHolder) {
      return '';
    }

    if (channel.customType === 'DM' && isEditable === false) {
      switch (dmUserPermission.direct_message_permission) {
        case 'FRIEND':
          return t('chat.messageInput.placeholder.friends');
        case 'NO_ONE':
          return t('chat.messageInput.placeholder.noOne');
      }
    }

    if (isReplyMode) {
      return t('chat.messageInput.placeholder.replay', {
        username: parentMessage?.sender.nickname
      });
    }

    if (userData?.is_banned) {
      return t('chat.messageInput.placeholder.disabled');
    }

    if (channel.data.sendMessageDisabledUserIds.indexOf(currentUser?.userId || '') > -1) {
      return t('chat.messageInput.placeholder.disabled');
    }

    return t('chat.messageInput.placeholder.default');
  }, [channel, isHiddenPlaceHolder, isReplyMode, parentMessage, t, isEditable, userData]);

  useEffect(() => {
    if (!channel || channel.customType !== 'DM') return;

    const toUserId = channel.members.find((member) => member.userId !== currentUser?.userId)?.userId;

    if (toUserId) {
      setToUserId(toUserId);
    }
  }, [channel]);

  return (
    // global_chat_form
    <div className="tw-bg-gray-100 tw-py-4">
      <div
        // global_chat_input
        className={classNames(
          'tw-relative tw-mx-auto tw-flex tw-min-h-12 tw-w-[calc(100%-8px)] tw-items-center tw-rounded-6 tw-border tw-bg-white tw-pl-1.5 tw-pr-2',
          {
            'tw-border-primary-100': isInputFocus,
            'tw-border-gray-300': !isInputFocus
          }
        )}
      >
        {isOpenedEmojiPopup === true && <ChatMessageEmoticonInput />}
        {/* 답글하기를 눌렀을 경우 답글하기 아이콘, 유저이름, 텍스트내용과 같이 뜨며 텍스트 내용은 한줄로만 노출됩니다. */}
        {isReplyMode === true && !!parentMessage && <ChatMessageReplyInput parentMessage={parentMessage} />}
        {mentionedMembers.length > 0 && <ChatMessageMentionedMembers members={mentionedMembers} />}
        {/* input란에 @로 닉네임을 지정할 시 나오는 팝업입니다. 현재 채팅창에 참여한 멤버 리스트가 나옵니다. */}
        {isOpenedMemberSearchPopup === true && (
          <ChatMessageMentionInput
            channel={channel}
            keyword={messageText}
            selectionStart={selectionStart}
            selectionEnd={selectionEnd}
            onInvite={onInvite}
            onMention={onMention}
          />
        )}

        <ChatMessageInputMore isEditable={isEditable} />

        {/* 이모티콘 아이콘에 hover시 검은색 아이콘이 되며, 클릭 시 active class를 추가하여 이모티콘 팝업이 보여지는 동안은 보라색 아이콘으로 보여져야 함 */}
        <button
          type="button"
          className="tw-absolute tw-bottom-2 tw-right-[50px] tw-size-8"
          disabled={!isEditable}
          onClick={() => {
            openEmojiPicker();
            closeMemberSearch();
          }}
        >
          <EmojiIcon
            className={classNames('tw-size-8 tw-text-gray-500', {
              'tw-text-primary-100': isOpenedEmojiPopup,
              'hover:tw-text-gray-900': !isOpenedEmojiPopup
            })}
          />
          <span className="a11y">이모티콘</span>
        </button>
        <div className="tw-relative tw-ml-[38px] tw-mr-10 tw-w-full tw-py-3">
          <input
            ref={refInput}
            className="tw-w-full tw-text-16 tw-leading-[20px] tw-text-black-body"
            placeholder={placeholder}
            value={messageText} // innerHTML of the editable div
            disabled={!isEditable} // use true to disable editing
            onChange={onChangeText} // handle innerHTML change
            onKeyDown={(event) => {
              onKeyDown(event);
            }}
            maxLength={2000}
            onFocus={onFocus}
            onBlur={onBlur}
            style={{
              fontFamily: 'Arial, sans-serif'
            }}
          />
        </div>

        <button
          type="button"
          className={classNames(
            'tw-flex tw-size-9 tw-shrink-0 tw-items-center tw-justify-center tw-rounded-full tw-bg-primary-100 disabled:tw-bg-gray-400',
            {}
          )}
          onClick={onSendMessage}
          disabled={!isSendable}
        >
          <SendIcon className="tw-size-6 tw-text-white" />
          <span className="tw-sr-only">입력</span>
        </button>
      </div>
    </div>
  );
};

export default ChatMessageInput;
