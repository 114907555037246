import React, { SVGProps, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { MemberState } from '@sendbird/chat/groupChannel';
import { useRecoilCallback } from 'recoil';

import ChannelInfoMemberList from './ChannelInfoMemberList';
import ChannelInfoMemberSearchList from './ChannelInfoMemberSearchList';
import ChatRoomInfoNoticeList from './ChatRoomInfoNoticeList';
import ChatRoomSharedFileList from './ChatRoomSharedFileList';

import { ReactComponent as ChevronDownIcon } from 'assets/icons/ic-chevron-down.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/ic-close.svg';
import { ReactComponent as SearchIcon } from 'assets/icons/ic-search.svg';
import { ReactComponent as BookmarkIcon } from 'assets/icons/post-bookmark.svg';
import Input from 'components/jayden/Input';
import classNames from 'components/styled/util';
import chatRoomExpandedState from 'store/atoms/chatRoomExpandedState';

import { useCurrentChannel } from 'hooks/use-current-channel';
import useUserMe from 'hooks/use-user-me';
import { useGetWindowHeight } from 'hooks/useGetWindowHeight';
import useGroupChannel from 'hooks/useGroupChannel';
import useMessageInput from 'hooks/useMessageInput';
import useTextInputChange from 'hooks/useTextInputChange';

interface BookmarkButtonProps extends SVGProps<SVGSVGElement> {
  isBookmarked: boolean;
}

const BookmarkButton: React.FC<BookmarkButtonProps> = (props) => {
  const { isBookmarked, className = '', ...nativeProps } = props;

  return (
    <BookmarkIcon
      className={classNames(
        'tw-size-6 tw-cursor-pointer [&>*]:tw-transition-all [&>.bookmark]:tw-stroke-[2.5px]',
        {
          '[&>.bookmark]:tw-fill-primary-100 [&>.bookmark]:tw-stroke-primary-200 [&>.star]:tw-opacity-100': isBookmarked
        },
        {
          '[&>.bookmark]:tw-stroke-black-title [&>.star]:tw-opacity-0 [&>.bookmark]:tw-fill-transparent': !isBookmarked
        },
        className
      )}
      {...nativeProps}
    />
  );
};

const ChannelInfo = () => {
  const { channelUrl } = useParams();
  const { t } = useTranslation();
  const user = useUserMe();

  const { data: newChannel } = useCurrentChannel();
  const channel = newChannel;

  // const { show, close } = useAlert();
  const { addBookmark, removeBookmark } = useGroupChannel();

  const { closeMemberSearch } = useMessageInput();
  const [isExpandedIntro, setIsExpandedIntro] = useState(true);
  const [isExpandedMemberList, setIsExpandedMemberList] = useState(false);

  const [keyword, onChangeKeyword, , clearKeyword] = useTextInputChange('', 30);
  const onCloseMemberSearch = () => clearKeyword();

  const memberList = useMemo(() => {
    if (!newChannel) return [];

    // 마스터가 맨 위로 오도록 정렬
    const sortedMembers = newChannel.members.sort((a, b) => {
      if (newChannel.masterIds.includes(a.userId)) return -1;
      if (newChannel.masterIds.includes(b.userId)) return 1;
      return 0;
    });

    if (!isExpandedMemberList) {
      return sortedMembers.slice(0, 4);
    }

    return sortedMembers;
  }, [newChannel, isExpandedMemberList]);

  const searchedMemberList = useMemo(() => {
    if (!keyword || !newChannel) {
      return [];
    }

    return newChannel.members.filter(
      (member) => member.nickname.indexOf(keyword) > -1 && member.state === MemberState.JOINED
    );
  }, [keyword, newChannel]);

  const isBookMarked = useMemo(() => {
    if (!newChannel || !user) return false;

    return newChannel.bookmarkUserIds.indexOf(String(user.id)) > -1;
  }, [newChannel?.bookmarkUserIds, user]);

  const onToggleBookMark = useCallback(async () => {
    if (!channelUrl) return;
    if (!channel || !user) return;

    if (isBookMarked) {
      await removeBookmark(channelUrl, [String(user.id)]);
    } else {
      await addBookmark(channelUrl, [String(user.id)]);
    }
  }, [channel, user, isBookMarked]);

  const onFocusKeyword = useCallback(async () => {
    closeMemberSearch();
  }, []);

  const onExpand = useRecoilCallback(
    ({ set }) =>
      () => {
        set(chatRoomExpandedState, true);
      },
    []
  );

  useEffect(() => {
    clearKeyword();
    setIsExpandedMemberList(false);
  }, [channelUrl]);

  const { height } = useGetWindowHeight();

  if (!channelUrl || !newChannel || !channel) return null;

  return (
    <div className="tw-h-full tw-w-[300px] tw-px-5 tw-pt-5">
      <div className="tw-flex tw-items-center tw-justify-between tw-pb-5">
        <div className="tw-flex tw-items-center tw-gap-1 tw-truncate">
          <button type="button" onClick={onToggleBookMark}>
            <BookmarkButton isBookmarked={isBookMarked} />
          </button>
          <div className="text-18 tw-truncate tw-font-semibold tw-text-black-title">{newChannel.name}</div>
        </div>
        <button type="button" className="tw-size-6" onClick={onExpand}>
          <CloseIcon className="tw-size-6" />
        </button>
      </div>
      <div
        className="-tw-mr-5 tw-flex tw-h-full tw-flex-col tw-gap-4 !tw-overflow-y-scroll tw-pr-5"
        style={{
          maxHeight: height - 44 - 40
        }}
      >
        {/* S : Introduction */}
        <div className="tw-flex tw-flex-col tw-gap-2">
          <div className="tw-flex tw-items-center tw-gap-1.5">
            <span className="tw-text-13 tw-font-medium tw-text-gray-600">
              {t('chat.channelInfo.introduction.label')}
            </span>
            <button
              type="button"
              className="tw-size-6 tw-p-1"
              onClick={() => {
                setIsExpandedIntro(!isExpandedIntro);
              }}
            >
              <ChevronDownIcon
                className={classNames('tw-size-4 tw-text-gray-600 tw-transition-all hover:tw-text-black-title', {
                  'tw-rotate-180': isExpandedIntro
                })}
              />
            </button>
          </div>

          {/* 소개글 수정은 방장이 채팅방 설정에서 가능 */}
          <div
            className={classNames('tw-whitespace-pre-wrap tw-break-all tw-text-14 tw-font-medium tw-text-black-body', {
              'tw-line-clamp-2 tw-h-full': !isExpandedIntro,
              '!tw-h-0': !isExpandedIntro
            })}
          >
            {newChannel.introduction}
          </div>
        </div>

        <div className="tw-h-[1px] tw-w-full tw-bg-gray-300" />

        {/* E : Introduction */}

        <ChatRoomInfoNoticeList />

        {/* S : Member */}
        <div className="">
          <div className="tw-flex tw-items-center tw-gap-1.5">
            <span className="tw-text-13 tw-font-medium tw-text-gray-600">
              {t('chat.channelInfo.members.label')} - {newChannel.memberCount}
            </span>
            <button
              type="button"
              className="tw-size-6 tw-p-1"
              onClick={() => {
                setIsExpandedMemberList(!isExpandedMemberList);
              }}
            >
              <ChevronDownIcon
                className={classNames('tw-size-4 tw-text-gray-600 tw-transition-all hover:tw-text-black-title', {
                  'tw-rotate-180': isExpandedMemberList
                })}
              />
            </button>
          </div>
          <div className="tw-mb-4 tw-mt-3">
            {/* 멤버리스트 펼쳤을 때 나오는 검색영역입니다. (접혔을때는 검색없이 멤버 4명만 나옵니다.) */}
            {/* 채널 첫 화면에서는 검색창 없이 master만 표시됩니다. 이후 Join하는 멤버들이 하단에 리스트로 쌓이면서 검색영역이 활성화 됩니다. */}
            {isExpandedMemberList && (
              <div className="tw-mb-4">
                <div className="tw-w-[260px]">
                  <div className={classNames('tw-relative', {})}>
                    <Input
                      type="text"
                      className="tw-peer"
                      placeholder={t('chat.channelInfo.members.search.placeholder')}
                      onChange={onChangeKeyword}
                      onFocus={onFocusKeyword}
                      value={keyword}
                      suffix={
                        <SearchIcon className="tw-absolute tw-right-3 tw-top-1/2 tw-inline-block tw-size-6 -tw-translate-y-1/2 tw-cursor-pointer tw-text-gray-500 tw-transition-all peer-focus:tw-text-black-body" />
                      }
                    />
                    <ChannelInfoMemberSearchList
                      searchedMemberList={searchedMemberList}
                      keyword={keyword}
                      onClose={onCloseMemberSearch}
                    />
                  </div>
                </div>
              </div>
            )}
            <ChannelInfoMemberList memberList={memberList} />
          </div>
        </div>
        {/* E : Member */}

        <ChatRoomSharedFileList />
      </div>
    </div>
  );
};

export default ChannelInfo;
