import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useIdleTimer } from 'react-idle-timer';
import { Outlet, useNavigate, useParams } from 'react-router-dom';

import { useRecoilState, useRecoilValue } from 'recoil';

import Alert from '../../components/alert/Alert';
import LeftMenu from '../../components/layout/LeftMenu';
import useAlert from '../../hooks/useAlert';
import useCurrentUser, { UserStatus } from '../../hooks/useCurrentUser';
import { getDMMember } from '../../libs/helper';
import chatRoomExpandedState from '../../store/atoms/chatRoomExpandedState';
import { groupChannelStateByUrl } from '../../store/atoms/groupChannelsState';
import navigatePathState from '../../store/atoms/navigatePathState';

import ChannelInfo from './components/ChannelInfo';
import ChannelList from './components/ChannelList';
import ChatRoom from './components/ChatRoom';
import DMChannelInfo from './components/DMChannelInfo';

import Loader from 'components/jayden/Loader';

import useGroupChannel from 'hooks/useGroupChannel';

type Params = {
  channelUrl: string;
};

const ChatMain = () => {
  const { channelUrl } = useParams<Params>();

  const navigate = useNavigate();

  const { t } = useTranslation();
  const [navigatePath, setNavigatePath] = useRecoilState(navigatePathState);

  // 외부 윈도우에서 navigatePath를 지정했다면 해당 경로로 이동합니다.
  useEffect(() => {
    if (navigatePath) {
      navigate(navigatePath);
      setNavigatePath('');
    }
  }, [navigatePath]);

  const channel = useRecoilValue(groupChannelStateByUrl(channelUrl ?? ''));

  const chatRoomExpanded = useRecoilValue(chatRoomExpandedState);

  const { channels, setCurrentChannel } = useGroupChannel();

  const { currentUser, updateUserState } = useCurrentUser();
  const { alerts } = useAlert();

  // 유저 접속 상태 관리
  useIdleTimer({
    crossTab: true,
    leaderElection: true,
    timeout: 1000 * 60 * 5, // 5분
    onIdle() {
      updateUserState(UserStatus.AWAY);
    },
    onActive() {
      updateUserState(UserStatus.ONLINE);
    }
  });

  /**
   * url에 채널이 없거나, 채널 나가기 후 /channel로 돌아왔을때
   * 가입된 채널을 확인하고 들어가기
   */
  useEffect(() => {
    if (!channelUrl) {
      const filtered = channels?.find((v) => v.joinedAt > 0);
      if (filtered) {
        setCurrentChannel(filtered.url);
        navigate(`/channel/${filtered.url}`);
      }
    }
  }, [channelUrl]);

  const toUserId = useMemo(() => {
    if (!channel) {
      return null;
    }

    return getDMMember(channel, currentUser?.userId);
  }, [channel, currentUser]);

  if (!currentUser) {
    return (
      <div className="tw-absolute tw-left-1/2 tw-top-1/2 -tw-translate-x-1/2 -tw-translate-y-1/2">
        <Loader />
      </div>
    );
  }

  return (
    <>
      <LeftMenu />
      <ChannelList />
      {channel ? (
        <>
          <ChatRoom channel={channel} />

          {chatRoomExpanded === false && (
            <>
              {toUserId !== null && <DMChannelInfo channelUrl={channel.url} toUserId={toUserId} />}
              {toUserId === null && <ChannelInfo />}
            </>
          )}
        </>
      ) : (
        <div className="tw-col-span-2 tw-row-span-1 tw-flex tw-flex-col tw-items-center tw-justify-center tw-bg-gray-100">
          <img src="/img/com/img_unsmile3_gr.png" alt="no result" className="tw-mb-4 tw-size-[100px]" />
          <h1 className="tw-mb-1.5 tw-text-18 tw-font-semibold tw-tracking-[-0.36px] tw-text-black-title">
            {t('chat.emptyChannel.title')}
          </h1>
          <p className="tw-text-13 tw-text-gray-700">{t('chat.emptyChannel.description')}</p>
        </div>
      )}
      <Outlet />
      {alerts.map((alert, alertIndex) => (
        <Alert key={alertIndex} {...alert} />
      ))}
    </>
  );
};

export default ChatMain;
