import React, { PropsWithChildren } from 'react';

import classNames from 'components/styled/util';

interface TooltipProps extends PropsWithChildren {
  position: 'right' | 'bottom';
  className?: string;
  textClassName?: string;
}

const Tooltip = (props: TooltipProps) => {
  const { children, position = 'right', className, textClassName } = props;

  return (
    <div
      className={classNames(
        'tw-invisible tw-absolute tw-z-10 tw-w-auto tw-rounded tw-bg-black-body tw-px-2 tw-py-1 tw-text-center tw-opacity-80 tw-shadow-md group-hover:tw-visible',
        {
          'tw-left-[calc(100%+5px)] tw-top-1/2 -tw-translate-y-1/2': position === 'right',
          'tw-bottom-[-5px] tw-left-1/2 -tw-translate-x-1/2 tw-translate-y-full': position === 'bottom'
        },
        className
      )}
    >
      <span className={classNames('tw-text-13 tw-text-white', textClassName)}>{children}</span>
    </div>
  );
};

export default Tooltip;
