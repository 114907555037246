import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { UserMessage } from '@sendbird/chat/message';
import { useRecoilCallback } from 'recoil';

import useMessageInput from '../../../hooks/useMessageInput';
import { UploadToS3Response } from '../../../libs/s3';
import { fetchMessages } from '../../../libs/sendbird';
import groupChannelState from '../../../store/atoms/groupChannelState';
import { CustomMessageType } from '../../../types/common';

import Button from 'components/jayden/Button';
import classNames from 'components/styled/util';

const ChatMessageAttachmentGallerySearch = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { sendMessageToChannel } = useMessageInput();
  const [images, setImages] = useState<UploadToS3Response[]>([]);

  const search = useRecoilCallback(
    ({ snapshot }) =>
      async () => {
        const channel = snapshot.getLoadable(groupChannelState).getValue();

        if (!channel) {
          return;
        }

        const messages = (await fetchMessages(channel.url, {
          customTypesFilter: [CustomMessageType.IMAGE],
          limit: 8
        })) as UserMessage[];

        setImages(
          messages.flatMap<UploadToS3Response>((message) => {
            try {
              if (message.data) {
                return JSON.parse(message.data);
              } else {
                return JSON.parse(message.message);
              }
            } catch {
              return [];
            }
          })
        );
      },
    []
  );

  const [selected, setSelected] = useState<UploadToS3Response[]>([]);

  const onSelectImage = (image: UploadToS3Response) => () => {
    if (selected.includes(image)) {
      setSelected(selected.filter((item) => item !== image));
      return;
    }

    setSelected((prev) => [...prev, image]);
  };

  // const onSelectImage = useRecoilCallback(
  //   ({ snapshot }) =>
  //     (image: UploadToS3Response) =>
  //     async () => {
  //       const channel = snapshot.getLoadable(groupChannelState).getValue();

  //       if (!channel) {
  //         return;
  //       }

  //       await sendMessageToChannel(channel.url, {
  //         message: JSON.stringify([image]),
  //         customType: CustomMessageType.IMAGE
  //       });
  //       navigate(-1);
  //     },
  //   [navigate]
  // );

  const onConfirm = useRecoilCallback(({ snapshot }) => async () => {
    const channel = snapshot.getLoadable(groupChannelState).getValue();

    if (!channel) {
      return;
    }

    await sendMessageToChannel(channel.url, {
      message: 'upload image',
      data: JSON.stringify(selected),
      customType: CustomMessageType.IMAGE
    });
    navigate(-1);
  });

  useEffect(() => {
    search();
  }, [search]);

  return (
    <div className="tw-h-full tw-pt-5">
      <div className="tw-mr-[-5px] tw-h-full tw-max-h-[266px] tw-overflow-x-hidden tw-overflow-y-scroll">
        <ul className="tw-grid tw-grid-cols-2 tw-gap-1">
          {images.map((image, index) => {
            return (
              <li key={index} onClick={onSelectImage(image)} className="tw-h-[128px] tw-w-[134px]">
                <img
                  className={classNames('tw-h-[128px] tw-w-[134px] tw-shrink-0 tw-object-cover', {
                    'tw-border-2 tw-border-primary-100': selected.includes(image)
                  })}
                  src={image.cloudFrontUrl}
                />
              </li>
            );
          })}
        </ul>
      </div>

      <div className="tw-py-5">
        <Button
          color="primary"
          size="lg"
          className="tw-w-full tw-text-center"
          onClick={onConfirm}
          disabled={selected.length === 0}
        >
          {t('common.button.confirm')}
        </Button>
      </div>
    </div>
  );
};

export default ChatMessageAttachmentGallerySearch;
