import { chatAxios } from './axios.config';

const chatApi = {
  /** ------------------------------------------------------------------------------
 * 
 * GlobalBadWord
 * 
 ------------------------------------------------------------------------------ */
  postGlobalBadWordCheck: async (payload: { text: string }) => {
    const { data } = await chatAxios.post('/global-bad-word/check', payload);
    return data;
  }
};

export default chatApi;
