import React from 'react';

import { Member } from '@sendbird/chat/groupChannel';

type Props = {
  members: Member[];
};
const ChatMessageMentionedMembers: React.FC<Props> = ({ members }) => {
  return (
    <div className="reply_preview">
      <div>
        {members.map((member) => (
          <span key={member.userId}>{member.nickname}</span>
        ))}
      </div>
    </div>
  );
};

export default ChatMessageMentionedMembers;
