import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import classNames from 'classnames';

import { CustomMessageType } from '../../../types/common';

import ProfileAvatar from 'components/profileAvatar/ProfileAvatar';
import { SuperClubUserInterface } from 'libs/ApiRepository';

import useUserInfo from 'hooks/useUserInfo';

type Props = {
  userId?: string;
  customType: CustomMessageType;
  isMine?: boolean;
};

const ChatMessageProfileImage: React.FC<Props> = ({ userId, isMine = false }) => {
  const navigate = useNavigate();

  const { data: userInfo } = useUserInfo(userId);
  const onProfile = useCallback(() => {
    if (document.location.href.indexOf('miniprofile') > -1) {
      navigate(-1);
      return;
    }
    if (!userId) {
      return;
    }

    navigate(`miniprofile/${userId}`, {
      replace: false
    });
  }, [navigate]);

  return (
    <div
      className={classNames('tw-absolute tw-top-0', {
        'tw-left-0': !isMine,
        'tw-right-0': isMine
      })}
      onClick={onProfile}
    >
      <ProfileAvatar userInfo={userInfo as SuperClubUserInterface} trustRing size={40} />
    </div>
  );
};
export default ChatMessageProfileImage;
