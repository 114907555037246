import { User } from '@sendbird/chat';
import { GroupChannel } from '@sendbird/chat/groupChannel';
import { UserMessage } from '@sendbird/chat/message';

import { ChannelCustomData, ChatChannel, ChatMessage, CustomChannelType, CustomMessageType } from '../types/common';

export const IMAGE_TYPES = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif', 'image/bmp', 'image/webp'];

export function getIsImage(contentType: string) {
  return IMAGE_TYPES.indexOf(contentType) > -1;
}

export function downloadURI(uri: string, name: string) {
  fetch(uri)
    .then((res) => res.blob())
    .then((file) => {
      const tempUrl = URL.createObjectURL(file);

      const link = document.createElement('a');
      link.download = name;
      link.href = tempUrl;

      document.body.appendChild(link);
      link.click();
      link.setAttribute('download', name);
      URL.revokeObjectURL(tempUrl);

      document.body.removeChild(link);
    });
  // delete link;
}
//TODO DM 채널
export function getIsDMChannel() {
  console.log('DM', location.pathname);
  return location.pathname.indexOf('/dm/') > -1;
}

export function getDMMember(channel?: ChatChannel, userId?: string) {
  if (!channel) {
    return null;
  }

  if (channel.customType !== CustomChannelType.DM) {
    return null;
  }

  if (channel.members.length === 2) {
    return channel.members.find((member) => member.userId !== userId)?.userId || null;
  }

  return null;
}

export function getChannelName(channel: ChatChannel) {
  if (channel.customType === CustomChannelType.DM) {
    return channel.members.map((member) => member.nickname).join(', ');
  }

  return channel.name;
}

export function sleep(seconds: number) {
  return new Promise((resolve) => {
    setTimeout(resolve, seconds);
  });
}

export function getChannelCustomData(groupChannelData?: string): ChannelCustomData {
  const data = JSON.parse(groupChannelData || '{}') as ChannelCustomData;

  const {
    introduction = '',
    masterIds = [],
    participantsLimit = 300,
    staffIds = [],
    noticeMessageIds = [],
    transferingStaffIds = [],
    forbiddenWords = [],
    bookmarkUserIds = [],
    sendMessageDisabledUserIds = [],
    viewChannelDisabledUserIds = [],
    viewChannelHistories = [],
    clubId,
    onlyClubMembers
  } = data;

  return {
    introduction,
    masterIds,
    participantsLimit,
    staffIds,
    noticeMessageIds,
    transferingStaffIds,
    forbiddenWords,
    bookmarkUserIds,
    sendMessageDisabledUserIds,
    viewChannelDisabledUserIds,
    viewChannelHistories,
    clubId,
    onlyClubMembers
  };
}

export function getChatChannel(channel: GroupChannel): ChatChannel {
  const customData = getChannelCustomData(channel.data);

  return {
    customType: channel.customType as CustomChannelType,
    isPublic: channel.isPublic,
    joinedAt: channel.joinedAt,
    name: channel.name,
    isDistinct: channel.isDistinct,
    unreadMessageCount: channel.unreadMessageCount,
    unreadMentionCount: channel.unreadMentionCount,
    url: channel.url,
    coverUrl: channel.coverUrl,
    members: [...channel.members],
    lastMessage: channel.lastMessage as UserMessage,
    channelType: channel.channelType,
    creator: channel.creator,
    isDiscoverable: channel.isDiscoverable,
    createdAt: channel.createdAt,
    data: customData,
    myMemberState: channel.myMemberState,
    memberCount: channel.memberCount,
    myCountPreference: channel.myCountPreference,
    myLastRead: channel.myLastRead,
    myMutedState: channel.myMutedState,
    myPushTriggerOption: channel.myPushTriggerOption,
    myRole: channel.myRole,
    inviter: channel.inviter,
    invitedAt: channel.invitedAt
  };
}

export function getIsMaster({ channel, user }: { channel: any | null; user: User | null }) {
  if (!channel) {
    return false;
  }

  if (!user) {
    return false;
  }

  const isMaster = channel?.data?.masterIds?.indexOf?.(user.userId) > -1;
  return isMaster;
}

export function getIsStaff({ channel, user }: { channel: any | null; user: User | null }) {
  if (!channel) {
    return false;
  }

  if (!user) {
    return false;
  }

  const isStaff = channel?.data?.staffIds?.indexOf?.(user.userId) > -1;
  return isStaff;
}

export function getIsMessageOwner({ message, user }: { message: ChatMessage | null; user: User | null }) {
  if (!message) {
    return false;
  }

  if (!user) {
    return false;
  }

  const isOwner = message.sender?.userId === user.userId;
  return isOwner;
}

export function getChatMessage(message: UserMessage): ChatMessage {
  const reactionUserIds = message.reactions?.flatMap((reaction) => reaction.userIds) || [];

  return {
    channelUrl: message.channelUrl,
    channelType: message.channelType,
    messageId: message.messageId,
    message: message.message,
    parentMessageId: message.parentMessageId,
    parentMessage: message.parentMessage ? getChatMessage(message.parentMessage as UserMessage) : undefined,
    silent: message.silent,
    isOperatorMessage: message.isOperatorMessage,
    messageType: message.messageType,
    data: message.data,
    customType: message.customType as CustomMessageType,
    mentionType: message.mentionType,
    mentionedUsers: message.mentionedUsers,
    mentionedUserIds: message.mentionedUserIds,
    mentionedMessageTemplate: message.mentionedMessageTemplate,
    reactionCount: reactionUserIds.length,
    reactionUserIds: reactionUserIds,
    createdAt: message.createdAt,
    updatedAt: message.updatedAt,
    sender: message.sender
  };
}

export function textToLink(text: string) {
  // Put the URL to variable $1 and Domain name
  // to $3 after visiting the URL
  const RegExp =
    /(\b(https?|ftp|file):\/\/([-A-Z0-9+&@#%?=~_|!:,.;]*)([-A-Z0-9+&@#%?\\/=~_|!:,.;]*)[-A-Z0-9+&@#\\/%=~_|])/gi;

  // Replacing the RegExp content by HTML element
  return text.replace(
    RegExp,
    "<a href='$1' target='_blank' class='hover:tw-underline tw-text-blue-600' style='word-break: break-all;'>$1</a>"
  );
}
