import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { ChatChannel } from '../../../../types/common';

type Props = {
  channel: ChatChannel;
};

const ChatWelcomeMessage: React.FC<Props> = ({ channel }) => {
  const { t } = useTranslation();

  return (
    <>
      {/* S : 채널 생성 후 첫 화면 문구 (이 문구 하단으로 채팅이 쌓이면서 상단으로 사라집니다.) */}
      <div className="tw-my-10 tw-flex tw-flex-col tw-items-center tw-text-center">
        <img className="tw-size-16 tw-rounded-2" src={channel?.coverUrl || channel.creator?.plainProfileUrl} />
        {/* span 안에 해당 채널명이 들어갑니다. */}
        <div className="tw-mt-3 tw-text-20 tw-font-semibold tw-text-black-title">
          {t('chat.firstCreateMessage.welcomeTo')}
          <br />
          <span className="tw-text-primary-100">{channel?.name}</span>
        </div>
        {/* span 안에 해당 채널명이 들어갑니다. */}
        <div className="tw-mt-3 tw-text-14 tw-text-gray-700">
          <Trans i18nKey="chat.firstCreateMessage.startMessage">
            <span className="tw-font-medium">{{ channelName: channel?.name } as any}</span>
          </Trans>
        </div>
      </div>
      {/* E : 채널 생성 후 첫 화면 문구 */}
    </>
  );
};
export default ChatWelcomeMessage;
