import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import ModalContainer from '../../components/layout/ModalContainer';
import useAlert from '../../hooks/useAlert';
import useGroupChannel from '../../hooks/useGroupChannel';
import useTextInputChange from '../../hooks/useTextInputChange';
import { SuperClubUserInterface } from '../../libs/ApiRepository';
import { getUser } from '../../libs/chatApi';

import classNames from 'components/styled/util';
import apis from 'libs/apis';

type Params = {
  userId: string;
};

const Invite: React.FC = () => {
  const { userId } = useParams<Params>();
  const { currentChannel, inviteChannel } = useGroupChannel();
  const [memo, setMemo, _reset, _clear, set] = useTextInputChange('', 100);
  const { t } = useTranslation();

  const [inviteUser, setInviteUser] = useState<SuperClubUserInterface | null>(null);
  const [isOpenedPreviousMessages, setIsOpenedPreviousMessages] = useState(false);
  const [selectMessage, setSelectMessage] = useState(t('chat.invitePopup.inviteTitle.placeholder'));
  const [isLoading, setIsLoading] = useState(false);

  const { show, close } = useAlert();

  const { data: inviteMessageList = [] } = useQuery({
    queryKey: ['inviteMessageList'],
    queryFn: () => apis.getInviteMessageList(),
    select: (response) => response.data
  });

  const postInviteMessageMutation = useMutation({
    mutationFn: apis.postInviteMessage
  });

  // TODO:  유저초대
  const onInvite = useCallback(async () => {
    if (!currentChannel) {
      return;
    }

    if (!userId) {
      return;
    }
    const userIdItem = userId;
    try {
      setIsLoading(true);
      // 초대팝업에서 사용한 메모와 초대한 유저정보를 가지고 유저를 초대합니다.
      await inviteChannel(currentChannel, memo, [userIdItem]);
      postInviteMessageMutation.mutateAsync({ content: memo });
    } catch (e) {
      show({
        content: t('chat.alert.userBanned.contents'),
        buttons: [
          {
            title: t('common.button.confirm'),
            onClick: close
          }
        ]
      });
    } finally {
      setIsLoading(false);
    }
  }, [currentChannel, userId, memo]);

  useEffect(() => {
    async function init() {
      if (!userId) {
        return;
      }
      const user = await getUser(userId);
      // await fetchUser(userId);
      setInviteUser(user);
    }
    init();
  }, [userId]);

  return (
    <ModalContainer
      width="w480"
      height={480}
      Buttons={[
        <button className="btn primary button_lg" disabled={isLoading} onClick={onInvite} key={0}>
          <span>{t('chat.invitePopup.button')}</span>
        </button>
      ]}
    >
      <>
        <div className="title">{t('chat.invitePopup.title')}</div>
        <div className="gray_con line invite">
          <div className="invite_title send">
            <span className="invite_send_title">{t('chat.invitePopup.toUser')}</span>
            <img src={inviteUser?.profile_image_url} />
            <span className="invite_nickname">{inviteUser?.username}</span>
          </div>
          <div className="invite_title channel">
            <span className="invite_send_title">{t('chat.invitePopup.whichChannel')}</span>
            <img src={currentChannel?.coverUrl} />
            <span className="invite_nickname">{currentChannel?.name}</span>
          </div>
          <div className="select_wrap">
            {/* 이전에 적은 초대 메세지가 없다면 disabled 처리 */}
            <div
              className={classNames({
                select: true,
                active: isOpenedPreviousMessages === true
              })}
            >
              <div
                className="selected"
                onClick={() => {
                  setIsOpenedPreviousMessages((state) => !state);
                }}
              >
                <div className="selected-value">{selectMessage}</div>
                <button type="button" className="arrow" onClick={() => {}}>
                  <span className="a11y">선택</span>
                </button>
              </div>
              <ul>
                {inviteMessageList.length > 0 ? (
                  inviteMessageList.map((item, index) => (
                    <li
                      className="option"
                      key={index}
                      onClick={() => {
                        setSelectMessage(item.content);
                        set(item.content);
                        setIsOpenedPreviousMessages((state) => !state);
                      }}
                    >
                      {item.content}
                    </li>
                  ))
                ) : (
                  <>
                    <li
                      className="option"
                      onClick={() => {
                        setSelectMessage(t('chat.invitePopup.inviteTitle.mock1'));
                        set(t('chat.invitePopup.inviteTitle.mock1'));
                        setIsOpenedPreviousMessages((state) => !state);
                      }}
                    >
                      {t('chat.invitePopup.inviteTitle.mock1')}
                    </li>
                    <li
                      className="option"
                      onClick={() => {
                        setSelectMessage(t('chat.invitePopup.inviteTitle.mock2'));
                        set(t('chat.invitePopup.inviteTitle.mock2'));
                        setIsOpenedPreviousMessages((state) => !state);
                      }}
                    >
                      {t('chat.invitePopup.inviteTitle.mock2')}
                    </li>
                    <li
                      className="option"
                      onClick={() => {
                        setSelectMessage(t('chat.invitePopup.inviteTitle.mock3'));
                        set(t('chat.invitePopup.inviteTitle.mock3'));
                        setIsOpenedPreviousMessages((state) => !state);
                      }}
                    >
                      {t('chat.invitePopup.inviteTitle.mock3')}
                    </li>
                  </>
                )}
              </ul>
            </div>
          </div>
          <div className="textarea_div">
            <textarea placeholder={t('chat.invitePopup.memo.placeholder')} onChange={setMemo} value={memo} />
            <span className="num">
              <span className="black">{memo.length}</span>/100
            </span>
          </div>
        </div>
      </>
    </ModalContainer>
  );
};

export default Invite;
