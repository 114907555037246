import React from 'react';

const gradientColor = {
  LITE_GREY: ['#eeeeee', '#eeeeee'],
  YELLOW: ['#ffd300', '#ffd300'],
  GREEN: ['#e2ff00', '#17d010'],
  BLUE: ['#6fedda', '#0da8e2'],
  YELLOW_PINK: ['#ffd300', '#ff45a1'],
  PINK: ['#ff8dc5', '#ff2a55'],
  PINK_VIOLET: ['#ff45a1', '#7d0edf'],
  PURPLE: ['#6563ff', '#8003ef']
} as const;

export type RingColor = keyof typeof gradientColor;

interface TrustRingProps {
  width?: number;
  ringColor?: RingColor;
}

const TrustRing: React.FC<TrustRingProps> = (props) => {
  const { width = 100, ringColor = 'LITE_GREY' } = props;
  const circlePosition = {
    cx: width / 2,
    cy: width / 2,
    r: width / 2
  };

  const randomHashChar = Math.random().toString(36).substring(2, 15);

  return (
    <svg className="tw-absolute tw-h-full tw-w-full">
      <defs>
        <mask id={`donut-mask-${randomHashChar}`}>
          <circle id="outer" cx={circlePosition.cx} cy={circlePosition.cy} r={circlePosition.r} fill="white" />
          <circle id="inner" cx={circlePosition.cx} cy={circlePosition.cy} r={circlePosition.r * 0.92} />
        </mask>
        <linearGradient id={`${randomHashChar}rC`} x1="0%" y1="0%" x2="100%" y2="100%">
          <stop offset="14%" stopColor={gradientColor[ringColor]?.[0]} />
          <stop offset="86%" stopColor={gradientColor[ringColor]?.[1]} />
        </linearGradient>
      </defs>
      <circle {...circlePosition} mask={`url(#donut-mask-${randomHashChar})`} fill={`url(#${randomHashChar}rC)`} />
    </svg>
  );
};

export default TrustRing;
