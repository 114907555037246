import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { User } from '@sendbird/chat';
import { useRecoilCallback, useRecoilValue } from 'recoil';
import { useOnClickOutside } from 'usehooks-ts';

import useCurrentUser from '../../../hooks/useCurrentUser';
import useGroupChannel from '../../../hooks/useGroupChannel';
import { getIsMaster, getIsMessageOwner, getIsStaff } from '../../../libs/helper';
import { fetchGroupChannel } from '../../../libs/sendbird';
import groupChannelState from '../../../store/atoms/groupChannelState';
import messageMoreState from '../../../store/atoms/messageMoreState';
import messageTranslateKeysState from '../../../store/atoms/messageTranslateKeysState';
import { ChatMessage } from '../../../types/common';

import { ReactComponent as NoticeIcon } from 'assets/icons/ic-chat-notice-bk.svg';
import { ReactComponent as CopyIcon } from 'assets/icons/ic-copy.svg';
import { ReactComponent as ReportIcon } from 'assets/icons/ic-report.svg';
import { ReactComponent as TranslateIcon } from 'assets/icons/ic-translate.svg';
import { ReactComponent as TrashIcon } from 'assets/icons/ic-trash.svg';
import classNames from 'components/styled/util';

type Props = {
  message: ChatMessage;
};

const ChatMessageMoreMenu: React.FC<Props> = ({ message }) => {
  const { t } = useTranslation();
  const { currentChannel, addNotice, removeMessage } = useGroupChannel();
  const { currentUser } = useCurrentUser();
  const refMoreMenu = useRef<HTMLDivElement | null>(null);
  const navigate = useNavigate();
  const messageMoreStateValue = useRecoilValue(messageMoreState);
  const messageTranslateKeysStateValue = useRecoilValue(messageTranslateKeysState);

  const close = useRecoilCallback(
    ({ set }) =>
      () => {
        set(messageMoreState, (state) => ({
          ...state,
          popup: false,
          messageId: null
        }));
      },
    []
  );

  const onReport = useRecoilCallback(
    () => () => {
      const { messageId } = message;
      navigate(`report/message/${messageId}`, {
        state: {
          messageId
        }
      });
      close();
    },
    [navigate, message]
  );

  const onRemove = useRecoilCallback(
    ({ snapshot }) =>
      async () => {
        const channel = snapshot.getLoadable(groupChannelState).getValue();

        if (!channel) {
          return;
        }

        await removeMessage(channel, message);
        close();
      },
    [message]
  );

  const toggleTranslate = useRecoilCallback(
    ({ set }) =>
      () => {
        set(messageTranslateKeysState, (state) => {
          if (state.includes(message.messageId)) {
            return state.filter((id) => id !== message.messageId);
          } else {
            return [...state, message.messageId];
          }
        });
        close();
      },
    [message]
  );

  const onCopy = useRecoilCallback(
    () => () => {
      navigator.clipboard.writeText(message.message);
      close();
    },
    [message]
  );

  const onNotice = useRecoilCallback(
    ({ snapshot }) =>
      async () => {
        const channel = snapshot.getLoadable(groupChannelState).getValue();

        if (!channel) {
          return;
        }

        const groupChannel = await fetchGroupChannel(channel.url);

        await addNotice(groupChannel, message);

        close();
      },
    [currentChannel, removeMessage, message]
  );

  const isOwner = getIsMessageOwner({ message, user: currentUser as User });
  const isMaster = getIsMaster({ channel: currentChannel, user: currentUser as User });
  const isStaff = getIsStaff({ channel: currentChannel, user: currentUser as User });
  const isTranslate = messageTranslateKeysStateValue.includes(message.messageId);

  const isAvailableNotice = isMaster || isStaff;
  // const isAvailableDelete = isOwner || isMaster || isStaff;
  const isAvailableDelete = isOwner;
  const isAvailableReport = !isOwner;
  const isAvailableTranslate = message.customType === 'TEXT';

  useOnClickOutside(refMoreMenu, () => {
    if (message.messageId === messageMoreStateValue.messageId) {
      close();
    }
  });

  const [positionClass, setPositionClass] = useState('bottom');

  useEffect(() => {
    if (refMoreMenu.current) {
      const rect = refMoreMenu.current.getBoundingClientRect();
      const midHeight = window.innerHeight / 2;
      rect.bottom <= midHeight ? setPositionClass('bottom') : setPositionClass('top');
    }
  }, []);

  const popupButtonClassNames =
    'tw-h-8 tw-items-center tw-w-full tw-flex tw-gap-1 tw-rounded-0 tw-px-2 hover:tw-bg-gray-100';

  return (
    <div
      className={classNames(
        'tw-absolute tw-right-[-3px] tw-z-10 tw-block tw-w-full tw-min-w-[120px] tw-pt-[1px] tw-text-14',
        {
          'tw-bottom-8': positionClass === 'top',
          'tw-top-8': positionClass === 'bottom'
        }
      )}
      ref={refMoreMenu}
    >
      <div className="tw-flex tw-w-full tw-flex-col tw-rounded-2 tw-border tw-border-gray-200 tw-bg-white tw-py-2 tw-shadow-layer">
        {isAvailableTranslate && (
          <button type="button" className={classNames(popupButtonClassNames)} onClick={toggleTranslate}>
            <TranslateIcon className="tw-size-6 tw-shrink-0 tw-text-black-body" />
            <p className="">{isTranslate ? t('chat.menu.more.seeOrigin') : t('chat.menu.more.seeTranslate')}</p>
          </button>
        )}
        <button type="button" className={classNames(popupButtonClassNames)} onClick={onCopy}>
          <CopyIcon className="tw-size-6 tw-shrink-0 tw-text-black-body" />
          <p>{t('chat.menu.alert.copy')}</p>
        </button>
        {isAvailableNotice === true && (
          <button type="button" className={classNames(popupButtonClassNames)} onClick={onNotice}>
            <NoticeIcon className="tw-size-6 tw-shrink-0 tw-text-black-body" />
            <p>{t('chat.menu.more.notice')}</p>
          </button>
        )}
        {isAvailableDelete === true && (
          <button type="button" className={classNames(popupButtonClassNames)} onClick={onRemove}>
            <TrashIcon className="tw-size-6 tw-shrink-0 tw-text-black-body" />
            <p>{t('chat.menu.more.delete')}</p>
          </button>
        )}
        {isAvailableReport === true && (
          <button type="button" className={classNames(popupButtonClassNames)} onClick={onReport}>
            <ReportIcon className="tw-size-6 tw-shrink-0 tw-text-black-body" />
            <p>{t('chat.menu.more.report')}</p>
          </button>
        )}
      </div>
    </div>
  );
};
export default ChatMessageMoreMenu;

export const getPositionClass = (target: HTMLDivElement | null, windowHeight: number) => {
  if (!target) return '';

  const { bottom } = target.getBoundingClientRect();
  const midHeight = windowHeight / 2;

  // 절반보다 위에 있을 경우 아래에 표시, 절반보다 아래에 있을 경우 위로 표시
  return bottom <= midHeight ? 'bottom' : 'top';
};
