import React, { useCallback, useEffect, useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { GifsResult, GiphyFetch } from '@giphy/js-fetch-api';
import { IGif } from '@giphy/js-types';
import { Grid } from '@giphy/react-components';
import { useRecoilCallback } from 'recoil';

import useElementSize from '../../../hooks/useElementSize';
import useMessageInput from '../../../hooks/useMessageInput';
import useTextInputChange from '../../../hooks/useTextInputChange';
import groupChannelState from '../../../store/atoms/groupChannelState';
import { CustomMessageType } from '../../../types/common';

import { ReactComponent as SearchIcon } from 'assets/icons/ic-search.svg';
import Input from 'components/jayden/Input';

const ChatMessageEmoticonGifInput = () => {
  const { closeEmojiPicker, messageText, sendMessageToChannel } = useMessageInput();
  const [keyword, onChangeKeyword] = useTextInputChange('', 30);

  const refGf = useRef<GiphyFetch | null>(null);
  const [containerRef, { width: containerWidth }] = useElementSize();

  const { t } = useTranslation();

  const onSelectGif = useRecoilCallback(
    ({ snapshot }) =>
      async (gif: IGif) => {
        const channel = snapshot.getLoadable(groupChannelState).getValue();

        if (!channel) {
          return;
        }

        closeEmojiPicker();
        await sendMessageToChannel(channel.url, {
          message: gif.images.original.url,
          customType: CustomMessageType.GIF
        });
      },
    [messageText, closeEmojiPicker]
  );

  const fetchGifs = useCallback(
    async (offset: number): Promise<GifsResult> => {
      if (!refGf.current) {
        return {
          data: [],
          meta: {
            msg: '',
            response_id: '',
            status: 0
          },
          pagination: {
            count: 0,
            offset: 0,
            total_count: 0
          }
        };
      }

      if (!keyword) {
        const result = await refGf.current?.trending({
          offset,
          limit: 10
        });
        return result;
      }

      const result = await refGf.current?.search(keyword, {
        offset,
        limit: 10
      });
      return result;
    },
    [keyword]
  );

  useEffect(() => {
    if (!refGf.current) {
      refGf.current = new GiphyFetch('t8C4qqiT51Q8OG9K8bLoBN6LZtmGd2dz');
    }
  }, []);

  return (
    <div className="tw-my-3 tw-h-[calc(100%-83px)] tw-overflow-y-scroll [&_.loader]:tw-hidden" ref={containerRef}>
      <Input
        type="text"
        placeholder={t('chat.stickerPopup.search.placeholder')}
        className="tw-peer"
        value={keyword}
        onChange={onChangeKeyword}
        suffix={
          <SearchIcon className="tw-absolute tw-right-3 tw-top-1/2 tw-inline-block tw-size-6 -tw-translate-y-1/2 tw-cursor-pointer tw-text-gray-500 tw-transition-all peer-focus:tw-text-black-body" />
        }
      />

      <div className="tw-h-3" />

      <Grid
        width={containerWidth - 8}
        columns={3}
        fetchGifs={fetchGifs}
        key={keyword}
        hideAttribution={true}
        onGifClick={onSelectGif}
        noLink
        noResultsMessage={
          <div className="tw-flex tw-h-[299px] tw-flex-col tw-items-center tw-justify-center tw-gap-5 tw-text-center">
            <img src="/img/com/img-error-page.webp" className="tw-w-[200px]" alt="no result" />
            <div className="tw-text-18 tw-font-bold tw-text-black-title">
              <Trans i18nKey="chat.stickerPopup.gif.empty.contents">{{ searchWord: keyword }}</Trans>
            </div>
          </div>
        }
      />

      {/* E : 검색결과 */}
    </div>
  );
};
export default ChatMessageEmoticonGifInput;
