import { sendbirdInstance } from 'apis';
type UserParams = {
  userId: string;
};

const sendbirdApi = {
  getUser: async ({ userId }: UserParams) => {
    const { data } = await sendbirdInstance.get(`/users/${userId}`);
    return data;
  }
};

export default sendbirdApi;
