import axios from 'axios';

import { Grade } from 'components/profileAvatar/LevelCap';
import { RingColor } from 'components/profileAvatar/TrustRing';
import { AuthStorage } from 'constants/storage';

export interface SuperClubUserInterface {
  id: number;
  username: string;
  hash?: string;
  description?: string;
  certified_progress?: number;
  profile_progress?: number;
  point?: number;
  current_point?: number;
  grade_title: Grade;
  level?: number;
  ring_color: RingColor;
  status?: string;
  phone?: string;
  email?: string;
  gender?: string;
  birth?: string;
  nation?: string;
  profile_image_url?: string;
  banner_image_url?: string;
  badge_image_url?: string;
  friend_count?: number;
  bookmark_count?: number;
  respect_count?: number;
  heart_count?: number;
  certified_count?: number;
  created?: string;
  modified?: string;
  labels?: [{ id: number; image_url: string }];
  friends?: any[]; // Friend 타입으로 변경해야 합니다.
  friend_request_status?: string;
  same_friends?: any[]; // Friend 타입으로 변경해야 합니다.
  is_friend?: boolean;
  is_banned?: boolean;
  is_blocked?: boolean;
  is_blocking?: boolean;
  is_bookmarked?: boolean;
  is_hearted?: boolean;
  is_respected?: boolean;
  unread_count?: number;
  wallet_address?: string;
  withdraw_date?: string;
  nickname?: string | null;
  icons: any[];
  language_code?: string;

  friend_request_id?: number;
}
const getToken = () => localStorage.getItem(AuthStorage.ACCESS_TOKEN);
export const getUserMe = () =>
  axios.get(`${process.env.REACT_APP_COMMON_API_URL}/v1/user/me`, {
    headers: {
      Authorization: 'Bearer ' + getToken()
    }
  });
export const getUsers = (params?: any) =>
  axios.get(`${process.env.REACT_APP_COMMON_API_URL}/v1/users`, {
    params,
    headers: {
      Authorization: 'Bearer ' + getToken()
    }
  });
export const getUser = (id: string) => axios.get(`${process.env.REACT_APP_COMMON_API_URL}/v1/user/${id}`);

export const getUserPermission = (id: string) =>
  axios.get(`${process.env.REACT_APP_COMMON_API_URL}/v1/user/${id}/permission`);

export const getUserFriendList = async ({ userId, params }: { userId: string; params?: any }) => {
  const { data } = await axios.get(`${process.env.REACT_APP_COMMON_API_URL}/v1/user/${userId}/friends`, {
    params,
    headers: {
      Authorization: 'Bearer ' + getToken()
    }
  });

  return data;
};

// open-channel api
export const getOpenChannel = (id: string) => axios.get(`${process.env.REACT_APP_CHAT_API_URL}/v1/open-channel/${id}`);
export const getOpenChannels = (data: object) =>
  axios.get(`${process.env.REACT_APP_CHAT_API_URL}/v1/open-channels`, {
    params: data,
    headers: {
      Authorization: 'Bearer ' + getToken()
    }
  });
export const postOpenChannel = (data: object) =>
  axios.post(`${process.env.REACT_APP_CHAT_API_URL}/v1/open-channel`, data, {
    headers: {
      Authorization: 'Bearer ' + getToken()
    }
  });
export const postOpenChannelInvite = (id: string) =>
  axios.post(`${process.env.REACT_APP_CHAT_API_URL}/v1/open-channel/${id}/invite`);
export const patchOpenChannel = (id: string, data: object) =>
  axios.patch(`${process.env.REACT_APP_CHAT_API_URL}/v1/open-channel/${id}`, data, {
    headers: {
      Authorization: 'Bearer ' + getToken()
    }
  });
export const deleteOpenChannel = (id: string) =>
  axios.delete(`${process.env.REACT_APP_CHAT_API_URL}/v1/open-channel/${id}`);

// Direct-channel api
export const getDirectChannel = (id: string) =>
  axios.get(`${process.env.REACT_APP_CHAT_API_URL}/v1/direct-channel/${id}`);
export const getDirectChannels = () =>
  axios.get(`${process.env.REACT_APP_CHAT_API_URL}/v1/direct-channels`, {
    headers: {
      Authorization: 'Bearer ' + getToken()
    }
  });

export const postDirectChannel = (data: object) =>
  axios.post(`${process.env.REACT_APP_CHAT_API_URL}/v1/direct-channel`, data, {
    headers: {
      Authorization: 'Bearer ' + getToken()
    }
  });

export const patchDirectChannel = (id: string, data: object) =>
  axios.patch(`${process.env.REACT_APP_CHAT_API_URL}/v1/direct-channel/${id}`, data, {
    headers: {
      Authorization: 'Bearer ' + getToken()
    }
  });
export const deleteDirectChannel = (id: string) =>
  axios.delete(`${process.env.REACT_APP_CHAT_API_URL}/v1/direct-channel/${id}`);

// invite
export const getInvite = (id: string) => axios.get(`${process.env.REACT_APP_CHAT_API_URL}/v1/invite/${id}`);
export const postInviteApproval = (id: string) =>
  axios.post(`${process.env.REACT_APP_CHAT_API_URL}/v1/invite/${id}/approval`);
export const postInviteRefusal = (id: string) =>
  axios.post(`${process.env.REACT_APP_CHAT_API_URL}/v1/invite/${id}/refusal`);

export const certificateServerUser = (id: string) =>
  axios.get(`https://test-platform-login-api.ccr.co.kr/auth/users/${id}`);

const clubInstance = axios.create({
  baseURL: process.env.REACT_APP_CLUB_API_URL,
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + getToken()
  }
});

export const getClub = (id: string) => clubInstance.get(`/v1/club/${id}`);
export const getClubProfileList = (id: string, params: any) => clubInstance.get(`/v1/club/${id}/profiles`, { params });

export default {
  getUserMe,
  getUsers,
  getUser,
  getOpenChannel,
  getOpenChannels,
  postOpenChannel,
  postOpenChannelInvite,
  patchOpenChannel,
  deleteOpenChannel,
  getDirectChannel,
  getDirectChannels,
  postDirectChannel,
  patchDirectChannel,
  deleteDirectChannel,
  getInvite,
  postInviteApproval,
  postInviteRefusal,
  certificateServerUser,
  getClub,
  getClubProfileList
};
