import React, { forwardRef } from 'react';

import classNames from 'components/styled/util';

interface InputProps {
  type?: 'text';
  value?: string;
  placeholder?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e?: React.FocusEvent<HTMLInputElement>) => void;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onKeyUp?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  suffix?: JSX.Element;
  className?: string;
  disabled?: boolean;
  readOnly?: boolean;
}

const Input = forwardRef((props: InputProps, ref: React.Ref<HTMLInputElement>) => {
  const {
    onChange,
    onFocus = () => {},
    onKeyUp = () => {},
    onKeyDown = () => {},
    onBlur = () => {},
    value,
    placeholder,
    type = 'text',
    suffix,
    className,
    disabled,
    readOnly
  } = props;

  return (
    <div className="tw-relative tw-w-full">
      <input
        ref={ref}
        className={classNames(
          'tw-h-11 tw-w-full tw-rounded-2 tw-border tw-border-gray-100 tw-bg-gray-100 tw-px-4 tw-text-14 tw-text-black-body',
          // focus
          'focus:tw-border-primary-100',
          {
            'tw-pr-10': !!suffix
          },
          className
        )}
        type={type}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
        onKeyDown={onKeyDown}
        onKeyUp={onKeyUp}
        placeholder={placeholder}
        disabled={disabled}
        readOnly={readOnly}
      />

      {suffix && suffix}
    </div>
  );

  Input.displayName = '123';
});

export default Input;
