import React, { PropsWithChildren } from 'react';

import classNames from 'components/styled/util';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  /**
   * 버튼 크기
   * @default "md"
   *
   * xs: 패딩에 맞춘 최소단위 (height 지정 없음)
   * sm: 40px
   * md: 48px
   * lg: 52px
   * xl: 56px
   *
   */
  size: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  color:
    | 'default'
    | 'primary'
    | 'secondary'
    | 'google'
    | 'apple'
    | 'facebook'
    | 'twitter'
    | 'point'
    | 'success'
    | 'warning'
    | 'error';
  outline?: boolean;
  icons?: React.ReactNode;
}

const BUTTON_CLASS = {
  SIZE: {
    xs: 'tw-w-fit tw-rounded-1.5 tw-px-2.5 tw-py-2 tw-text-13',
    sm: 'tw-h-10 tw-w-fit tw-rounded-1.5 tw-text-14',
    md: 'tw-h-12 tw-w-fit tw-rounded-2 tw-px-3.5 tw-text-15',
    lg: 'tw-h-[52px] tw-w-fit tw-rounded-2.5 tw-px-4 tw-text-17',
    xl: 'tw-text-20 tw-h-14 tw-w-fit tw-rounded-3 tw-px-5'
  },
  COLOR: {
    default: 'tw-border-gray-500 tw-bg-gray-100 tw-text-black-title hover:tw-bg-gray-200 active:tw-bg-gray-300',
    primary:
      'tw-border-primary-200 tw-bg-primary-100 tw-text-white hover:tw-bg-primary-200 active:tw-border-primary-300',
    secondary:
      'tw-border-secondary-200 tw-bg-secondary-100 tw-text-white hover:tw-bg-secondary-200 active:tw-border-secondary-300',
    point: 'tw-border-point-200 tw-bg-point-100 tw-text-white hover:tw-bg-point-200 active:tw-border-point-300',
    success:
      'tw-border-success-200 tw-bg-success-100 tw-text-white hover:tw-bg-success-200 active:tw-border-success-300',
    warning:
      'tw-border-warning-200 tw-bg-warning-100 tw-text-black-body hover:tw-bg-warning-200 active:tw-border-warning-300',
    error: 'tw-border-error-200 tw-bg-error-100 tw-text-white hover:tw-bg-error-200 active:tw-border-error-300',
    google: 'tw-border-gray-400 tw-bg-white tw-relative tw-text-gray-700 hover:tw-bg-gray-200',
    apple: 'tw-border-black tw-bg-black tw-relative tw-text-white hover:tw-bg-[#393A3C]',
    facebook: 'tw-border-[#304d8a] tw-bg-[#304d8a] tw-relative tw-text-white hover:tw-bg-[#1F386C]',
    twitter: 'tw-border-[#55acee] tw-bg-[#55acee] tw-relative tw-text-white hover:tw-bg-[#2F96E5]'
  },
  OUTLINE: {
    default: 'tw-border-gray-500 tw-bg-white tw-text-black-title hover:tw-bg-gray-100 active:tw-bg-gray-200',
    primary: 'tw-border-primary-100 tw-bg-white tw-text-primary-100 hover:tw-bg-gray-100 active:tw-bg-gray-200',
    secondary: 'tw-border-secondary-100 tw-bg-white tw-text-secondary-100 hover:tw-bg-gray-100 active:tw-bg-gray-200',
    point: 'tw-border-point-100 tw-bg-white tw-text-point-100 hover:tw-bg-gray-100 active:tw-bg-gray-200',
    success: 'tw-border-success-100 tw-bg-white tw-text-success-100 hover:tw-bg-gray-100 active:tw-bg-gray-200',
    warning: 'tw-border-warning-100 tw-bg-white tw-text-warning-100 hover:tw-bg-gray-100 active:tw-bg-gray-200',
    error: 'tw-border-error-100 tw-bg-white tw-text-error-100 hover:tw-bg-gray-100 active:tw-bg-gray-200',
    google: 'tw-border-gray-400 tw-bg-white tw-relative tw-text-gray-700 hover:tw-bg-gray-200',
    apple: 'tw-border-black tw-bg-black tw-relative tw-text-white hover:tw-bg-[#393A3C]',
    facebook: 'tw-border-[#304d8a] tw-bg-[#304d8a] tw-relative tw-text-white hover:tw-bg-[#1F386C]',
    twitter: 'tw-border-[#55acee] tw-bg-[#55acee] tw-relative tw-text-white hover:tw-bg-[#2F96E5]'
  }
};

const Button = (props: PropsWithChildren<ButtonProps>) => {
  const {
    type = 'button',
    size = 'md',
    color = 'default',
    outline = false,
    className,
    children,
    icons,
    onClick,
    disabled = false,
    ...restProps
  } = props;

  return (
    <button
      {...restProps}
      type={type}
      onClick={onClick}
      className={classNames(
        'tw-border tw-transition-all tw-duration-300 disabled:tw-cursor-not-allowed',
        [BUTTON_CLASS.SIZE[size]],
        {
          'disabled:tw-border-gray-400 disabled:tw-bg-gray-400 disabled:tw-text-white': !outline,
          'disabled:tw-border-gray-500 disabled:tw-bg-white disabled:tw-text-gray-400': outline
        },
        {
          [BUTTON_CLASS.COLOR[color]]: !outline,
          [BUTTON_CLASS.OUTLINE[color]]: !!outline
        },
        className
      )}
      disabled={disabled}
    >
      {icons && <div className="tw-absolute tw-left-4 tw-top-1/2 tw-size-6 -tw-translate-y-1/2">{icons}</div>}
      {children}
    </button>
  );
};

export default Button;
