import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { User } from '@sendbird/chat';
import dayjs from 'dayjs';
import { useRecoilValue } from 'recoil';
import { v4 as uuidv4 } from 'uuid';

import CropImageModal from '../../components/image/CropImageModal';
import { MAX_PARTICIPANTS_LIMIT } from '../../constants/common';
import useCurrentUser from '../../hooks/useCurrentUser';
import useTextInputChange from '../../hooks/useTextInputChange';
import { uploadToS3 } from '../../libs/s3';
import { fetchUser } from '../../libs/sendbird';
import { groupChannelStateByUrl } from '../../store/atoms/groupChannelsState';
import { ChannelPrivacyType } from '../../types/common';

import ChannelSettingContainer, { ChannelSettingTab } from './components/ChannelSettingContainer';

import { ReactComponent as MinusIcon } from 'assets/icons/ic-minus-sm.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/ic-plus-sm.svg';
import classNames from 'components/styled/util';

import useNewGroupChannel from 'hooks/use-group-channel';

import { getChannelIdByChannelUrl } from 'utils/common';

type Params = {
  channelUrl: string;
};

const ChannelSettingOverview: React.FC = () => {
  const { channelUrl } = useParams<Params>();

  if (!channelUrl) {
    return null;
  }

  const channel = useRecoilValue(groupChannelStateByUrl(channelUrl));
  const { t } = useTranslation();

  if (!channel) {
    return null;
  }

  const [file, setFile] = useState<FileList | null>(null);
  const hiddenFileInputRef = React.useRef<HTMLInputElement | null>(null);
  const { currentUser } = useCurrentUser();
  const { updateChannel } = useNewGroupChannel();
  const [master, setMaster] = useState<User | null>(null);
  const [isToast, setIsToast] = useState(false);
  const [participantsLimit, setParticipantsLimit] = useState(channel.data.participantsLimit);
  const [forbiddenWords, setForbiddenWords] = useState<string[]>(channel.data.forbiddenWords);
  const [thumbnail, setThumbnail] = useState<string | null>();
  const [forbiddenWord, onChangeForbiddenWord, , clearForbiddenWord] = useTextInputChange('', 20);
  const [channelName, onChangeChannelName, resetChannelName, clearChannelName] = useTextInputChange(
    channel.name || '',
    20
  );

  const [introduction, onChangeIntroduction, resetIntroduction] = useTextInputChange(
    channel.data.introduction || '',
    200
  );

  const channelNameError = channelName.length > 0 && (channelName.length < 2 || channelName.length > 20);

  const [channelPrivacyType, setChannelPrivacyType] = useState(
    channel.data.onlyClubMembers
      ? ChannelPrivacyType.ONLY_CLUB_MEMBERS
      : channel.isPublic === true
        ? ChannelPrivacyType.OPEN
        : ChannelPrivacyType.PRIVATE
  );

  const isPublic = channelPrivacyType === ChannelPrivacyType.OPEN;

  const [coverUrl, setCoverUrl] = useState(channel.coverUrl || currentUser?.profileUrl || '');

  const onChangeChannelPrivacyType = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setChannelPrivacyType(event.target.value as unknown as ChannelPrivacyType);
  }, []);

  const onChangeChannelThumbnail = useCallback((base64: string) => {
    setCoverUrl(base64);
    setThumbnail(base64);
  }, []);

  const onUpdateChannel = useCallback(async () => {
    if (!currentUser?.userId) {
      return;
    }

    if (!channel) {
      return;
    }

    let newCoverUrl = coverUrl;

    if (thumbnail) {
      const res = await fetch(thumbnail);
      const blob = await res.blob();

      const fileName = `${uuidv4()}.jpg`;
      const response = await uploadToS3({
        dir: `channel`,
        contentType: 'jpeg',
        Body: blob,
        orgFileName: fileName,
        fileName: fileName,
        fileSize: thumbnail.length
      });

      newCoverUrl = response.cloudFrontUrl;
    }

    await updateChannel({
      channelId: getChannelIdByChannelUrl(channel.url),
      name: channelName,
      coverUrl: newCoverUrl,
      participantsLimit,
      introduction,
      isPublic,
      forbiddenWords
    });

    setIsToast(true);

    setTimeout(() => {
      setIsToast(false);
    }, 3000);
  }, [
    channelName,
    introduction,
    currentUser?.userId,
    coverUrl,
    thumbnail,
    channelPrivacyType,
    channel.url,
    forbiddenWords,
    participantsLimit
  ]);

  // const {} = useGroupChannel();
  const onChangeThumbnail = useCallback(() => {
    hiddenFileInputRef?.current?.click();
  }, []);

  const onChangeFile = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setFile(event.target.files);
  }, []);

  const onCropCancel = useCallback(() => {
    hiddenFileInputRef?.current?.setAttribute('value', '');
    setFile(null);
  }, []);

  const onCropSave = useCallback((base64: string) => {
    hiddenFileInputRef?.current?.setAttribute('value', '');
    setFile(null);

    onChangeChannelThumbnail(base64);
  }, []);

  const selectedFile = useMemo(() => {
    return file?.item(0);
  }, [file]);

  const onRemoveForbiddenWord = useCallback(
    (removedWord: string) => async () => {
      const newForbiddenWords = forbiddenWords.filter((word) => word !== removedWord);
      setForbiddenWords(newForbiddenWords);
    },
    [forbiddenWords, channel]
  );

  const onAddForbiddenWord = useCallback(async () => {
    const newForbiddenWords = [...forbiddenWords, forbiddenWord];
    setForbiddenWords(newForbiddenWords);
    clearForbiddenWord();
  }, [forbiddenWords, forbiddenWord]);

  const onChangeParticipantLimit = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setParticipantsLimit(Math.min(Math.max(Number(event.target.value), 2), MAX_PARTICIPANTS_LIMIT));
  }, []);

  const onReset = useCallback(() => {
    setParticipantsLimit(channel.data.participantsLimit);
    setForbiddenWords(channel.data.forbiddenWords);
    clearForbiddenWord();
    setThumbnail(null);
    resetChannelName();
    resetIntroduction();
    setChannelPrivacyType(channel.isDiscoverable === true ? ChannelPrivacyType.OPEN : ChannelPrivacyType.PRIVATE);
    setCoverUrl(channel.coverUrl || currentUser?.profileUrl || '');
  }, [channel]);

  const isRegisteredForbiddenWord = useMemo(() => {
    return forbiddenWords.indexOf(forbiddenWord) > -1;
  }, [forbiddenWords, forbiddenWord]);

  const isDisabledSave = useMemo(() => {
    if (channelName.length < 2) {
      return true;
    }

    if (channelNameError) {
      return true;
    }

    return false;
  }, [channelName, channelNameError, introduction]);

  useEffect(() => {
    async function init() {
      const user = await fetchUser(channel.data.masterIds[0]);
      setMaster(user);
    }
    init();
  }, [channel]);

  return (
    <ChannelSettingContainer
      tab={ChannelSettingTab.OVERVIEW}
      Buttons={[
        <button className="left reset" key={0} onClick={onReset}>
          <span>{t('chat.settingPopup.overview.button.reset')}</span>
        </button>,
        <React.Fragment key={1}>
          {/* 수정된사항이 있을경우 저장버튼이 활성화됩니다. */}
          {/* 저장 시 Alert(Are you sure without saving?<br/>There are unsaved changes. / Button : Cancle,Confirm) */}
          <button className="btn primary button_lg save" onClick={onUpdateChannel} disabled={isDisabledSave}>
            <span>{t('chat.settingPopup.overview.button.save')}</span>
          </button>
        </React.Fragment>
      ]}
      HeaderContent={
        <>
          {/* 채널 정보로 기존정보를 불러오며 변경되지않는 부분입니다. */}
          {isToast === true && (
            <div className="toast default setting">
              <span>{t('chat.channel.setting.confirm.message')}</span>
            </div>
          )}
        </>
      }
      BottomContent={
        <>{selectedFile && <CropImageModal file={selectedFile} onCancel={onCropCancel} onSave={onCropSave} />}</>
      }
    >
      <div className="channel_info">
        {master && (
          <dl>
            <dt>{t('chat.settingPopup.overview.master')} :</dt>
            <dd>
              <img src={master.profileUrl} />
              <span>{master.nickname}#few3a</span>
            </dd>
          </dl>
        )}
        <dl>
          <dt>{t('chat.settingPopup.overview.since')} :</dt>
          <dd>{dayjs(channel.createdAt).format('YYYY, MM, DD')}</dd>
        </dl>
      </div>
      {/* Channel Name */}
      <div className="setting_title">{t('chat.settingPopup.overview.channelName.label')}</div>
      <div
        className={classNames({
          form_wrap: true,
          error: channelNameError
        })}
      >
        <span className="form_cell form_input input_lg">
          <input type="text" value={channelName} onChange={onChangeChannelName} />
          <button className="btn_reset" onClick={clearChannelName}>
            <span className="a11y">삭제</span>
          </button>
        </span>
        {channelNameError && (
          <span className="error_txt msg">{t('chat.createChannelPopup.channelName.validation')}</span>
        )}
      </div>
      {/* Introduction */}
      <div className="setting_title">
        {t('chat.settingPopup.overview.Introduction.label')}{' '}
        <span>{t('chat.settingPopup.overview.Introduction.guide')}</span>
      </div>
      <div className="textarea_div">
        <textarea
          placeholder={t('chat.settingPopup.overview.Introduction.placeholder')}
          value={introduction}
          onChange={onChangeIntroduction}
        />
        {/* 글자 수 체크 */}
        <span className="num">
          <span className="black">{introduction.length}</span>/200
        </span>
      </div>
      {/* Channel Thumbnail */}
      <div className="setting_title">{t('chat.createChannelPopup.channelThumbnail.label')}</div>
      <div className="create_thum">
        {/* 썸네일 디폴트는 채널생성하는 유저썸네일입니다. */}
        <div className="create_thum_img">
          <img src={coverUrl} />
        </div>
        <div className="create_thum_btn">
          <button type="button" className="btn primary button_md" onClick={onChangeThumbnail}>
            <span>{t('chat.createChannelPopup.channelThumbnail.changeButton')}</span>
          </button>
          <input
            type="file"
            style={{ display: 'none' }}
            ref={hiddenFileInputRef}
            onChange={onChangeFile}
            accept="image/*"
          />
          <ul className="guide popup">
            <li>{t('chat.createChannelPopup.channelThumbnail.imageSizeGuide')}</li>
          </ul>
        </div>
      </div>
      {/* User Limit */}
      <div className="setting_title">{t('chat.createChannelPopup.userLimit.label')}</div>
      <div className="tw-relative tw-flex tw-h-12 tw-w-fit tw-items-center tw-rounded-2 tw-border tw-border-gray-300 tw-px-1.5 tw-py-3">
        <MinusIcon
          className={classNames('tw-cursor-pointer tw-text-gray-500', {
            'tw-cursor-not-allowed tw-text-gray-400': Number(participantsLimit) <= 2,
            'hover:tw-text-gray-800': Number(participantsLimit) > 2
          })}
          onClick={() => {
            if (participantsLimit > 2) {
              setParticipantsLimit((prev) => Number(prev) - 1);
            }
          }}
        />
        <input
          value={participantsLimit}
          className="tw-w-[80px] tw-text-center tw-text-18 tw-font-medium"
          onChange={onChangeParticipantLimit}
        />
        <PlusIcon
          className={classNames('tw-cursor-pointer tw-text-gray-500', {
            'tw-cursor-not-allowed tw-text-gray-400': Number(participantsLimit) >= MAX_PARTICIPANTS_LIMIT,
            'hover:tw-text-gray-800': Number(participantsLimit) < MAX_PARTICIPANTS_LIMIT
          })}
          onClick={() => {
            if (Number(participantsLimit) < MAX_PARTICIPANTS_LIMIT) {
              setParticipantsLimit((prev) => Number(prev) + 1);
            }
          }}
        />
      </div>

      {/* Participation Restriction */}
      <div className="setting_title">{t('chat.createChannelPopup.participationRestriction.label')}</div>
      {/* radio 버튼은 한개만 선택 / 중복선택 불가 */}
      <div className="radio_div">
        <div className="form_wrap">
          <span className="form_cell form_radio">
            <input
              type="radio"
              id="radio30"
              value={ChannelPrivacyType.OPEN}
              onChange={onChangeChannelPrivacyType}
              checked={channelPrivacyType === ChannelPrivacyType.OPEN}
            />
            <label htmlFor="radio30">
              <span>{t('chat.createChannelPopup.participationRestriction.radioButton.open')}</span>
            </label>
          </span>
          <span className="form_cell form_radio">
            <input
              type="radio"
              id="radio31"
              value={ChannelPrivacyType.PRIVATE}
              onChange={onChangeChannelPrivacyType}
              checked={channelPrivacyType === ChannelPrivacyType.PRIVATE}
            />
            <label htmlFor="radio31">
              <span>{t('chat.createChannelPopup.participationRestriction.radioButton.private')}</span>
            </label>
          </span>
          {channel.customType === 'CLUB' && (
            <span className="form_cell form_radio">
              <input
                type="radio"
                id="radio32"
                value={ChannelPrivacyType.ONLY_CLUB_MEMBERS}
                onChange={onChangeChannelPrivacyType}
                checked={channelPrivacyType === ChannelPrivacyType.ONLY_CLUB_MEMBERS}
              />
              <label htmlFor="radio32">
                <span>{t('chat.createChannelPopup.participationRestriction.radioButton.onlyClubMembers')}</span>
              </label>
            </span>
          )}
        </div>
      </div>
      {/* 라디오버튼 눌렀을때 아래 정보텍스트가 노출됩니다. */}
      <ul className="guide popup">
        {channelPrivacyType === ChannelPrivacyType.OPEN && (
          <li>{t('chat.createChannelPopup.participationRestriction.guide.open')}</li>
        )}
        {channelPrivacyType === ChannelPrivacyType.PRIVATE && (
          <li>{t('chat.createChannelPopup.participationRestriction.guide.private')}</li>
        )}
        {channelPrivacyType === ChannelPrivacyType.ONLY_CLUB_MEMBERS && (
          <li>{t('chat.createChannelPopup.participationRestriction.guide.onlyClubMembers')}</li>
        )}
      </ul>
      {/* Voice Chat */}
      <div className="setting_title">{t('chat.settingPopup.overview.forbiddenWords.label')}</div>
      <div
        className={classNames({
          form_wrap: true,
          error: isRegisteredForbiddenWord === true
        })}
      >
        <div className="input_group">
          <span className="form_cell form_input input_md">
            <input
              type="text"
              placeholder={t('chat.settingPopup.overview.forbiddenWords.placeholder')}
              value={forbiddenWord}
              onChange={onChangeForbiddenWord}
            />
            <span className="guide_text num">
              <span>{forbiddenWord.length}</span>/20
            </span>
          </span>
          {/* Add 클릭 시 Alert(The same forbidden word has already been registered / button : Confirm) */}
          <button
            type="button"
            className="btn primary_line button_md"
            onClick={onAddForbiddenWord}
            disabled={isRegisteredForbiddenWord === true}
          >
            <span>{t('chat.settingPopup.overview.forbiddenWords.addButton')}</span>
          </button>
        </div>
        {isRegisteredForbiddenWord === true && (
          <span className="error_txt msg">{t('chat.settingPopup.overview.forbiddenWords.guide')}</span>
        )}
      </div>
      <div className="hashtag_box">
        {forbiddenWords.map((forbiddenWord) => {
          return (
            <button type="button" className="hashtag" key={forbiddenWord}>
              <span>{forbiddenWord}</span>
              <span className="delete" onClick={onRemoveForbiddenWord(forbiddenWord)} />
            </button>
          );
        })}
      </div>
      <ul className="guide popup">
        <li>{t('chat.settingPopup.overview.forbiddenWords.guide')}</li>
      </ul>
    </ChannelSettingContainer>
  );
};

export default ChannelSettingOverview;
