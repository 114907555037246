import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);
dayjs.extend(localizedFormat);

type Props = {
  createdAt: Date;
};

// enum DateFormatType {
//   SHORT,
//   NORMAL,
//   LONG
// }

// enum DateDiffType {
//   MINUTE,
//   HOUR,
//   DAY,
//   WEEK,
//   YEAR
// }

const MINUTES_IN_YEAR = 365 * 24 * 60;
const MINUTES_IN_WEEK = 7 * 24 * 60;
const MINUTES_IN_DAY = 24 * 60;
const MINUTES_IN_HOUR = 60;

const ChatMessageDate: React.FC<Props> = ({ createdAt }) => {
  const { t } = useTranslation();

  const calculate = useCallback((dateProps: any) => {
    const date = dayjs(dateProps);
    const now = dayjs();
    const minute = now.diff(date, 'minute');

    return {
      date,
      minute
    };
  }, []);

  const dateFormatLong = useCallback((dateProps: any) => {
    const { date, minute } = calculate(dateProps);

    switch (true) {
      case minute > MINUTES_IN_YEAR:
        return date.format('MMM. D, YYYY');

      case minute > MINUTES_IN_WEEK:
        return date.format('MMM. D');

      case minute > MINUTES_IN_DAY: {
        const day = Math.floor(minute / MINUTES_IN_DAY);
        if (day === 1) return t('common.dateFormat.longSingle.withinWeek', { day });
        return t('common.dateFormat.longPlural.withinWeek', { day });
      }

      case minute > MINUTES_IN_HOUR: {
        const hour = Math.floor(minute / MINUTES_IN_HOUR);
        if (hour === 1) return t('common.dateFormat.longSingle.withinDay', { hour });
        return t('common.dateFormat.longPlural.withinDay', { hour });
      }

      default: {
        if (minute === 1) return t('common.dateFormat.longSingle.withinHour', { minute });
        return t('common.dateFormat.longPlural.withinHour', { minute });
      }
    }
  }, []);

  return (
    <span className="tw-flex tw-whitespace-nowrap tw-px-1 tw-text-10 tw-text-gray-600 group-hover:tw-hidden">
      {dateFormatLong(createdAt)}
    </span>
  );
};
export default ChatMessageDate;
