import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { User } from '@sendbird/chat';
import { useRecoilCallback, useRecoilValue } from 'recoil';

import { fetchUser } from '../../../libs/sendbird';
import likedUserListPopupState from '../../../store/atoms/likedUserListPopupState';

import { ReactComponent as LikeIcon } from 'assets/icons/ic-chat-heart-on.svg';
import classNames from 'components/styled/util';

type Props = {
  countLiked?: number;
  messageId: number;
  reactionsUserIds?: string[];
  isMine: boolean;
};

type LikedUser = User | null;

const LikedUserList: React.FC<Props> = ({ countLiked = 0, messageId, reactionsUserIds, isMine }) => {
  const navigate = useNavigate();
  const popupMessageId = useRecoilValue(likedUserListPopupState);
  const [likedUsers, setLikedUsers] = useState<LikedUser[]>([]);

  const wrapperRef = useRef<HTMLDivElement>(null);

  const onPopup = useRecoilCallback(
    ({ snapshot, set }) =>
      () => {
        const popupMessageId = snapshot.getLoadable(likedUserListPopupState).getValue();
        if (popupMessageId === messageId) {
          set(likedUserListPopupState, null);
        } else {
          set(likedUserListPopupState, messageId);
        }
      },
    []
  );

  useEffect(() => {
    async function init() {
      const promises: Promise<LikedUser>[] = [];
      for (const userId of reactionsUserIds || []) {
        promises.push(fetchUser(userId));
      }

      const results = await Promise.allSettled(promises);

      const fulfilledUsers = results
        .filter((result): result is PromiseFulfilledResult<LikedUser> => result.status === 'fulfilled')
        .map((result) => result.value);

      if (fulfilledUsers.length > 0) {
        setLikedUsers(fulfilledUsers);
      }
    }
    init();
  }, [reactionsUserIds]);

  const onProfile = useCallback(
    (userId: string) => () => {
      navigate(`miniprofile/${userId}`, {
        replace: false
      });
    },
    [navigate]
  );

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target as Node)) {
        onPopup();
      }
    };
    const active = messageId === popupMessageId;
    if (active) {
      document.addEventListener('click', handleClickOutside);
    }
    return () => {
      if (active) {
        document.removeEventListener('click', handleClickOutside);
      }
    };
  }, [messageId, popupMessageId, onPopup, wrapperRef.current]);

  return (
    // love_area
    <div
      className={classNames('tw-mt-1 tw-flex', {
        'tw-pl-16': !isMine,
        'tw-pr-16 tw-justify-end': isMine
      })}
    >
      <div className="tw-relative tw-inline-block tw-w-fit" ref={wrapperRef}>
        <button
          type="button"
          className="tw-flex tw-h-[25px] tw-items-center tw-gap-1 tw-rounded-full tw-bg-white tw-p-1 tw-pr-1.5"
          onClick={onPopup}
        >
          <LikeIcon className="tw-size-4" />
          <span className="tw-text-12 tw-text-black-title">{countLiked}</span>
        </button>

        <div
          // love_div
          className={classNames(
            'tw-absolute tw-top-1/2 tw-w-[158px] -tw-translate-y-1/2 tw-rounded-3 tw-border tw-border-gray-300 tw-bg-white tw-p-4 tw-shadow-layer',
            {
              'tw-right-full -tw-translate-x-[7px]': isMine,
              'tw-left-full tw-translate-x-[7px]': !isMine
            },
            {
              'tw-flex': messageId === popupMessageId,
              'tw-hidden': messageId !== popupMessageId
            }
          )}
        >
          <ul className="-tw-mr-5 tw-flex tw-max-h-[192px] tw-w-full tw-flex-col tw-gap-2 tw-overflow-y-auto">
            {likedUsers.map((likedUser) => {
              if (!likedUser) {
                return null;
              }

              return (
                <li
                  key={likedUser.userId}
                  onClick={onProfile(likedUser.userId)}
                  className="tw-flex tw-cursor-pointer tw-items-center"
                >
                  <img className="tw-size-8 tw-rounded-4" src={likedUser.profileUrl} />
                  <span className="tw-ml-2 tw-inline-block tw-truncate tw-text-13 tw-font-medium tw-text-black-body">
                    {likedUser.nickname}
                  </span>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default LikedUserList;
