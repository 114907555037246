import React, { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { User } from '@sendbird/chat';
import { useRecoilCallback, useRecoilValue } from 'recoil';

import useAlert from '../../../../hooks/useAlert';
import useCurrentUser from '../../../../hooks/useCurrentUser';
import useGroupChannel from '../../../../hooks/useGroupChannel';
import { getChannelName, getIsMaster } from '../../../../libs/helper';
import { groupChannelStateByUrl } from '../../../../store/atoms/groupChannelsState';
import { ChatMessage, TransferMasterMessageProps } from '../../../../types/common';

import Button from 'components/jayden/Button';
import Ballon from 'components/jayden/ballon';

type Props = {
  message: ChatMessage;
};

const ChatTransferMasterMessage: React.FC<Props> = ({ message }) => {
  const { t } = useTranslation();
  const { show, close } = useAlert();
  const { currentUser } = useCurrentUser();
  const { updateChannel } = useGroupChannel();
  const channel = useRecoilValue(groupChannelStateByUrl(message.channelUrl));

  const transferMessage = useMemo<TransferMasterMessageProps>(() => JSON.parse(message.message), [message.message]);
  const transferChannel = useRecoilValue(groupChannelStateByUrl(transferMessage.channelUrl));

  const isAccepted = () => {
    if (!transferChannel) {
      return false;
    }

    return getIsMaster({ channel: transferChannel, user: currentUser as User });
  };

  const onAccept = useRecoilCallback(
    () => async () => {
      if (!transferChannel) {
        return;
      }

      if (!transferMessage) {
        return;
      }

      if (!currentUser) {
        return;
      }
      if (transferChannel.data.transferingStaffIds.indexOf((currentUser as User).userId) === -1) {
        show({
          content: t('chat.transferMessage.alert.cancelled', {
            channelName: transferChannel.name
          }),
          buttons: [
            {
              title: t('common.button.confirm'),
              onClick: close
            }
          ]
        });
        return;
      }

      await updateChannel(transferChannel.url, {
        data: {
          masterIds: [(currentUser as User).userId],
          staffIds: channel.data.staffIds.filter((staffId) => staffId !== currentUser.userId),
          transferingStaffIds: channel.data.transferingStaffIds.filter((staffId) => staffId !== currentUser.userId)
        }
      });
      show({
        content: t('chat.transferMessage.alert.becomeMaster', {
          channelName: `<span class="channel_name_puple">${transferChannel.name}.</span>`
        }),
        buttons: [
          {
            title: t('common.button.confirm'),
            onClick: close
          }
        ]
      });
    },
    [transferMessage, currentUser]
  );

  const isCancelled = () => {
    if (!currentUser) {
      return true;
    }

    if (isAccepted() === true) {
      return false;
    }

    if (transferChannel?.data.transferingStaffIds.indexOf((currentUser as User).userId) === -1) {
      return true;
    }

    return false;
  };

  const channelName = useMemo(() => {
    if (!transferChannel) {
      return transferMessage.channelName || 'Unknown';
    }

    return getChannelName(transferChannel);
  }, [transferChannel, transferMessage, message]);

  if (!channel) {
    return null;
  }

  const isMe = message.sender.userId === currentUser?.userId;

  return (
    <>
      {/* 초대하기 DM  */}
      <Ballon isMe={isMe}>
        <p className="tw-text-12">{process.env.NODE_ENV === 'development' && message.messageId}</p>
        <span className="tw-block">
          <div className="tw-mb-2 tw-inline-flex tw-h-10 tw-items-center">
            <span>
              <img className="tw-size-10 tw-rounded-2" src={transferChannel?.coverUrl} />
            </span>
            <span className="tw-ml-3 tw-text-16 tw-font-semibold">{channelName}</span>
          </div>
          {/* 채널초대문구 : 채널 생성시 작성된 소개 문구가 들어갑니다. */}
          {!isCancelled() && (
            <div className="tw-border-t tw-border-gray-300 tw-px-1 tw-pt-3 tw-text-13">
              <Trans i18nKey="chat.transfer.channel.description">
                <span className="">{{ channelName: channelName } as any}</span>
              </Trans>
            </div>
          )}
          {isCancelled() === true && (
            <div className="tw-border-t tw-border-gray-300 tw-px-1 tw-pt-3 tw-text-13">
              <Trans i18nKey="chat.transferMessage.alert.cancelled">
                <span className="">{{ channelName: channelName } as any}</span>
              </Trans>
            </div>
          )}

          {/* {!isCancelled() && isAccepted() && (
            <div className="tw-border-t tw-border-gray-300 tw-px-1 tw-pt-3 tw-text-13">
              <Trans i18nKey="chat.transferMessage.alert.becomeMaster">
                <span className="">{{ channelName: channelName } as any}</span>
              </Trans>
            </div>
          )} */}

          {!!transferChannel && currentUser?.userId !== message.sender.userId && (
            <div className="tw-mt-3 tw-border-t tw-border-gray-300 tw-pt-3 tw-text-end">
              <Button
                color="primary"
                className="tw-w-[100px] tw-text-center"
                size="sm"
                type="button"
                onClick={onAccept}
                disabled={isAccepted() || isCancelled()}
              >
                {isCancelled()
                  ? t('chat.inviteTransfer.button.cancelled')
                  : isAccepted()
                    ? t('chat.inviteTransfer.button.accepted')
                    : t('chat.inviteTransfer.button.accept')}
              </Button>
            </div>
          )}
        </span>
      </Ballon>
    </>
  );
};
export default ChatTransferMasterMessage;
