import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useRecoilCallback, useRecoilValue } from 'recoil';

import useAlert from '../../hooks/useAlert';
import useGroupChannel from '../../hooks/useGroupChannel';
import { getIsStaff } from '../../libs/helper';
import { groupChannelStateByUrl } from '../../store/atoms/groupChannelsState';

import ChannelSettingContainer, { ChannelSettingTab } from './components/ChannelSettingContainer';

import useNewGroupChannel from 'hooks/use-group-channel';

import { getChannelIdByChannelUrl } from 'utils/common';

type Params = {
  channelUrl: string;
};

const ChannelSettingDeleteChannel: React.FC = () => {
  const { channelUrl } = useParams<Params>();
  const { t } = useTranslation();

  const channel = useRecoilValue(groupChannelStateByUrl(channelUrl ?? ''));

  const [selectedStaffId, setSelectedStaffId] = useState<string | null>(null);

  const { transferMaster, cancelTransferMaster } = useGroupChannel();
  const { deleteChannel } = useNewGroupChannel();
  const { show, close } = useAlert();
  const members = useMemo(() => {
    if (!channel) {
      return [];
    }

    return channel.members.filter((member) => getIsStaff({ channel, user: member }));
  }, [channel]);

  // 채널 삭제
  const onDeleteChannel = useRecoilCallback(
    () => async () => {
      show({
        content: t('chat.settingPopup.deleteChannel.deleteAlert.contents', {
          channelName: `<span class="purple">${channel.name}</span>`
        }),
        buttons: [
          {
            title: t('common.button.cancel'),
            onClick() {
              close();
            }
          },
          {
            title: t('common.button.confirm'),
            async onClick() {
              await deleteChannel(getChannelIdByChannelUrl(channelUrl ?? ''));
              close();
            }
          }
        ]
      });
    },
    [channel]
  );

  const onSendTransfer = useRecoilCallback(
    () => async () => {
      if (!selectedStaffId) {
        return;
      }

      show({
        content: t('chat.settingPopup.deleteChannel.transferMasterAlert.contents'),
        buttons: [
          {
            title: t('common.button.cancel'),
            onClick() {
              close();
            }
          },
          {
            title: t('common.button.confirm'),
            async onClick() {
              await transferMaster(channel, '', [selectedStaffId]);
              close();
              show({
                content: t('chat.settingPopup.deleteChannel.messageSentAlert.contents'),
                buttons: [
                  {
                    title: t('common.button.confirm'),
                    onClick() {
                      close();
                    }
                  }
                ]
              });
            }
          }
        ]
      });
    },
    [channel, selectedStaffId]
  );

  const onCancelTransfer = useRecoilCallback(
    () => async () => {
      show({
        content: t('chat.settingPopup.deleteChannel.cancelTransferAlert.contents'),
        buttons: [
          {
            title: t('common.button.cancel'),
            onClick() {
              close();
            }
          },
          {
            title: t('common.button.confirm'),
            async onClick() {
              await cancelTransferMaster(channel);
              close();
            }
          }
        ]
      });
    },
    [channel]
  );

  const onSelectStaff = useCallback(
    (userId: string) => () => {
      setSelectedStaffId(userId);
    },
    []
  );

  if (!channelUrl) {
    return null;
  }

  if (!channel) {
    return null;
  }

  return (
    <ChannelSettingContainer tab={ChannelSettingTab.DELETE_CHANNEL}>
      {/*
      <div class="no_staff">
        <dl>
          <dt>No Staffs..</dt>
          appointing staff를 누를시 스탭설정 화면으로 이동됩니다.(permissions tab)
          <dd>There are no staff for this channel.<br/>After <button type="button" class="appointing"><span>appointing staff</span></button>, you can transfer authority.</dd>
        </dl>
      </div> */}
      <div className="setting_title transfer">{t('chat.settingPopup.deleteChannel.title')}</div>
      <div className="transfer_text">{t('chat.settingPopup.deleteChannel.description')}</div>
      {channel.data.transferingStaffIds.length > 0 && (
        <div className="waiting" style={{ width: 630, marginBottom: 150 }}>
          <dl className="tw-w-[475px] tw-break-all">
            <dt>{t('chat.settingPopup.deleteChannel.transferWaiting.title')}</dt>
            <dd>{t('chat.settingPopup.deleteChannel.transferWaiting.description')}</dd>
          </dl>
          {/* 채널권한요청 취소되었을때 Alert(The offer has been cancelled.) / Button : Confirm */}
          <button type="button" className="btn warning button_sm" onClick={onCancelTransfer}>
            <span>{t('chat.settingPopup.deleteChannel.button.cancelTransfer')}</span>
          </button>
        </div>
      )}
      {channel.data.transferingStaffIds.length === 0 && (
        <>
          <div className="setting_subtitle">{t('chat.settingPopup.deleteChannel.selectStaff.label')}</div>
          <div className="select_staff_list">
            {members.length === 0 && (
              <ul>
                <li>
                  <span style={{ fontSize: 14 }}>{t('chat.settingPopup.deleteChannel.selectStaff.placeholder')}</span>
                </li>
              </ul>
            )}
            {members.length > 0 && (
              <ul>
                {members.map((member) => {
                  return (
                    <li key={member.userId}>
                      <div className="form_wrap">
                        <span className="form_cell form_check">
                          <input
                            type="checkbox"
                            id={`check-${member.userId}`}
                            checked={selectedStaffId === member.userId}
                            onChange={onSelectStaff(member.userId)}
                          />
                          <label htmlFor={`check-${member.userId}`} className="checkbox">
                            <img className="tw-ml-1.5" src={member.profileUrl} />
                            <span>{member.nickname}</span>
                          </label>
                        </span>
                      </div>
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
          <div className="message_box">
            {/* Staff 선택 전에는 disabled 상태입니다. 스태프 선택 시 활성화 됩니다. */}
            {/* 버튼 클릭 시 Alert(Do you want to send a message of agreeing to transfer the master autority to the staff?) / Button : Cancel, Confirm */}
            {/* Alert에서 Confirm 클릭 시 다시 Alert(A message has been sent.) / Button : Confirm */}
            <button
              type="button"
              className="btn primary_line button_md"
              onClick={onSendTransfer}
              disabled={!selectedStaffId}
            >
              <span>{t('chat.settingPopup.deleteChannel.selectStaff.sendButton')}</span>
            </button>
          </div>
        </>
      )}

      <div className="guide_box">
        <ul className="guide">
          <li>{t('chat.settingPopup.deleteChannel.guide')}</li>
        </ul>
        <button type="button" className="btn default button_sm" onClick={onDeleteChannel}>
          <span>{t('chat.settingPopup.deleteChannel.deleteChannelButton')}</span>
        </button>
      </div>
    </ChannelSettingContainer>
  );
};

export default ChannelSettingDeleteChannel;
