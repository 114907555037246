import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import Loader from 'components/jayden/Loader';
import ProfileAvatar from 'components/profileAvatar/ProfileAvatar';
import classNames from 'components/styled/util';

interface ChannelInfoMemberSearchListProps {
  searchedMemberList: any[];
  keyword?: string;
  onClose: () => void;
  isLoading?: boolean;
}

const ChannelInfoMemberSearchList = ({
  searchedMemberList,
  keyword = '',
  onClose,
  isLoading = false
}: ChannelInfoMemberSearchListProps) => {
  const navigate = useNavigate();

  const onProfile = useCallback(
    (userId: string) => () => {
      navigate(`miniprofile/${userId}`, {
        replace: false
      });
      onClose();
    },
    []
  );

  return (
    <div
      className={classNames({
        'tw-hidden': !keyword,
        'tw-absolute tw-z-[99] tw-rounded-2 tw-mt-1.5 tw-max-h-[350px] tw-overflow-y-auto tw-border tw-bg-white tw-w-full tw-border-gray-500 tw-top-[52px]':
          keyword
      })}
    >
      {searchedMemberList.length > 0 && (
        <ul className="">
          {/* 각 멤버 클릭 시 미니 프로필 호출 */}
          {searchedMemberList.map((member) => {
            const userInfo = {
              ...member,
              profile_image_url: member.profileUrl
            } as any;

            return (
              <li
                key={member.userId}
                onClick={onProfile(member.userId)}
                className="tw-flex tw-h-12 tw-cursor-pointer tw-items-center tw-px-4 first:tw-mt-2.5 last:tw-mb-2.5 hover:tw-bg-gray-100"
              >
                <ProfileAvatar userInfo={userInfo} trustRing size={32} className="tw-z-0" />
                <div className="tw-ml-2 tw-w-fit tw-truncate tw-text-13 tw-font-medium tw-text-black-body">
                  {member.nickname}
                </div>
              </li>
            );
          })}
        </ul>
      )}

      {isLoading && (
        <div className="tw-py-10">
          <Loader />
        </div>
      )}

      {!isLoading && searchedMemberList.length === 0 && (
        <div className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-p-7">
          <img className="tw-size-[60px]" src={`${process.env.PUBLIC_URL}/img/com/img_unsmile3_gr.png`} />
          <p className="tw-text-18 tw-font-bold tw-leading-[26px] tw-text-gray-300">No Matching..</p>
        </div>
      )}
    </div>
  );
};
export default ChannelInfoMemberSearchList;
