import React from 'react';

import useUserInfo from 'hooks/useUserInfo';

interface ProfileImageProps extends React.HTMLAttributes<HTMLImageElement> {
  userId: string;
}

const ProfileImage: React.FC<ProfileImageProps> = (props) => {
  const { userId, ...imgProps } = props;

  const { data: userInfo, isLoading } = useUserInfo(userId);

  if (isLoading) return <></>;

  return <img src={userInfo?.profile_image_url} alt="profile image" {...imgProps} />;
};

const Username: React.FC<{ userId: string }> = (props) => {
  const { userId } = props;
  const { data: userInfo, isLoading } = useUserInfo(userId);

  if (isLoading) return <></>;

  return <>{userInfo?.username}</>;
};

type UserInfoCompoundComponent = {
  ProfileImage: typeof ProfileImage;
  Username: typeof Username;
};

const UserInfo: UserInfoCompoundComponent = { ProfileImage, Username };

export default UserInfo;
